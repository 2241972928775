import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const BannerContainer = styled(Stack)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    > :last-child {
        margin-bottom: 16px;
    }
`;
