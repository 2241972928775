import { PropsWithChildren, ReactNode, useRef, useState } from 'react';

import { useFixedTopbar } from 'app/common/contexts/fixedTopbar';
import { useIntersectionObserver } from 'app/hooks';

import { FixedContainer, HeaderContainer } from './FixedPageHeader.styled';

type FixedPageHeader = {
    fixed?: ReactNode;
};

export const FixedPageHeader = ({
    children,
    fixed = children,
}: PropsWithChildren<FixedPageHeader>) => {
    const headerContainerRef = useRef<HTMLDivElement>(null);
    const { intersecting, setIntersecting } = useFixedTopbar();
    const [isVisible, setIsVisible] = useState(false);

    useIntersectionObserver({
        target: headerContainerRef,
        callback: entry => {
            setIntersecting(!entry.isIntersecting);

            if (!entry.isIntersecting) {
                setIsVisible(true);
            }
        },
    });

    const height = headerContainerRef?.current?.offsetHeight ?? 0;

    const onTransitionEndHandler = () => {
        if (!intersecting) {
            setIsVisible(false);
        }
    };

    return (
        <>
            <HeaderContainer ref={headerContainerRef}>{children}</HeaderContainer>
            <FixedContainer
                height={height}
                intersecting={intersecting}
                onTransitionEnd={onTransitionEndHandler}
            >
                {isVisible && fixed}
            </FixedContainer>
        </>
    );
};
