import { SingleSelect } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useWidgetSetupGet } from '../../../../hooks/useWidgetSetupGet';

export const BrandDisplay = () => {
    const { t } = useTranslation();
    const { widgetSetupId } = useParams();
    const { data: widgetSetup } = useWidgetSetupGet(widgetSetupId!);
    const widgetSetupName = widgetSetup?.business.name;

    const selectedValue = { label: widgetSetupName, name: '', value: '' };

    return (
        <SingleSelect
            dataTrackId=""
            label={t('widget_setup_brand_selection_input') + '*'}
            selectedValue={selectedValue}
            onChange={() => {}}
            sections={[]}
            disabled={true}
        />
    );
};
