import { Dispatch, SetStateAction, useMemo } from 'react';

import { Pagination } from '@partoohub/ui';

import { debounce } from 'lodash';

import { TABLE_PAGE_SIZE } from 'app/pages/analytics/ReviewAnalyticsV2/hooks/useMetrics';

import { StyledPaginationWrapper } from '../Rankings.styled';

type PageNavigatorProps = {
    dataCount: number;
    dataLength: number;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    dataTrackIdKey: string;
};

export const PageNavigator = ({
    dataCount,
    dataLength,
    page,
    setPage,
    dataTrackIdKey,
}: PageNavigatorProps) => {
    const handlePageChange = useMemo(() => debounce(newPage => setPage(newPage), 100), []);

    return (
        <StyledPaginationWrapper>
            <Pagination
                dataTrackId={`review_analytics__${dataTrackIdKey}_ranking__pagination`}
                page={page}
                pageCount={Math.max(1, Math.ceil(dataCount / TABLE_PAGE_SIZE))}
                onChange={handlePageChange}
                disabled={dataCount === 1 || dataCount === 0 || dataLength === 0}
            />
        </StyledPaginationWrapper>
    );
};
