import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

/**
 * Container for a todo item, no styling applied.
 * Use this to wrap your shortcut content.
 */
export const TodoContainer = styled.div`
    display: flex;
    height: 146px;
    overflow: hidden;
    border-radius: 8px;
    transition: all ease 0.15s;
    background-color: ${({ theme }) => theme.colors.theme.background};
    box-shadow: 0 2px 16px 0 rgba(127, 142, 164, 0.12);

    &:hover {
        box-shadow: 0 2px 16px 0 rgba(127, 142, 164, 0.24);
    }
`;

/**
 * Default content for a todo item.
 * You can use a new one if you want a new specific style.
 */
export const TodoContent = styled.div`
    position: relative;
    overflow: hidden;

    display: flex;
    flex: 1;
    align-items: center;
    padding: 24px 32px;

    &:hover {
        cursor: pointer;
    }
`;

type TodoLeftSectionProps = {
    small?: boolean;
};

export const TodoLeftSection = styled.div<TodoLeftSectionProps>`
    display: flex;
    position: absolute;
    flex-shrink: 0;

    ${props =>
        props.small &&
        css`
            * {
                height: 88px;
                margin-left: -8px;
            }
        `}
`;

type TodoRightSectionProps = {
    small?: boolean;
};

export const TodoRightSection = styled.div<TodoRightSectionProps>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-left: ${props => (props.small ? '128px' : '160px')};
`;

export const TodoTitle = styled(Text)``;

export const TodoAction = styled.div`
    display: flex;
    align-items: center;

    gap: 4px;
`;
