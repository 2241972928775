import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { StyledDimensionSwitch } from './DimensionSwitch.styled';
import { useInternalRankingParameters } from '../hooks/useInternalRankingParameters';

export const DimensionSwitch = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const options = useMemo(
        () => [
            { label: t('businesses'), value: 'business' as const },
            { label: t('users'), value: 'user' as const },
        ],
        [t],
    );

    const {
        dimension: [selectedDimension, setDimension],
        resetParameters,
    } = useInternalRankingParameters();

    useEffect(() => {
        resetParameters({ dimension: options });
        setLoading(false);
    }, [options]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDimension(e.target.value);
    };

    // Render only when the selectedDimension is fully updated
    if (loading || !selectedDimension) return null;

    const leftButton = options[0] ?? { label: '', value: '' };
    const rightButton = options[1] ?? { label: '', value: '' };

    return (
        <StyledDimensionSwitch
            dataTrackId="review_analytics__dimensions_switch"
            name="review_analytics_business_user_switch"
            onChange={handleChange}
            value={selectedDimension}
            leftButton={leftButton}
            rightButton={rightButton}
        />
    );
};
