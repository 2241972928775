import styled from '@emotion/styled';
import { Button, Chart, Text } from '@partoohub/ui';

export const OverviewContainer = styled.div`
    flex-direction: column;
    background-color: white;
    display: flex;
    height: 376px;
    padding: 24px;
    border-radius: inherit;
`;

export const ChartContainer = styled.div`
    flex: 2;
`;

export const ChartHeader = styled(Text)`
    gap: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

export const StyledChart = styled(Chart)`
    width: 100% !important;
`;

export const RankingExplanationButton = styled(Button)`
    margin-left: auto;
`;
