import { PropsWithChildren, ReactNode } from 'react';

import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import { FixedPageHeader } from 'app/routing/components/FixedPageHeader';

import { BodyContainer, BodyContent } from './Template.styled';
import { DesktopHeader } from '../DesktopHeader/DesktopHeader';
import { VisibilityContextProvider } from '../DesktopHeader/DesktopHeader.context';

interface TemplateProps {
    header?: ReactNode;
}

export const Template = ({ header, children }: PropsWithChildren<TemplateProps>) => {
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);

    return isNewSidebarEnabled ? (
        <VisibilityContextProvider>
            <FixedPageHeader>
                <DesktopHeader header={header} />
            </FixedPageHeader>

            <BodyContent>{children}</BodyContent>
        </VisibilityContextProvider>
    ) : (
        <BodyContainer>
            <VisibilityContextProvider>
                <DesktopHeader header={header} />
                <BodyContent>{children}</BodyContent>
            </VisibilityContextProvider>
        </BodyContainer>
    );
};
