import { useRef } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { LeftElementType } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import {
    ColorPickerContainer,
    ColorPickerTextInput,
    ColoredDot,
    HiddenColorPickerInput,
} from './ColorPicker.styled';

interface ColorPickerProps {
    color?: string;
    onChange: (color: string) => void;
}

export const ColorPicker = ({ color = HEX_COLORS.primary, onChange }: ColorPickerProps) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLInputElement>(null);

    return (
        <ColorPickerContainer onClick={() => ref.current?.click()}>
            <HiddenColorPickerInput
                ref={ref}
                type="color"
                value={color}
                onChange={e => onChange(e.target.value)}
            />
            <ColorPickerTextInput
                dataTrackId="settings_messages_widget_setup__display_color"
                label={t('widget_setup_color_picker_label')}
                readOnly
                leftElement={<ColoredDot bgColor={color} />}
                leftElementType={LeftElementType.Icon}
                value={color.toUpperCase()}
            />
        </ColorPickerContainer>
    );
};
