import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';

import {
    ChartCardsContainer,
    ChartGraphContainer,
    ContentSectionContainer,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/ContentSection/ContentSection.styled';
import {
    KeywordsCard,
    KeywordsChart,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/Keywords';
import {
    MetricsCard,
    MetricsChart,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/Metrics';

export const ContentSection = () => {
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    return (
        <ContentSectionContainer>
            <ChartCardsContainer isNewSidebarEnabled={isNewSidebarEnabled}>
                <KeywordsCard />
                <MetricsCard />
            </ChartCardsContainer>
            <ChartGraphContainer>
                <KeywordsChart />
                <MetricsChart />
            </ChartGraphContainer>
        </ContentSectionContainer>
    );
};
