import { useEffect } from 'react';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { URLParams } from 'app/states/messaging';

import { useGetActiveConversation } from './useGetActiveConversation';
import { useGetConversations } from './useGetConversations';

export const useConversationSetup = () => {
    const [conversationParamId, setConversationParamId] = useStateQueryParams(
        URLParams.CONVERSATION_ID,
    );
    const { conversations } = useGetConversations();
    const activeConversation = useGetActiveConversation();
    useEffect(() => {
        /**
         * Checking for activeConversation is important since setting
         * an active conversation also changes the ref of conversations
         * It avoids running the effect twice
         */
        if (conversations.length > 0 && !activeConversation?.id && !conversationParamId) {
            setConversationParamId(String(conversations[0]?.id));
        }
    }, [JSON.stringify(conversations), activeConversation]);
};
