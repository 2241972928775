import LoadingView from 'app/common/components/LoadingView';
import { NoBusiness } from 'app/common/components/placeholder/NoBusiness';
import { NoReviewManagement } from 'app/common/components/placeholder/reviewMangement/NoReviewManagement';

import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks/useDeviceType';
import { AnalyticsTabs } from 'app/pages/analytics/VisibilityAnalyticsV2/components/AnalyticsTabs';

import { GetParamsReduxSync } from './GetParamsReduxSync';
import { DesktopReviewHeader, FilterSection, MobileReviewManagementHeader } from './pageHeader';
import { PageContainer } from './ReviewAnalytics.styled';
import ReviewAnalyticsSection from './ReviewAnalyticsSection/ReviewAnalyticsSection';

export const ReviewAnalytics = () => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);

    const { hasBusinesses, subscribedToRM, isLoading } = useBusinessSubscriptions();

    if (isLoading) {
        return <LoadingView />;
    }

    if (!hasBusinesses) {
        return <NoBusiness />;
    }

    if (!subscribedToRM) {
        return <NoReviewManagement />;
    }

    return (
        <PageContainer>
            <GetParamsReduxSync />
            {isBelowProvidedDevices ? <MobileReviewManagementHeader /> : <DesktopReviewHeader />}
            <AnalyticsTabs />
            <FilterSection />
            <ReviewAnalyticsSection />
        </PageContainer>
    );
};
