import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { Global, ThemeProvider } from '@emotion/react';
import { darkTheme, theme as lightTheme } from '@partoohub/ui';

import env from 'app/common/services/getEnvironment';
import { global, globalWithScrollbarStyling } from 'app/global.styled';

import { useIsMac } from './common/hooks/useIsMac';
import { usePartooTheme } from './hooks/usePartooTheme';

type ThemeContextType = {
    theme: string;
    toggleTheme: () => void;
};

const DARK_VALUE = 'dark';
const LIGHT_VALUE = 'light';

const PartooTheme = createContext<ThemeContextType | undefined>(undefined);

export const usePartooThemeContext = () => {
    const context = useContext(PartooTheme);
    if (!context) {
        throw new Error('useThemeContext must be used within a PartooThemeProvider');
    }
    return context;
};

export const USE_DARK_THEME = env.isDev() || env.isStaging();

/**
 * Handle everything related to the theme of the app
 */
export const PartooThemeProvider: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
    const [partooTheme, setPartooTheme] = usePartooTheme();
    const isMac = useIsMac();
    const globalStyle = isMac ? global : globalWithScrollbarStyling;

    const selectedTheme = partooTheme === DARK_VALUE && USE_DARK_THEME ? darkTheme : lightTheme;

    const toggleTheme = () => {
        setPartooTheme(partooTheme === DARK_VALUE ? LIGHT_VALUE : DARK_VALUE);
    };

    const value = useMemo(
        () => ({
            theme: partooTheme,
            toggleTheme,
        }),
        [partooTheme],
    );

    return (
        <PartooTheme.Provider value={value}>
            <Global styles={globalStyle} />
            <ThemeProvider theme={selectedTheme}>{children}</ThemeProvider>
        </PartooTheme.Provider>
    );
};
