import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

type GroupContainerProps = {
    canEdit: boolean;
};

export const GroupContainer = styled.div<GroupContainerProps>`
    display: flex;
    align-items: center;
    height: 72px;
    border-radius: 8px;
    box-shadow: 0 2px 16px rgba(20, 37, 66, 0.08);
    padding-inline: 16px;
    transition: all 0.15s ease;

    ${props =>
        props.canEdit &&
        css`
            &:hover {
                cursor: pointer;
                box-shadow: 0 5px 16px rgba(20, 37, 66, 0.16);
            }
        `}
`;

export const GroupLeft = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
`;

export const GroupName = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    flex-basis: 40%;
    color: ${({ theme }) => theme.colors.default.initial};
    > i {
        margin-right: 8px;
    }
`;

export const GroupCount = styled(Text)`
    margin-right: auto;
`;
