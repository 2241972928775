import { useEffect } from 'react';

import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { MESSAGING_CONVERSATIONS } from 'app/common/data/queryKeysConstants';

import queryClient from 'app/states/queryClient';

import { MessagingBanners } from './banners/MessagingBanners';

import { useConversationDeeplink } from './hooks/useConversationDeeplink';
import { useConversationSetup } from './hooks/useConversationSetup';

import { useHandleBusinessFiltersChange } from './hooks/useHandleBusinessFiltersChange';
import { useHandleNewConversation } from './hooks/useHandleNewConversation';

import { MessagingUsersProvider } from './LoadedUsersProvider';
import { MessagingBody } from './MessagingBody';
import { MessagingPageHeader } from './pageHeader/MessagePageHeader';

export const MessagingWithNewSidebar = () => {
    useHandleBusinessFiltersChange();
    useHandleNewConversation();
    useConversationSetup();
    useConversationDeeplink();

    /**
     * On component unmount, we remove the messaging cache.
     */
    useEffect(
        () => () => {
            queryClient.removeQueries(invalidatesAny(MESSAGING_CONVERSATIONS));
        },
        [],
    );

    return (
        <MessagingUsersProvider>
            <MessagingPageHeader />
            <MessagingBanners />
            <MessagingBody />
        </MessagingUsersProvider>
    );
};
