import { useEffect, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import useTotalBusinessesCount from 'app/common/components/businessModal/hooks/useTotalBusinessesCount';
import { resetBusinessFilter } from 'app/common/components/businessModalDeprecated/reducers';
import LoadingView from 'app/common/components/LoadingView';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { NoPresenceManagement } from 'app/common/components/placeholder/PresenceManagement/NoPresenceManagement';
import { CONNECTION_STATS, POSTS_BUSINESS_SCOPES } from 'app/common/data/queryKeysConstants';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import { FilterBar } from 'app/presence/googlePosts/components/googlePostList/FilterBar';
import PostCreationModalComponent from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/PostCreationModalComponent';
import GooglePostDeleteConfirmModal from 'app/presence/googlePosts/components/googlePostList/PostDeleteConfirmModal';
import PostsTable from 'app/presence/googlePosts/components/googlePostList/PostsTable';
import usePosts from 'app/presence/googlePosts/hooks/usePosts';

import { PostListBanners } from './Banners';
import { EditPostsContext, useEditPostContext } from './PostCreationModal/hooks/useEditPostContext';
import { PostsQueryContext } from './PostCreationModal/hooks/usePostQueryContext';
import { GooglePostsListPageHeader } from './TitleBar/GooglePostsListPageHeader';
import { usePostsListIsLoading } from '../../hooks/usePostsListIsLoading';

export const PostsListWithNewSidebar = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const editPostsContext = useEditPostContext();
    const {
        postCreationModalEditMode,
        postId,
        postToEditData,
        setPostId,
        setBulkId,
        showCreationModal,
        setShowCreationModal,
    } = editPostsContext;

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [postCount, setPostCount] = useState(0);
    const { subscribedToPM } = useBusinessSubscriptions();
    const totalBusinesses = useTotalBusinessesCount();

    const postsQueryContext = usePosts(!postId && !showCreationModal);
    const queryClient = useQueryClient();

    useEffect(() => {
        // TODO: Check if needed
        const reactContent = document.getElementById('react-content');
        if (reactContent) {
            reactContent.scrollTo({
                top: 0,
            });
        }

        return () => {
            dispatch(resetBusinessFilter());

            queryClient.invalidateQueries({
                queryKey: CONNECTION_STATS,
                refetchActive: false,
            });

            queryClient.invalidateQueries({
                queryKey: POSTS_BUSINESS_SCOPES,
                refetchActive: false,
            });
        };
    }, []);

    const onDeleteClick = (googlePostId: string, bulkId: number | null): void => {
        setPostId(googlePostId);
        setBulkId(bulkId);
        setShowConfirmationModal(true);
    };

    const onHideDeleteModal = () => {
        setShowConfirmationModal(false);
        setPostId('');
        setBulkId(null);
    };

    const onHideCreationModal = () => setShowCreationModal(false);
    const isLoading = usePostsListIsLoading();
    let illustrationElement: IllustrationLaptopElement;
    switch (true) {
        case isLoading:
            return <LoadingView />;
        case !subscribedToPM:
            return <NoPresenceManagement />;

        case totalBusinesses === 0:
            illustrationElement = {
                type: IllustrationCategoryEnum.Laptop,
                name: IllustrationLaptopEnum.Monocle,
            };
            return (
                <NotFoundTemplate
                    withButton
                    show
                    title={t('all_businesses_unable_to_create_posts')}
                    imgSrc={illustrationElement}
                    buttonText={t('contact_our_support')}
                    handleClick={() => {
                        try {
                            // @ts-ignore
                            window.Intercom('showNewMessage');
                        } catch (e) {
                            console.error(e.message);
                        }
                    }}
                />
            );
        default:
            return (
                <EditPostsContext.Provider value={editPostsContext}>
                    <PostsQueryContext.Provider value={postsQueryContext}>
                        <GooglePostDeleteConfirmModal
                            show={showConfirmationModal}
                            onHide={onHideDeleteModal}
                        />
                        {showCreationModal &&
                            ReactDOM.createPortal(
                                <PostCreationModalComponent
                                    show={showCreationModal}
                                    onHide={onHideCreationModal}
                                    isEditing={postCreationModalEditMode}
                                    postEditingData={postToEditData}
                                />,
                                document.body,
                            )}
                        <GooglePostsListPageHeader />
                        <PostListBanners />
                        <FilterBar count={postCount} />
                        <PostsTable
                            onDeletePost={(postId, bulkId) => onDeleteClick(postId + '', bulkId)}
                            onPostCountUpdate={(count: number) => setPostCount(count)}
                        />
                    </PostsQueryContext.Provider>
                </EditPostsContext.Provider>
            );
    }
};
