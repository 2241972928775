import { memo, useMemo } from 'react';

import { css } from '@emotion/react';
import { ColorVariants } from '@partoohub/branding';
import {
    FontAwesomeIconsPartooUsed,
    Icon,
    IconElement,
    IconPrefix,
    Stack,
    Text,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { StyledCard } from './Cards.styled';
import { StyledChartIcon, StyledIcon, StyledTag } from './Tags.styled';
import { getTagScores } from '../../../helper';
import { useReviewTagMetrics } from '../../../hooks/useMetrics';
import { useReviewAnalyticsDefaultFilter } from '../../../hooks/useReviewAnalyticsDefaultFilter';

type IconWithTagsProps = {
    icon: IconElement;
    color: ColorVariants;
    tags: Array<{ tagName: string; score: number }>;
    tagChecks: Array<{ lengthCheck: number; index: number }>;
};

const IconWithTags = memo(({ icon, color, tags, tagChecks }: IconWithTagsProps) => (
    <Stack direction="row" alignItems="center" gap="8px">
        <StyledIcon color={color}>
            <Icon icon={icon} color={color} iconSize="16px" />
        </StyledIcon>
        {tagChecks.map(({ lengthCheck, index }) => (
            <StyledTag key={index} variant="bodyMBold">
                {tags.length >= lengthCheck ? tags[index]?.tagName : '-'}
            </StyledTag>
        ))}
    </Stack>
));

export const TagsCard = () => {
    const { t } = useTranslation();
    const metrics = useReviewTagMetrics();
    const { isDefaultDateFilter } = useReviewAnalyticsDefaultFilter();

    const topData = metrics?.data.filter(d => d.metrics.average_rating >= 3.8) || [];
    const bottomData = metrics?.data.filter(d => d.metrics.average_rating < 3.8) || [];

    //Get top 2 tags from topData descending
    const topTagScores = useMemo(() => getTagScores(topData, false, 2), [topData]);
    // Get bottom 2 tags from bottomData ascending
    const bottomTagScores = useMemo(() => getTagScores(bottomData, true, 2), [bottomData]);

    const noTagData = !metrics?.data || metrics?.data.length === 0;

    return (
        <StyledCard>
            <Text variant="bodyMBold">
                {t('review_analytics_graph_section_tags')}{' '}
                {isDefaultDateFilter && (
                    <Text as="span" color="secondary">
                        ({t('date_criteria_last_twelve_months')})
                    </Text>
                )}
            </Text>
            {noTagData ? (
                <Stack
                    gap="8px"
                    alignItems="center"
                    justifyContent="center"
                    css={css`
                        flex: 1;
                        width: 100%;
                    `}
                >
                    <StyledChartIcon className="fa-solid fa-chart-simple"></StyledChartIcon>
                    <Text as="span" variant="bodyMRegular" color="secondary">
                        {t('review_analytics_no_tag_data')}
                    </Text>
                </Stack>
            ) : (
                <Stack
                    css={css`
                        width: 100%;
                    `}
                    gap="8px"
                >
                    <IconWithTags
                        icon={[FontAwesomeIconsPartooUsed.faFaceLaugh, IconPrefix.REGULAR]}
                        color="success"
                        tags={topTagScores}
                        tagChecks={[
                            { lengthCheck: 1, index: 0 },
                            { lengthCheck: 2, index: 1 },
                        ]}
                    />
                    <IconWithTags
                        icon={[FontAwesomeIconsPartooUsed.faFaceAngry, IconPrefix.REGULAR]}
                        color="danger"
                        tags={bottomTagScores}
                        tagChecks={[
                            { lengthCheck: 1, index: 0 },
                            { lengthCheck: 2, index: 1 },
                        ]}
                    />
                </Stack>
            )}
        </StyledCard>
    );
};
