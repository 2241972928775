import styled from '@emotion/styled';
import { convertToRgba } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

export const ProTipContainer = styled.div`
    background-color: ${({ theme }) => convertToRgba(theme.colors.secondary.alpha, 0.12)};
    border-radius: 8px;
    padding: 16px;
`;

export const AdviceList = styled.ul`
    padding-left: 10px;
`;

export const Advice = styled(Text)`
    list-style: disc inside;
`;
