import BusinessOpeningSoon from 'app/businessEditV2/components/BusinessEditV2Header/BusinessOpeningSoon';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useBusinessStatus } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import { isBusinessOpeningSoon } from 'app/businessEditV2/utils/businessOpeningSoon';

import { OPEN } from 'app/common/data/businessStatuses';
import { MetaRelogin } from 'app/pages/visibility/subComponents/MetaRelogin/MetaRelogin';

import { BannerContainer } from './BusinessEditV2Banner.styled';
import PageHeaderBusinessClosed from '../BusinessEditV2Header/PageHeaderBusinessClosed';

export const BusinessEditV2Banner = () => {
    const { data: business, isLoading } = useBusiness();
    const isOpen = useBusinessStatus() === OPEN;
    const isOpeningSoon = isBusinessOpeningSoon(business);

    const displayBusinessClosed = !isLoading && !isOpen;

    return (
        <BannerContainer gap="8px" direction="column">
            {displayBusinessClosed && <PageHeaderBusinessClosed />}
            <MetaRelogin />
            {isOpeningSoon && <BusinessOpeningSoon />}
        </BannerContainer>
    );
};
