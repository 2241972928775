import { PropsWithChildren } from 'react';

import { Card } from './SelectableCard.styled';

interface Props {
    isSelected: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
}

export const SelectableCard = ({
    disabled = false,
    isSelected,
    onChange,
    children,
}: PropsWithChildren<Props>) => {
    return (
        <Card onClick={() => onChange(!isSelected)} isSelected={isSelected} disabled={disabled}>
            {children}
        </Card>
    );
};
