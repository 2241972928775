import styled from '@emotion/styled';
import { COLLAPSED_SIDEBAR_WIDTH } from '@partoohub/modular-components';

import { LAYOUT_Y_PADDING } from 'app/common/components/Layout/Layout.styled';
import { DEFAULT_ANIMATION_IN_SECOND } from 'app/constants/constants';
import { MAX_TABLET_WIDTH } from 'app/hooks';

export const FIXED_CONTAINER_Y_PADDING = 16;

export const HeaderContainer = styled.div``;

export const FixedContainer = styled.div<{ height: number; intersecting: boolean }>`
    background-color: ${({ theme }) => theme.colors.theme.background};
    border-bottom: 1px solid ${({ theme }) => theme.colors.default.alpha};

    top: ${({ height }) => `-${height + LAYOUT_Y_PADDING * 2}px`};
    position: fixed;
    left: ${COLLAPSED_SIDEBAR_WIDTH}px;
    width: calc(100% - ${COLLAPSED_SIDEBAR_WIDTH}px);
    z-index: 2;
    padding: ${FIXED_CONTAINER_Y_PADDING}px 24px;

    transition:
        transform ${DEFAULT_ANIMATION_IN_SECOND * 3}s ease-in-out,
        opacity ${DEFAULT_ANIMATION_IN_SECOND * 3}s ease-in-out;

    transform: ${({ intersecting, height }) =>
        intersecting ? `translateY(${height + LAYOUT_Y_PADDING * 2}px)` : 'translateY(0)'};
    opacity: ${({ intersecting }) => (intersecting ? 1 : 0)};

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        left: 0;
        width: 100%;
    }
`;
