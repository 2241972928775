import { useTranslation } from 'react-i18next';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useHolidays from 'app/businessEditV2/hooks/useHolidays';
import { mergeSpecificHours } from 'app/businessEditV2/utils/specificHours';

import { useFormatDate } from 'app/common/hooks/useFormatDate';

type Props = {
    colorState?: ColorState;
};

const SpecificHoursSectionContent = ({ colorState = ColorState.OK }: Props) => {
    const { data: business } = useBusiness();

    const { data: holidays } = useHolidays();

    const { t } = useTranslation();

    const generateDate = useFormatDate();

    const specificHoursDays = mergeSpecificHours(business?.specific_hours ?? {}).sort(
        (day1, day2) => day1.starts_at.localeCompare(day2.starts_at),
    );
    const formattedDaysToDisplay = specificHoursDays.splice(0, 3).map(({ starts_at, ends_at }) => {
        const name = holidays?.holidays.find(
            holiday => holiday.date === starts_at && holiday.date === ends_at,
        )?.name;

        if (name) {
            return name;
        }
        if (starts_at === ends_at) {
            return generateDate(starts_at);
        }
        return `${generateDate(starts_at)} - ${generateDate(ends_at)}`;
    });
    const dayStrings = formattedDaysToDisplay.join(', ');
    const otherHoursNumber = specificHoursDays.length;
    const stringToDisplay =
        otherHoursNumber === 0 ? dayStrings : `${dayStrings}, +${otherHoursNumber}`;

    switch (colorState) {
        case ColorState.PRECHECK_ERRORS:
            return <>{t('business_edit_content_correct_this_field')}</>;
        case ColorState.MISSING_WARNING:
        case ColorState.CLOSED:
        case ColorState.DISABLED:
            return <>{t('business_edit_content_specific_hours')}</>;
        case ColorState.OK:
            return (
                <>
                    <i className="fa-solid fa-business-time" />
                    <span>{stringToDisplay}</span>
                </>
            );

        default:
            return null;
    }
};

export default SpecificHoursSectionContent;
