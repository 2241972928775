import { FC, PropsWithChildren } from 'react';

import { FontAwesomeIconsPartooUsed, Modal } from '@partoohub/ui';

import {
    FullScreen,
    FunnelModalContentWrapper,
    FunnelModalLeftButton,
    FunnelModalRightButton,
} from './FunnelModal.styled';

interface Props {
    isOpen: boolean;
    onClose?: () => void;
    onBack?: () => void;
    dataTrackId?: string;
    background?: string;
}

/**
 * /!\ This modal is meant to be used with the following pre-defined components as children :
 * - FunnelModalHeader
 * - FunnelModalBody
 * - FunnelModalFooter
 */
const FunnelModal: FC<PropsWithChildren<Props>> = ({
    isOpen,
    children,
    onBack,
    onClose,
    dataTrackId = 'funnel-modal',
    background,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            overlayAppearance="gradient"
            placement="center"
            animation="fadeIn"
            hideOnClickOutside={false}
            hideOnEsc={false}
            closeModal={() => undefined}
        >
            <FullScreen>
                {onBack && (
                    <FunnelModalRightButton
                        dataTrackId={`${dataTrackId}-back-button`}
                        icon={[FontAwesomeIconsPartooUsed.faArrowLeft]}
                        onClick={onBack}
                    />
                )}
                {onClose && (
                    <FunnelModalLeftButton
                        dataTrackId={`${dataTrackId}-close-button`}
                        icon={[FontAwesomeIconsPartooUsed.faClose]}
                        onClick={onClose}
                    />
                )}
                <FunnelModalContentWrapper background={background}>
                    {children}
                </FunnelModalContentWrapper>
            </FullScreen>
        </Modal>
    );
};

export default FunnelModal;
