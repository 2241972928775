import { UseQueryOptions, useQuery } from 'react-query';

import {
    CreateWidgetSetupResponse,
    MessagingWidgetSetup,
} from 'app/api/v2/api_calls/messagingWidgetSetupApiCalls';
import { MESSAGING_WIDGET_SETUP_GET } from 'app/common/data/queryKeysConstants';

export const useWidgetSetupGet = (widgetId: string, options?: UseQueryOptions<any>) => {
    return useQuery<CreateWidgetSetupResponse>(
        [MESSAGING_WIDGET_SETUP_GET, widgetId],
        () => MessagingWidgetSetup.getWidgetSetup(widgetId),
        options,
    );
};
