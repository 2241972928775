import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';

export const PhotoLogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 8px;
    width: 144px;
    height: 144px;
    border-radius: 144px;
    background-color: ${({ theme }) => theme.colors.theme.initial};
    bottom: 21px;
    left: 24px;
`;

type PhotoLogoProps = {
    url: string;
    sectionColor: ColorState;
};

export const PhotoLogo = styled.div<PhotoLogoProps>`
    position: relative;
    border: dashed 1px ${greyColorObject.initial};
    width: 128px;
    height: 128px;
    border-radius: 128px;
    background-image: url('${props => props.url}');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    ${props =>
        props.url &&
        css`
            background-color: ${greyColorObject.light};
            border: solid 1px ${greyColorObject.initial};
        `}

    ${({ sectionColor, theme }) =>
        [ColorState.PRECHECK_ERRORS, ColorState.MISSING_WARNING].includes(sectionColor) &&
        css`
            border: dashed 1px ${theme.colors.warning.initial};
        `};
`;

export const PhotoLogoOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition: all ease 0.3s;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    background-color: rgba(15, 22, 31, 0.5);

    &:hover {
        cursor: pointer;
        opacity: 1;
    }

    > i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background-color: ${HEX_COLORS.white};
        color: ${({ theme }) => theme.colors.secondary.initial};
        font-size: 16px;
    }
`;

type PhotoLogoOverlayNoLogoProps = {
    sectionColor: ColorState;
};

export const PhotoLogoOverlayNoLogo = styled(PhotoLogoOverlay)<PhotoLogoOverlayNoLogoProps>`
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.secondary.alpha};

    > svg {
        * {
            transition: all ease 0.2s;
        }
    }

    &:hover {
        > svg {
            * {
                fill: ${({ theme }) => theme.colors.warning.alpha};
            }
        }
    }

    ${({ sectionColor, theme }) =>
        [ColorState.PRECHECK_ERRORS, ColorState.MISSING_WARNING].includes(sectionColor) &&
        css`
            background-color: ${theme.colors.warning.alpha};

            > svg {
                * {
                    fill: ${theme.colors.warning.initial};
                }
            }
        `};

    ${({ sectionColor, theme }) =>
        [ColorState.DISABLED, ColorState.CLOSED].includes(sectionColor) &&
        css`
            background-color: ${theme.colors.secondary.alpha};

            > svg {
                * {
                    fill: ${theme.colors.secondary.alpha};
                }
            }

            &:hover {
                > svg {
                    * {
                        fill: ${theme.colors.secondary.alpha};
                    }
                }
            }
        `};
`;
