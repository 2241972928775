import { CompetitiveBenchmarkPageHeader } from 'app/pages/analytics/CompetitiveBenchmark/components/HeaderSection/CompetitiveBenchmarkPageHeader';
import { AnalyticsTabs } from 'app/pages/analytics/VisibilityAnalyticsV2/components/AnalyticsTabs';

import { CompetitiveBenchmark } from './CompetitiveBenchmark';

export const CompetitiveBenchmarkRootWithNewSidebar = () => {
    return (
        <>
            <CompetitiveBenchmarkPageHeader />
            <AnalyticsTabs />
            <CompetitiveBenchmark />
        </>
    );
};
