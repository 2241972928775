import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

type EditSectionContainerProps = {
    colorState: ColorState;
};

export const EDIT_SECTION_HEIGHT = 96;

export const EditSectionContainer = styled.button<EditSectionContainerProps>`
    display: flex;
    align-items: center;
    width: 100%;
    height: ${EDIT_SECTION_HEIGHT}px;
    padding: 20px 20px 20px 16px;
    background: none;
    outline: inherit;
    border-radius: 8px;
    border: solid 1px ${DISABLED_COLOR};
    transition: all 0.2s;

    &:hover {
        cursor: pointer;
    }

    > i {
        color: ${({ colorState, theme }) =>
            [ColorState.DISABLED, ColorState.CLOSED].includes(colorState)
                ? DISABLED_COLOR
                : theme.colors.secondary.initial};
    }

    ${({ colorState, theme }) =>
        [ColorState.PRECHECK_ERRORS, ColorState.MISSING_WARNING].includes(colorState) &&
        css`
            border: solid 1px transparent;
            background-color: ${theme.colors.warning.alpha};
            &:hover {
                border: solid 1px ${theme.colors.warning.light};
            }
        `};

    ${({ colorState, theme }) =>
        [ColorState.DISABLED, ColorState.CLOSED].includes(colorState) &&
        css`
            border: solid 1px ${theme.colors.secondary.alpha};
            &:hover {
                border: solid 1px ${greyColorObject.dark};
            }
        `};

    ${props =>
        props.colorState === ColorState.OK &&
        css`
            &:hover {
                border: solid 1px ${greyColorObject.dark};
            }
        `};

    > i {
        color: ${({ colorState, theme }) =>
            [ColorState.DISABLED, ColorState.CLOSED].includes(colorState)
                ? DISABLED_COLOR
                : theme.colors.secondary.initial};
    }
`;

type EditSectionIconProps = {
    colorState: ColorState;
};

export const EditSectionIcon = styled.div<EditSectionIconProps>`
    display: flex;
    align-items: center;

    > i,
    svg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        border-radius: 56px;
        overflow: hidden;
        margin-right: 16px;
        font-size: 26px;
        color: ${({ theme }) => theme.colors.success.initial};
        background-color: ${({ theme }) => theme.colors.success.alpha};

        ${({ colorState, theme }) =>
            [ColorState.PRECHECK_ERRORS, ColorState.MISSING_WARNING].includes(colorState) &&
            css`
                color: ${theme.colors.warning.initial};
                background-color: ${theme.colors.warning.alpha};
            `};

        ${({ colorState, theme }) =>
            [ColorState.DISABLED, ColorState.CLOSED].includes(colorState) &&
            css`
                color: ${theme.colors.secondary.alpha};
                background-color: ${theme.colors.secondary.alpha};
            `};
    }
`;

export const EditSectionText = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    justify-content: center;
    margin-right: 8px;
`;

type EditSectionTitleProps = {
    disabled: boolean;
};

export const EditSectionTitle = styled.div<EditSectionTitleProps>`
    font-weight: bold;
    color: ${props => (props.disabled ? DISABLED_COLOR : props.theme.colors.default.initial)};
    text-align: left;
    margin-bottom: 4px;
`;

type EditSectionDescriptionProps = {
    disabled: boolean;
};

export const EditSectionDescription = styled.div<EditSectionDescriptionProps>`
    color: ${({ disabled, theme }) => (disabled ? DISABLED_COLOR : theme.colors.secondary.initial)};
    text-align: left;

    i {
        font-size: 12px;
        margin-right: 4px;
    }

    > span {
        margin-right: 12px;
    }
`;
