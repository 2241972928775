import { useMemo, useState } from 'react';

import { Table } from '@partoohub/ui';

import { ColumnProps } from '@partoohub/ui/dist/components/Table';

import {
    CompetitorRow,
    RowOptions,
    RowValues,
} from 'app/pages/analytics/CompetitiveBenchmark/components/CompetitorTableSection/CompetitorRow/CompetitorRow';
import { TableContainer } from 'app/pages/analytics/CompetitiveBenchmark/components/CompetitorTableSection/CompetitorTableSection.styled';

export type Column = Omit<ColumnProps, 'isOrdered' | 'onOrder' | 'orderDirection'>;
export type OrderableColumn = Column & { orderOn: keyof RowValues };

type TableOrders = 'asc' | 'desc';

type Order = {
    columnId: keyof RowValues;
    order: TableOrders;
};

type Props = {
    columns: Array<Column>;
    rows: Array<RowOptions>;
    isLoading: boolean;
};

function isOrderableColumn(column: Column | OrderableColumn): column is OrderableColumn {
    return 'orderOn' in column;
}

export const CompetitorTableSection = ({ columns, rows, isLoading }: Props) => {
    const [order, setOrder] = useState<Order | null>({ columnId: 'rank', order: 'asc' });
    const buildOrder = (column: Column | OrderableColumn): ColumnProps => {
        if (!order || !isOrderableColumn(column)) {
            return column;
        }
        return {
            isOrdered: order.columnId === column.orderOn,
            orderDirection: order.order,
            onOrder: (newOrder: TableOrders) =>
                setOrder({ columnId: column.orderOn, order: newOrder }),
            ...column,
        };
    };

    const tableColumns = useMemo(() => columns.map(column => buildOrder(column)), [columns, order]);

    const sortedRows = useMemo(() => {
        if (!order?.columnId) {
            return rows;
        }
        return [...rows].sort((a, b) => {
            const columnId = order.columnId;
            const orderMultiplicator = order.order === 'asc' ? 1 : -1;

            const aValue = a[columnId] ?? Infinity;
            const bValue = b[columnId] ?? Infinity;

            if (aValue < bValue) {
                return -1 * orderMultiplicator;
            }
            if (aValue > bValue) {
                return 1 * orderMultiplicator;
            }

            return 0;
        });
    }, [rows, order]);

    return (
        <TableContainer>
            <Table loading={isLoading} columns={tableColumns} selectable={false}>
                <Table.Body>
                    {sortedRows.map((row, i) => (
                        <CompetitorRow key={i} options={row} />
                    ))}
                </Table.Body>
            </Table>
        </TableContainer>
    );
};
