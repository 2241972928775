import { useCallback, useMemo } from 'react';

import { css } from '@emotion/react';
import { Button, IconPrefix, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { STATIC_APP_ASSETS_URL } from 'app/config';
import { ReviewsV2PageHeader } from 'app/pages/analytics/ReviewAnalyticsV2/Sections/HeaderSection/ReviewsV2PageHeader';
import { AnalyticsTabs } from 'app/pages/analytics/VisibilityAnalyticsV2/components/AnalyticsTabs';
import { CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH } from 'app/routing/routeIds';

import { useGraphSelection } from './hooks/useGraphSelection';
import { useReviewTagMetrics } from './hooks/useMetrics';
import {
    CardsSection,
    FilterSection,
    GraphSection,
    GraphSelectorSection,
    InternalRankingSection,
    UpdateDateSection,
} from './Sections';

export const ReviewAnalyticsV2WithNewSidebar = () => {
    const { t } = useTranslation();
    const [graph] = useGraphSelection();

    const metrics = useReviewTagMetrics();

    const navigate = useNavigate();

    const handleGoToReview = useCallback(() => {
        navigate(CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH);
    }, [navigate]);

    const noTagsAssigned = !metrics?.data || metrics.data.length === 0;

    const renderNoTag = useMemo(() => {
        return (
            <Stack
                gap="16px"
                alignItems="center"
                justifyContent="center"
                style={{ height: '380px' }}
            >
                <img
                    width="154px"
                    src={`${STATIC_APP_ASSETS_URL}/reviews/review_analytics_tag.png`}
                    alt={t('prepare_data_title')}
                />
                <Text as="span" variant="heading3">
                    {t('review_analytics_no_tag_assigned_title')}
                </Text>
                <Text as="span" variant="bodyMRegular" color="secondary">
                    {t('review_analytics_no_tag_assigned_content')}
                </Text>
                <Button
                    dataTrackId="review_analytics_tag_to_assign__button"
                    onClick={handleGoToReview}
                    icon={['fa-arrow-right', IconPrefix.REGULAR]}
                    iconPosition="right"
                >
                    {t('review_analytics_no_tag_assigned_button')}
                </Button>
            </Stack>
        );
    }, [handleGoToReview, t]);

    return (
        <>
            <ReviewsV2PageHeader />
            <AnalyticsTabs />
            <Stack>
                <Stack direction="row" justifyContent="space-between">
                    <FilterSection />
                    <UpdateDateSection />
                </Stack>
                <CardsSection />
                <Stack direction="row">
                    <GraphSelectorSection />
                    <Stack
                        css={css`
                            min-width: calc(75% - 16px);
                        `}
                    >
                        {graph === 'tags' && noTagsAssigned ? (
                            renderNoTag
                        ) : (
                            <>
                                <GraphSection />
                                <InternalRankingSection />
                            </>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};
