import { useState } from 'react';

import { Button, Option, SingleSelect, Text, theme } from '@partoohub/ui';

import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { V2BusinessData } from 'app/api/types/business';

import { useGetBrands } from 'app/common/hooks/queries/useGetBrands';

interface BrandCreatorProps {
    onChange: (value: string) => void;
    fieldError: FieldError | undefined;
}

export const BrandCreator = ({ onChange, fieldError }: BrandCreatorProps) => {
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState<Option | undefined>(undefined);
    const { query, businesses: brands } = useGetBrands();

    const handleChange = (brandOption: Option | undefined) => {
        setSelectedValue(brandOption);
        onChange(brandOption!.value);
    };

    const options = brands?.map((brand: V2BusinessData) => ({
        label: brand.name,
        name: brand.name,
        value: brand.id,
    }));

    return (
        <>
            <SingleSelect
                dataTrackId="widget_setup_brand_select"
                onChange={handleChange}
                label={t('widget_setup_brand_selection_input') + '*'}
                sections={options ? [{ options }] : []}
                selectedValue={selectedValue}
                hasMore={query.hasNextPage}
                loadMore={query.fetchNextPage}
            />
            {fieldError && (
                <Text color={theme.colors.danger.initial}>
                    {t('widget_setup_brand_selection_error')}
                </Text>
            )}
            <Button dataTrackId={''}>{t('widget_setup_brand_creation')}</Button>
        </>
    );
};
