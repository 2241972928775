import { ContentToast, Progress, SnackbarVariant, Stack, Text, toast } from '@partoohub/ui';

import i18n from 'app/common/translations/i18n';

export const BUSINESS_IMPORT_TOAST_ID = 'business_import_snackbar';
export type BusinessImportToastType = 'success' | 'loading' | 'warning' | 'error';

interface ToastWithProgressProps {
    description: string;
    title: string;
    type?: BusinessImportToastType;
    completed?: number;
    toUpdate?: boolean;
    // Necessary if you want to update toast. Otherwise you Title and Icon
    total?: number;
}

export const ToastWithProgress = ({
    type,
    title,
    description,
    completed = 0,
    total = 1,
    toUpdate = false,
}: ToastWithProgressProps) => {
    const percentage = completed > 0 ? Math.ceil((completed / total) * 100) : undefined;
    const DescriptionComponent = (
        <Stack justifyContent="left" gap="4px" style={{ flex: 1 }}>
            {description && (
                <Text variant="bodyMRegular" as="span" color="secondary">
                    {description}
                </Text>
            )}
            {typeof percentage === 'number' && <Progress variant="primary" value={percentage} />}
        </Stack>
    );
    return toUpdate ? (
        <ContentToast
            variant={type === 'loading' ? 'progress' : (type as SnackbarVariant)}
            title={title}
        >
            {DescriptionComponent}
        </ContentToast>
    ) : (
        DescriptionComponent
    );
};

type Props = {
    type: BusinessImportToastType;
    completed: number;
    failed?: number;
    total?: number;
    isNew?: boolean;
    hasFinishedStatus?: boolean;
};

export const buildToastWithProgress = ({
    type,
    completed,
    failed,
    total,
    isNew,
    hasFinishedStatus,
}: Props) => {
    const title = i18n.t(`onboarding_snackbar_title_${type}`, {
        count: completed,
    });
    const description = i18n.t(`onboarding_snackbar_description_${type}`, {
        count: completed,
        failed,
        total,
    });
    const ToastComponent = (
        <ToastWithProgress
            type={type}
            title={title}
            description={description}
            completed={completed}
            toUpdate={!hasFinishedStatus && !isNew}
            total={total}
        />
    );

    if (isNew) {
        toast.loading(ToastComponent, title, {
            toastId: BUSINESS_IMPORT_TOAST_ID,
            closeOnClick: false,
            autoClose: false,
        });
    } else if (hasFinishedStatus) {
        toast.dismiss(BUSINESS_IMPORT_TOAST_ID);
        toast.success(description, title);
    } else {
        toast.update(BUSINESS_IMPORT_TOAST_ID, ToastComponent, undefined, {
            toastId: BUSINESS_IMPORT_TOAST_ID,
            closeOnClick: false,
            autoClose: false,
        });
    }
};
