import { Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { BusinessReachEnum } from 'app/api/types/business';
import BusinessOpeningSoon from 'app/businessEditV2/components/BusinessEditV2Header/BusinessOpeningSoon';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useBusinessStatus } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import MenuIcon from 'app/common/components/icons/MenuIcon';
import MobilePageHeader from 'app/common/components/pageHeaders/MobilePageHeader';
import MobilePageSubHeader from 'app/common/components/pageHeaders/MobilePageSubHeader';
import { OPEN } from 'app/common/data/businessStatuses';
import IS_IFRAME from 'app/common/data/iframe';

import { collapseSidebar } from 'app/common/reducers/global';

import { useIsTablet } from 'app/hooks/useIsTablet';
import { DiffusionListButton } from 'app/pages/visibility/BusinessListV2/sections/BusinessListHeader/HeaderButtons/Buttons/DiffusionListButton';

import { BusinessEditV2HeaderContainer, Title } from './BusinessEditV2Header.styled';
import CTAButtons from './CTAButtons';

const BusinessEditV2Header = () => {
    const { t } = useTranslation();
    const isTablet = useIsTablet();
    const dispatch = useDispatch();
    const openSidebar = () => dispatch(collapseSidebar());

    const { data: business, isLoading } = useBusiness();
    const isOpen = useBusinessStatus() === OPEN;

    const buttons = !isLoading && isOpen && (
        <Stack
            gap="8px"
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            key="cta_buttons"
        >
            {business?.reach !== BusinessReachEnum.GLOBAL && <CTAButtons />}
            {!IS_IFRAME && <DiffusionListButton />}
        </Stack>
    );

    return isTablet ? (
        <>
            <MobilePageHeader
                leftContent={<MenuIcon onClick={openSidebar} />}
                title={t('sidebar_visibility')}
                hideLeftContentOnIframe
            />
            <MobilePageSubHeader
                title={t('business_edit_my_business')}
                actions={buttons ? [buttons] : []}
            />
        </>
    ) : (
        <BusinessEditV2HeaderContainer>
            <Title>
                <Text variant="heading3">{t('business_edit_my_business')}</Text>
            </Title>
            {buttons}
        </BusinessEditV2HeaderContainer>
    );
};

export default BusinessEditV2Header;
