import { PropsWithChildren, createContext, useMemo, useState } from 'react';

interface MessagingContextType {
    isRequestingNewConversations: boolean;
    setIsRequestingNewConversations: (val: boolean) => void;
}

export const MessagingContext = createContext<MessagingContextType>({
    isRequestingNewConversations: false,
    setIsRequestingNewConversations: () => undefined,
});

export const MessagingProvider = ({ children }: PropsWithChildren) => {
    const [isRequestingNewConversations, setIsRequestingNewConversations] =
        useState<boolean>(false);

    const value = useMemo(
        () => ({
            isRequestingNewConversations,
            setIsRequestingNewConversations,
        }),
        [isRequestingNewConversations],
    );

    return <MessagingContext.Provider value={value}>{children}</MessagingContext.Provider>;
};
