import styled from '@emotion/styled';

import Flexbox from 'app/styles/utils/flexbox';

export const InboxStyled = styled(Flexbox)`
    background: ${({ theme }) => theme.colors.theme.background};
    overflow: auto;
`;

// TODO: Tricia - height: 0 is css hack to avoid the scrollbar to be displayed
export const ConversationCardsContainer = styled.div<{ isNewSidebarEnabled: boolean }>`
    flex: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 4px 0px;

    ${({ isNewSidebarEnabled }) => `height:  ${isNewSidebarEnabled ? 0 : 'initial'};`}
`;
