import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { URLParams } from 'app/states/messaging';

import { useGetConversations } from './useGetConversations';

export const useGetActiveConversation = () => {
    const { conversations } = useGetConversations();
    const [conversationParamId] = useStateQueryParams(URLParams.CONVERSATION_ID);

    return conversations.find(conversation => conversation.id === Number(conversationParamId));
};
