export const ExportIcon = () => {
    return (
        <svg height="93" viewBox="3 19 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50.0001" cy="65.5001" r="46.2907" fill="#7F8EA4" fillOpacity="0.12" />
            <path
                d="M69.3051 52.9863H27.9349C26.5613 52.9863 25.4478 54.0999 25.4478 55.4735V87.6939H71.7924V55.4735C71.7923 54.0999 70.6787 52.9863 69.3051 52.9863Z"
                fill="#302C49"
            />
            <path
                d="M71.5749 91.3063H25.9233C23.7566 91.3063 22 89.5499 22 87.3831V85.5747H75.4982V87.3831C75.4982 89.5499 73.7417 91.3063 71.5749 91.3063Z"
                fill="#525263"
            />
            <path d="M68.7346 56.0435H28.5039V82.5185H68.7346V56.0435Z" fill="#BCBCBC" />
            <path
                d="M52.4611 88.4406H45.0361C43.8758 88.4406 42.9351 87.4999 42.9351 86.3396V85.5747H54.5621V86.3396C54.5621 87.4999 53.6214 88.4406 52.4611 88.4406Z"
                fill="#F2F2F2"
            />
            <g filter="url(#filter0_d_1759_5775)">
                <path
                    d="M70.5033 39.5H55.9241C53.9591 39.5 52.3604 41.0987 52.3604 43.0638V69.1119C52.3604 71.077 53.9591 72.6757 55.9241 72.6757H74.0671C76.0322 72.6757 77.6309 71.077 77.6309 69.1119V46.6276L70.5033 39.5Z"
                    fill="white"
                />
            </g>
            <path
                d="M70.9399 52.4307H59.9246C59.3878 52.4307 58.9526 51.9955 58.9526 51.4588C58.9526 50.922 59.3878 50.4868 59.9246 50.4868H70.9399C71.4767 50.4868 71.9119 50.922 71.9119 51.4588C71.9119 51.9955 71.4767 52.4307 70.9399 52.4307Z"
                fill="#A0A9B5"
            />
            <path
                d="M70.9399 56.5723H62.5164C61.9796 56.5723 61.5444 56.1371 61.5444 55.6004C61.5444 55.0636 61.9796 54.6284 62.5164 54.6284H70.9399C71.4767 54.6284 71.9118 55.0636 71.9118 55.6004C71.9118 56.1371 71.4767 56.5723 70.9399 56.5723Z"
                fill="#A0A9B5"
            />
            <path
                d="M70.9399 60.7134H62.5164C61.9796 60.7134 61.5444 60.2782 61.5444 59.7415C61.5444 59.2047 61.9796 58.7695 62.5164 58.7695H70.9399C71.4767 58.7695 71.9118 59.2047 71.9118 59.7415C71.9118 60.2782 71.4767 60.7134 70.9399 60.7134Z"
                fill="#A0A9B5"
            />
            <path
                d="M70.9399 64.855H59.9246C59.3878 64.855 58.9526 64.4198 58.9526 63.8831C58.9526 63.3463 59.3878 62.9111 59.9246 62.9111H70.9399C71.4767 62.9111 71.9119 63.3463 71.9119 63.8831C71.9119 64.4198 71.4767 64.855 70.9399 64.855Z"
                fill="#A0A9B5"
            />
            <path
                d="M70.5034 39.5V45.6556C70.5034 46.1924 70.9386 46.6276 71.4754 46.6276H77.631L70.5034 39.5Z"
                fill="#D7D7D7"
            />
            <g clipPath="url(#clip0_1759_5775)">
                <path
                    d="M33.9574 71.6409C34.3196 71.6409 34.6341 71.3789 34.6947 71.0157C35.2345 67.8635 38.2869 62.3619 46.0296 62.0405V66.0849C46.0296 66.3651 46.1862 66.6219 46.4354 66.7501C46.6846 66.8783 46.9845 66.8565 47.2126 66.6937L58.4309 58.6805C58.6275 58.5401 58.7441 58.3133 58.7441 58.0717C58.7441 57.8301 58.6275 57.6034 58.4309 57.463L47.2125 49.4499C46.9845 49.287 46.6846 49.2652 46.4354 49.3935C46.1862 49.5217 46.0296 49.7784 46.0296 50.0587V54.1641C41.2436 54.6414 33.2086 58.0961 33.2086 70.8927C33.2086 71.282 33.5071 71.6062 33.8951 71.6383C33.916 71.64 33.9368 71.6409 33.9574 71.6409Z"
                    fill="#FF5C9A"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_1759_5775"
                    x="0.461159"
                    y="0.71977"
                    width="129.069"
                    height="136.974"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="13.119" />
                    <feGaussianBlur stdDeviation="25.9496" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1759_5775"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1759_5775"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_1759_5775">
                    <rect
                        width="25.5355"
                        height="25.5355"
                        fill="white"
                        transform="matrix(-1 0 0 1 58.7441 47.708)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
