import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import type { OverviewType } from 'app/api/types/competitiveBenchmark';

import { OverviewMenuOption, OverviewSelectionContainer } from './OverviewSelection.styled';
import { useOverviewSelection } from '../../hooks/useOverviewSelection';

export const OverviewSelection = () => {
    const { t } = useTranslation();
    const [overviewSelection, setOverviewSelection] = useOverviewSelection();

    const options = [
        { value: 'rank', label: t('ranking_word') },
        { value: 'average_reviews', label: t('reviews_count') },
        { value: 'average_rating', label: t('average_grade') },
    ];

    return (
        <OverviewSelectionContainer>
            {options.map(option => (
                <OverviewMenuOption
                    key={option.value}
                    label={
                        <Text as="span" variant="bodyMBold" color="secondary">
                            {option.label}
                        </Text>
                    }
                    dataTrackId={option.value}
                    value={option.value}
                    checked={overviewSelection === option.value}
                    onChange={() => setOverviewSelection(option.value as OverviewType)}
                />
            ))}
        </OverviewSelectionContainer>
    );
};
