import { useMemo } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Overview } from 'app/pages/analytics/CompetitiveBenchmark/components/OverviewSection/Overview';
import { OverviewSelection } from 'app/pages/analytics/CompetitiveBenchmark/components/OverviewSection/OverviewSelection';

import { BenchmarkContent } from './content.styled';

import { useComparisonDateFilter } from '../../hooks/useComparisonDateFilter';
import { useCompetitorColors } from '../../hooks/useCompetitorColors';
import { useGlobalRankings } from '../../hooks/useGlobalRankings';
import { buildGlobalRows } from '../../utils/buildTableRowOptions';
import { CompetitorTableSection } from '../CompetitorTableSection/CompetitorTableSection';

const getGlobalColumns = (t: TFunction<'translation', undefined>) => {
    return [
        {
            label: t('name'),
        },
        {
            label: t('appearance_rate_word'),
            orderOn: 'appearance',
            info: t('tooltip_appearance_rate'),
        },
        {
            label: t('ranking_word'),
            orderOn: 'rank',
            info: t('ranking_word_tooltip'),
        },
        {
            label: t('reviews_count'),
            orderOn: 'reviews',
            info: t('reviews_count_tooltip'),
        },
        {
            label: t('average_grade'),
            orderOn: 'rating',
            info: t('average_grade_tooltip'),
        },
    ];
};

export const GlobalCompetitiveBenchmark = () => {
    const [currentDate, comparisonDate] = useComparisonDateFilter();
    const { data, isLoading } = useGlobalRankings(currentDate);
    const { data: lastData } = useGlobalRankings(comparisonDate);

    const colors = useCompetitorColors();
    const rows = buildGlobalRows(data, lastData, colors);
    const { t } = useTranslation();
    const columns = useMemo(() => getGlobalColumns(t), []);
    return (
        <>
            <OverviewSelection />
            <BenchmarkContent>
                <Overview />
                <CompetitorTableSection rows={rows} isLoading={isLoading} columns={columns} />
            </BenchmarkContent>
        </>
    );
};
