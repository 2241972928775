import LoadingView from 'app/common/components/LoadingView';
import { NoBusiness } from 'app/common/components/placeholder/NoBusiness';
import { NoReviewBooster } from 'app/common/components/placeholder/ReviewBooster/NoReviewBooster';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import { useIsTablet } from 'app/hooks/useIsTablet';
import { useReviewBoosterInitialDataLoad } from 'app/pages/conversations/ReviewBooster/hooks/useReviewBoosterInitialDataLoad';

import { DesktopHeader } from './pageHeader/DesktopHeader/DesktopHeader';
import { MobileHeader } from './pageHeader/MobileHeader/MobileHeader';
import { StyledReviewBooster } from './ReviewBooster.styled';
import ReviewBoosterForm from './ReviewBoosterForm';

export default function ReviewBooster() {
    const isTablet = useIsTablet();

    const {
        isLoading: subscriptionsLoading,
        hasBusinesses,
        subscribedToRB,
    } = useBusinessSubscriptions();
    const { isLoading: isInitialDataLoadLoading } = useReviewBoosterInitialDataLoad();

    if (subscriptionsLoading || isInitialDataLoadLoading) {
        return <LoadingView />;
    }

    if (!hasBusinesses) {
        return <NoBusiness />;
    }

    if (!subscribedToRB) {
        return <NoReviewBooster />;
    }

    return (
        <StyledReviewBooster>
            {isTablet ? <MobileHeader /> : <DesktopHeader />}
            <ReviewBoosterForm />
        </StyledReviewBooster>
    );
}
