import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import UnsavedModal from 'app/businessEditV2/components/UnsavedModal';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import AdvancedSettingsForm from './AdvancedSettingsForm';

type Props = {
    showModal: boolean;
    setShowModal: (arg: boolean) => void;
    closeSafeModal: () => void;
    showUnsavedModal: boolean;
    setShowUnsavedModal: (arg: boolean) => void;
    useDirtyUpdate: (arg: boolean) => void;
};

const AdvancedSettingsModal = ({
    showModal,
    setShowModal,
    closeSafeModal,
    showUnsavedModal,
    setShowUnsavedModal,
    useDirtyUpdate,
}: Props) => {
    const { t } = useTranslation();

    return (
        <FullScreenModal
            show={showModal}
            childrenRight={
                <EditFormDescription
                    title={t('business_edit_title_advanced_settings')}
                    description={t('business_edit_description_advanced_settings')}
                    advice={t('business_edit_advice_advanced_settings')}
                />
            }
            childrenLeft={
                <>
                    <AdvancedSettingsForm
                        closeModal={() => setShowModal(false)}
                        useDirtyUpdate={useDirtyUpdate}
                    />
                    <UnsavedModal
                        show={showUnsavedModal}
                        cancel={() => setShowUnsavedModal(false)}
                        validate={() => setShowModal(false)}
                    />
                </>
            }
            onHideClick={closeSafeModal}
        />
    );
};

export default AdvancedSettingsModal;
