import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationElement,
    IllustrationVariousEnum,
} from '@partoohub/iconography';
import { Dialog, Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { Advice, AdviceList, ProTipContainer } from './RankingExplanation.styled';
import { useComparisonDateFilter } from '../../hooks/useComparisonDateFilter';
import { useGlobalRankings } from '../../hooks/useGlobalRankings';

function getRankingExplanation(rank: number | undefined): RankingExplanation {
    if (rank == undefined) return BadRankingExplanation;
    if (rank <= 9) return GoodRankingExplanation;
    return AverageRankingExplanation;
}

type RankingExplanation = {
    illustration: IllustrationElement;
    title: string;
    subTitle: string;
    advices: string[];
    tips: string;
};

const GoodRankingExplanation: RankingExplanation = {
    illustration: {
        type: IllustrationCategoryEnum.Various,
        name: IllustrationVariousEnum.RankingGood,
    },
    title: 'good_ranking_explanation_title',
    subTitle: 'good_ranking_explanation_introduction',
    advices: ['good_ranking_explanation_first_advice', 'good_ranking_explanation_second_advice'],
    tips: 'good_ranking_explanation_tips',
};

const AverageRankingExplanation: RankingExplanation = {
    illustration: {
        type: IllustrationCategoryEnum.Various,
        name: IllustrationVariousEnum.RankingBad,
    },
    title: 'mediocre_ranking_explanation_title',
    subTitle: 'mediocre_ranking_explanation_introduction',
    advices: [
        'mediocre_ranking_explanation_first_advice',
        'mediocre_ranking_explanation_second_advice',
        'mediocre_ranking_explanation_third_advice',
    ],
    tips: 'mediocre_ranking_explanation_tips',
};

const BadRankingExplanation: RankingExplanation = {
    illustration: {
        type: IllustrationCategoryEnum.Various,
        name: IllustrationVariousEnum.RankingNone,
    },
    title: 'no_ranking_explanation_',
    subTitle: 'no_ranking_ranking_introduction',
    advices: [
        'mediocre_ranking_explanation_first_advice',
        'mediocre_ranking_explanation_second_advice',
        'mediocre_ranking_explanation_third_advice',
    ],
    tips: 'no_ranking_ranking_tips',
};

type RankingExplanationModalProps = {
    opened: boolean;
    onClose: () => void;
};

export const RankingExplanationModal = ({ opened, onClose }: RankingExplanationModalProps) => {
    const { t } = useTranslation();
    const [currentDate] = useComparisonDateFilter();
    const { data } = useGlobalRankings(currentDate);
    const rank = data.find(rank => rank.highlight)?.rank;
    const explanation = getRankingExplanation(rank);

    return (
        <Modal isOpen={opened} closeModal={onClose}>
            <Dialog
                dataTrackId="ranking_explanation"
                header={
                    <Illustration
                        dataTrackId="competitive_benchmark__ranking_asset"
                        illustration={explanation.illustration}
                        fullSize
                    />
                }
                onClose={onClose}
                subTitle={t(explanation.subTitle)}
                title={t(explanation.title)}
            >
                <AdviceList>
                    {explanation.advices.map(advice => (
                        <Advice key={advice} variant="bodyMRegular" as="li" color="secondary">
                            {t(advice)}
                        </Advice>
                    ))}
                </AdviceList>
                <ProTipContainer>{t(explanation.tips)}</ProTipContainer>
            </Dialog>
        </Modal>
    );
};
