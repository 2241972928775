import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const CHECKBOX_GM_POSITION = 300;
export const CHECKBOX_BM_POSITION = 100;

export const PermissionsRoleBannerContainer = styled.div`
    display: flex;
    align-items: center;
    padding-inline: 24px;
    position: relative;

    margin-bottom: 16px;
    border-radius: 4px;
    height: 32px;
    background-color: ${({ theme }) => theme.colors.secondary.alpha};

    > span {
        letter-spacing: 2px;
        text-transform: uppercase;
    }
`;

export const TextGM = styled(Text)`
    position: absolute;
    // Hardcoded value to align with the checkbox
    right: ${CHECKBOX_GM_POSITION}px;
    transform: translateX(50%);
`;

export const TextBM = styled(Text)`
    position: absolute;
    // Hardcoded value to align with the checkbox
    right: ${CHECKBOX_BM_POSITION}px;
    transform: translateX(50%);
`;
