import React, { useEffect, useRef } from 'react';

import { TFunction } from 'i18next';

import { AnimatedCounter } from 'app/businessConnection/components/AccountsList/AccountRow/AnimatedCounter';

import { CountProgress, Icon, IconText, RowInfo } from './AccountImportRow.styled';

type Props = {
    t: TFunction<'translation', undefined>;
    doneCount: number;
    explanationKey: string;
    variant: 'success' | 'danger' | 'primary';
    isDone: boolean;
    totalCount?: number;
};

export const AccountImportRow = ({
    t,
    doneCount,
    explanationKey,
    variant,
    isDone,
    totalCount,
}: Props) => {
    const prevDoneCount = useRef(0);

    useEffect(() => {
        prevDoneCount.current = doneCount;
    }, [doneCount]);

    return (
        <RowInfo>
            <IconText>
                <Icon className={variant}>
                    <span className="fa-stack">
                        {isDone ? (
                            <i
                                className={`fa-solid ${variant === 'danger' ? 'fa-circle-xmark' : 'fa-check-circle'} fa-stack-2x`}
                            />
                        ) : (
                            <>
                                <i className="fa-solid fa-circle fa-stack-2x" />
                                <i className="fa-solid fa-sync fa-spin fa-stack-1x fa-inverse" />
                            </>
                        )}
                    </span>
                </Icon>
                <div>{t(explanationKey)}</div>
            </IconText>

            <CountProgress className={variant}>
                {typeof totalCount === 'number' ? (
                    `${doneCount} / ${totalCount}`
                ) : (
                    <AnimatedCounter from={prevDoneCount.current} to={doneCount} />
                )}
            </CountProgress>
        </RowInfo>
    );
};
