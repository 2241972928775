import { useMutation } from 'react-query';

import {
    CreateWidgetSetupData,
    CreateWidgetSetupResponse,
    MessagingWidgetSetup,
} from 'app/api/v2/api_calls/messagingWidgetSetupApiCalls';

export const useWidgetSetupCreate = () => {
    return useMutation<CreateWidgetSetupResponse, any, CreateWidgetSetupData>(
        widgetData => MessagingWidgetSetup.createWidgetSetup(widgetData),
        {
            onSuccess: () => {},
        },
    );
};
