import styled from '@emotion/styled';
import { whiteColorObject } from '@partoohub/branding';

import { GAP } from 'app/common/components/Layout/Layout.styled';
import { DEFAULT_ANIMATION_IN_SECOND } from 'app/constants/constants';
import { FIXED_CONTAINER_Y_PADDING } from 'app/routing/components/FixedPageHeader/FixedPageHeader.styled';

export const PAGE_HEADER_HEIGHT = 40;

export const SettingSkeletonContainer = styled.div<{ isBelowTablet: boolean }>`
    display: flex;
    flex-direction: ${({ isBelowTablet }) => (isBelowTablet ? 'column' : 'row')};
    gap: 24px;
`;

export const MenuListContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.theme.background};
    height: 100%;
`;

export const ScrollToBottomContainer = styled.div`
    position: absolute;
    z-index: 101;
    bottom: 96px;
    right: 24px;
    border-radius: 50%;
    backdrop-filter: blur(5px) brightness(1.2);
    box-shadow: ${({ theme }) => theme.shadows.block};
    background-color: ${whiteColorObject['50']};
`;

export const StickySettingsMenuList = styled.div<{
    isSticky: boolean;
    isNewSidebarEnabled: boolean;
}>`
    position: sticky;
    top: ${({ isNewSidebarEnabled }) => `${isNewSidebarEnabled ? 0 : -16}px`};
    height: 100%;
    transform: translateY(0);
    transition: transform ${DEFAULT_ANIMATION_IN_SECOND}s ease-in-out;

    ${({ isSticky, isNewSidebarEnabled }) =>
        isSticky &&
        isNewSidebarEnabled &&
        `
        transform: translateY(${GAP + FIXED_CONTAINER_Y_PADDING * 2 + PAGE_HEADER_HEIGHT}px);

    `};
`;
