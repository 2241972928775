import { useEffect, useState } from 'react';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { AssignFilterOption, StatusFilterOption, URLParams } from 'app/states/messaging';

import queryClient from 'app/states/queryClient';

import { getConversationsKey, useGetConversations } from './useGetConversations';

export const useHandleBusinessFiltersChange = () => {
    const [, setConversationParamId] = useStateQueryParams(URLParams.CONVERSATION_ID);

    const [statusFilter] = useStateQueryParams<StatusFilterOption>(URLParams.STATUS);
    const [assignFilter] = useStateQueryParams<AssignFilterOption>(URLParams.ASSIGN);
    const businessFilters = useBusinessModalFilters();

    const { isLoading } = useGetConversations();
    const [hasRendered, setHasRendered] = useState<boolean>(false);

    /* Triggers `useConversationSetup` upon business filters changes.
       `hasRendered` allows us to not remove conversationId from the URL at the beginning. 
       If we do, it will impact `useConversationDeeplink`. 
    */
    useEffect(() => {
        if (!hasRendered) {
            setHasRendered(true);
        } else {
            const conversationsKey = getConversationsKey(
                statusFilter,
                assignFilter,
                businessFilters,
            );

            if (!isLoading) queryClient.removeQueries(conversationsKey);
            setConversationParamId('');
        }
    }, [JSON.stringify(businessFilters)]);
};
