import { useCallback } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { useAppDataContext } from 'app/common/contexts/appData';
import { ADMIN, PROVIDER } from 'app/common/data/roles';
import { useProductsAccess } from 'app/hooks/useProductsAccess';
import { MessagingPage } from 'app/pages/conversations/Messaging';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import {
    ANALYTICS_REVIEW_PATH,
    CONVERSATIONS_MESSAGING_PATH,
    MESSAGING_PATH_TOKEN,
    NOT_FOUND_PATH,
} from 'app/routing/routeIds';

export const ConversationsRoutes = () => {
    const { me } = useAppDataContext();
    const meRole = me ? me.role : null;
    const { hasBmAccess } = useProductsAccess();

    const getDefaultConversationsPath = useCallback(() => {
        if (!hasBmAccess) {
            return ANALYTICS_REVIEW_PATH;
        }

        return CONVERSATIONS_MESSAGING_PATH;
    }, []);

    const routes = [
        {
            index: true,
            element: <Navigate replace to={getDefaultConversationsPath()} />,
        },
        {
            path: MESSAGING_PATH_TOKEN,
            element: (
                <ProtectedRoute userRole={meRole} excludeRoles={[ADMIN, PROVIDER]}>
                    <MessagingPage hasBusinessMessage={hasBmAccess} />
                </ProtectedRoute>
            ),
        },
        {
            path: '*',
            element: <Navigate replace to={NOT_FOUND_PATH} />,
        },
    ];

    return useRoutes(routes);
};
