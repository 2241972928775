import { BusinessReachEnum } from 'app/api/types/business';
import useBusiness from 'app/reviewManagement/reviewList/hooks/useBusiness';

export default function (businessId: string): string | null {
    const business = useBusiness(businessId);
    if (!business) {
        return null;
    }

    if (business.reach === BusinessReachEnum.GLOBAL) {
        return business.name;
    }

    return [
        business.name,
        [business.addressFull, [business.zipcode, business.city].filter(Boolean).join(' ')]
            .filter(Boolean)
            .join(', '),
    ]
        .filter(Boolean)
        .join(' - ');
}
