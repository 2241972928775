import { Trans } from 'react-i18next';

import { useAverageRatingDistribution } from 'app/pages/analytics/ReviewAnalyticsV2/hooks/useMetrics';

const SEGMENTS_COLORS = ['#DDDFE3', '#FF426E', '#FF9014', '#88DDBD', '#2CB884'];

export const useBusinessDistributionSegments = () => {
    const metrics = useAverageRatingDistribution();

    if (!metrics) return [];

    const data = metrics.data[0]?.metrics.average_rating_distribution ?? [];

    const segments = Object.entries(data)
        .map(([minmax, count]) => {
            // Extract the segment max rating from data. It should be in the form "<min>-<max>" or NULL
            const segmentMax = minmax.split('-')[1];
            const segmentValue = segmentMax ? Number(segmentMax) : 0;
            return {
                label: segmentValue,
                value: count,
            };
        })
        // Order by ascending rating order
        .toSorted((s1, s2) => s1.label - s2.label)
        .map((s, idx) => ({
            // Apply color and tooltip
            ...s,
            label: s.label.toString(),
            color: SEGMENTS_COLORS[idx] ?? '#000',
            tooltip: {
                text: (
                    <Trans
                        i18nKey="review_analytics_businesses__count"
                        values={{ count: s.value }}
                        components={{ b: <span /> }}
                    />
                ),
            },
        }));

    return segments;
};
