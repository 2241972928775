import styled from '@emotion/styled';
import { ColorVariants } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

export const StyledTag = styled(Text)`
    height: 50px;
    border-radius: 8px;
    padding: 16px;
    background-color: rgba(215, 219, 223, 0.12);
    width: 100%;
    justify-content: center;
    display: flex;
`;

export const StyledIcon = styled.div<{ color: ColorVariants }>`
    padding: 8px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme, color }) => theme.colors[color].alpha};
`;

export const StyledChartIcon = styled.i`
    color: rgba(20, 37, 66, 0.12);
    font-size: 24px;
`;
