import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba } from '@partoohub/branding';

import { HEADER_SECTION_HEIGHT } from 'app/businessEditV2/components/BusinessEditV2Content/BusinessEditV2Content.styled';

export const AddressSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: ${HEADER_SECTION_HEIGHT}px;
    min-height: ${HEADER_SECTION_HEIGHT}px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.theme.initial};
    border-radius: 8px;
    box-shadow: 0 2px 10px ${convertToRgba(HEX_COLORS.black, 0.15)};
    overflow: hidden;
    position: relative;
`;

export const BottomPart = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`;
