import { css } from '@emotion/react';
import { Icon, IconPrefix, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { BUSINESS_MANAGER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { sumValues } from 'app/common/utils/sumValues';

import { StyledCard } from './Cards.styled';
import {
    StyledBMRank,
    StyledBar,
    StyledBars,
    StyledCardContent,
    StyledProgress,
    StyledReviewCount,
} from './EReputation.styled';
import { useEReputationInternalRanking, useReviewTotalMetrics } from '../../../hooks/useMetrics';
import { useReviewAnalyticsDefaultFilter } from '../../../hooks/useReviewAnalyticsDefaultFilter';

export const EReputationCard = () => {
    const { t } = useTranslation();
    const { data: me } = useMe();

    const { isDefaultDateFilter } = useReviewAnalyticsDefaultFilter();

    const metrics = useReviewTotalMetrics();
    const rankMetrics = useEReputationInternalRanking('business');
    const isSingleBusinessBM = me?.role === BUSINESS_MANAGER && rankMetrics?.data.length === 1;

    const reviewCount =
        metrics?.data
            .map(d => sumValues(d.metrics.rating_distribution))
            .reduce((prev, curr) => prev + curr, 0) ?? 0;

    const countByRating = Array(5)
        .fill(null)
        .map((_, idx) => {
            const score = `${idx + 1}` as '1' | '2' | '3' | '4' | '5';
            return (
                metrics?.data.reduce(
                    (total, d) => d.metrics.rating_distribution[score] + total,
                    0,
                ) ?? 0
            );
        });

    const averageRating = reviewCount
        ? countByRating.reduce((total, value, idx) => total + value * (idx + 1), 0) / reviewCount
        : 0;

    return (
        <StyledCard>
            <Text variant="bodyMBold">
                {t('review_analytics_graph_section_ereputation')}
                {isDefaultDateFilter && (
                    <Text as="span" color="secondary">
                        {' '}
                        ({t('date_criteria_last_twelve_months')})
                    </Text>
                )}
            </Text>

            <StyledCardContent direction="row">
                <Stack justifyContent="space-between">
                    <div>
                        <Text
                            as="div"
                            variant="heading1"
                            css={css`
                                flex-shrink: 0;
                                width: 3ch;
                            `}
                        >
                            {averageRating ? averageRating.toFixed(2) : '-'}
                        </Text>
                        <StyledReviewCount variant="bodyLRegular" color="secondary">
                            {t('review_count', { count: reviewCount })}
                        </StyledReviewCount>
                    </div>
                    {isSingleBusinessBM && !!rankMetrics.data[0]?.metrics.rank && (
                        <StyledBMRank variant="bodySBold">
                            <Icon icon={['fa-medal', IconPrefix.REGULAR]} />
                            {`#${rankMetrics.data[0]?.metrics.rank}`}
                        </StyledBMRank>
                    )}
                </Stack>
                <StyledBars gap="6px">
                    {countByRating.map((c, idx) => (
                        <StyledBar direction="row" gap="8px" key={idx}>
                            <Text
                                as="div"
                                variant="bodyMBold"
                                color="secondary"
                                css={css`
                                    flex-shrink: 0;
                                    width: 1ch;
                                    text-align: center;
                                `}
                            >
                                {idx + 1}
                            </Text>
                            <StyledProgress
                                value={reviewCount ? (100 * c) / reviewCount : 0}
                                variant="ratings"
                            />
                            <Text
                                as="div"
                                variant="bodySRegular"
                                color="secondary"
                                css={css`
                                    flex-shrink: 0;
                                    width: 3ch;
                                    text-align: end;
                                `}
                            >
                                {reviewCount ? ((100 * c) / reviewCount).toFixed(0) : 0}%
                            </Text>
                        </StyledBar>
                    ))}
                </StyledBars>
            </StyledCardContent>
        </StyledCard>
    );
};
