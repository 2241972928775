import env from 'app/common/services/getEnvironment';

export const COMPETITIVE_BENCHMARK = 'COMPETITIVE_BENCHMARK';
export const WA_DEDICATED_NUMBER_ONBOARDING = 'WA_DEDICATED_NUMBER_ONBOARDING';
export const CONVERSATION_ROUTING = 'CONVERSATION_ROUTING';
export const USE_NEW_SIDEBAR = 'USE_NEW_SIDEBAR';
export const BULK_RELOOKING = 'BULK_RELOOKING';
export const WIDGET_SETUP = 'WIDGET_SETUP';

export const useFeatureFlagsValues = () => {
    return {
        [USE_NEW_SIDEBAR]: true,
        [COMPETITIVE_BENCHMARK]: !env.isProd(),
        [WA_DEDICATED_NUMBER_ONBOARDING]: false,
        [CONVERSATION_ROUTING]: true,
        [BULK_RELOOKING]: env.isDev() || env.isStaging(),
        [WIDGET_SETUP]: !env.isProd(),
    };
};

export type FeatureFlags = ReturnType<typeof useFeatureFlagsValues>;
export type FeatureFlag = keyof FeatureFlags;

export const USE_USER_SIDEBAR_FEATURES = env.isDev() || env.isStaging();
