import { useController } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { UserSidebarFeatures } from './UserSidebarFeatures';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    selectedOrgId: number | undefined;
};

export const ControlledUserSidebarFeatures = ({
    name,
    control,
    selectedOrgId,
}: ControlledComponent) => {
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
    });

    return <UserSidebarFeatures value={value} onChange={onChange} selectedOrgId={selectedOrgId} />;
};
