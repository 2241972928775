import styled from '@emotion/styled';

export const UserSidebarFeaturesContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    gap: 24px;
`;

export const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const FeaturesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
`;
