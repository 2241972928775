import { Stack } from '@partoohub/ui';

import useHasReviewTagFeature from 'app/reviewManagement/common/hooks/useHasReviewTagFeature';

import { EReputationCard, ReviewReplyCard, TagsCard } from './Cards';

export const CardsSection = () => {
    const hasReviewTagFeature = useHasReviewTagFeature();

    return (
        <Stack direction="row">
            <EReputationCard />
            <ReviewReplyCard />
            {hasReviewTagFeature && <TagsCard />}
        </Stack>
    );
};
