import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

const FixedTopbarContext = createContext<
    { intersecting: boolean; setIntersecting: (value: boolean) => void } | undefined
>(undefined);

export const FixedTopbarProvider = ({ children }: PropsWithChildren) => {
    const [intersecting, setIntersecting] = useState<boolean>(true);

    const value = useMemo(() => ({ intersecting, setIntersecting }), [intersecting]);

    return <FixedTopbarContext.Provider value={value}>{children}</FixedTopbarContext.Provider>;
};

export const useFixedTopbar = () => {
    const context = useContext(FixedTopbarContext);
    if (!context) {
        throw new Error('useFixedTopbar must be used within an FixedTopbarProvider');
    }
    return context;
};
