import { useState } from 'react';

export const useHandleSort = (defaultColumnId: string, defaultOrder: 'asc' | 'desc') => {
    const [currentOrder, setCurrentOrder] = useState<{
        columnId: string;
        order: 'asc' | 'desc';
    }>({
        columnId: defaultColumnId,
        order: defaultOrder,
    });

    const handleSort = (columnId: string) => {
        setCurrentOrder(prevOrder => ({
            columnId,
            order: prevOrder.columnId === columnId && prevOrder.order === 'asc' ? 'desc' : 'asc',
        }));
    };

    return { currentOrder, handleSort };
};
