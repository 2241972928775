import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const PBoxContainer = styled.div`
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.theme.background};
    height: 100%;
    border-radius: 4px;
    border: 1px solid ${greyColorObject.initial};
`;
export const PBoxTitleContainer = styled.div`
    display: flex;
    align-items: baseline;
    gap: 8px;
`;

export const StyledPBoxBody = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
`;

export const StyledPBoxFooter = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-around;
    padding-bottom: 8px;
`;

export const PBoxActions = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 8px;

    @media (max-width: 1000px) {
        margin: 0 -16px;
        padding: 16px 16px 0 16px;
        border-top: 1px solid #e6e8ed;
    }

    > :not(:last-child) {
        @media (min-width: 1001px) {
            margin-right: 8px;
        }

        @media (max-width: 1000px) {
            margin-bottom: 8px;
        }
    }
`;

export const PBoxTitleWithoutActions = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;

    h3 {
        margin: 0;

        i {
            padding-right: 10px;
        }
    }
`;

export const PBoxTitleWithActions = styled.div`
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    padding: 16px;

    h3 {
        margin: 0;

        @media (max-width: 1000px) {
            margin-bottom: 16px;
        }

        i {
            padding-right: 10px;
        }
    }
`;
