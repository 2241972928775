import { Navigate, useRoutes } from 'react-router-dom';

import IS_IFRAME from 'app/common/data/iframe';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { Homepage } from 'app/pages/Homepage';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

export const HomeRoutes = () => {
    const { data: me } = useMe();
    const meRole = me ? me.role : null;

    const hasIndex = !IS_IFRAME;

    const routes = [
        ...(hasIndex
            ? [
                  {
                      index: true,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <Homepage />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
        {
            path: '*',
            element: <Navigate replace to={NOT_FOUND_PATH} />,
        },
    ];

    return useRoutes(routes);
};
