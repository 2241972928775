import React from 'react';

import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import ExpandableToggleBox, { Props } from './ExpandableToggleBox';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    rules: object;
};

const ControlledExpandableToggleBox = ({
    name,
    control,
    onClick = () => undefined,
    rules,
    ...props
}: ControlledComponent & Omit<Props, 'onClick' | 't'>) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value, ...rest } }) => (
            <ExpandableToggleBox
                onClick={e => {
                    onChange(e);
                    onClick(e);
                }}
                checked={value}
                {...rest}
                {...props}
            />
        )}
    />
);

export default ControlledExpandableToggleBox;
