import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack, Text } from '@partoohub/ui';

import { OverrideButton } from './FilterBusinessModalButton.styled';
import useSelectedBusinessesTitle from '../../hooks/useSelectedBusinessesTitle';

export const FilterBusinessModalButton = () => {
    const { title } = useSelectedBusinessesTitle();
    return (
        <OverrideButton
            dataTrackId="filter_displayed__button"
            appearance="outlined"
            shape="cube"
            size="large"
            full
        >
            <Stack gap="24px" direction="row" alignItems="center">
                <Text variant="bodyMSemibold" as="div" color="secondary">
                    {title}
                </Text>
                <Icon
                    icon={[FontAwesomeIconsPartooUsed.faChevronRight, IconPrefix.SOLID]}
                    color="secondary"
                    iconSize="12px"
                />
            </Stack>
        </OverrideButton>
    );
};
