import { IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DefaultShortcutComponent } from 'app/pages/Homepage/sections/HomepageShortcuts/DefaultShortcutComponent';
import { POSTS_CREATE_PATH } from 'app/routing/routeIds';

export const ShortcutCreatePost = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <DefaultShortcutComponent
            title={t('homepage_shortcut_create_post')}
            variant="slate"
            iconElement={['fa-newspaper', IconPrefix.SOLID]}
            onClick={() => navigate(POSTS_CREATE_PATH)}
            dataTrack="click_homepage_shortcut_posts"
        />
    );
};
