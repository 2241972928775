import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { MESSAGING_BUSINESS_COUNT } from 'app/common/data/queryKeysConstants';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useGetFirstBusinessPage = () => {
    const { data: me } = useMe();
    const { data, isLoading } = useQuery([MESSAGING_BUSINESS_COUNT, { org_id: me?.org_id }], () =>
        api.business.searchBusinesses({
            org_id: me?.org_id,
            page: 1,
            per_page: 1,
        }),
    );

    return { data, isLoading };
};
