import styled from '@emotion/styled';

export const FiltersContainerStyled = styled.div<{ isNewSidebarEnabled: boolean }>`
    display: flex;
    justify-content: space-between;
    gap: 8px;

    margin-bottom: ${({ isNewSidebarEnabled }) => (isNewSidebarEnabled ? '0px' : '16px')};
`;

export const FiltersStyled = styled.div`
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    gap: 8px;
    align-items: center;
`;

export const FiltersCounter = styled.div`
    white-space: nowrap;
    transform: translate(0px, 13px);
    font-weight: bold;
`;
