import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Modal, NumberInput, TextArea, TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { FoodMenuItemMediaType, FoodMenuItemType } from 'app/api/types/food_menu';
import usePhotosUpload from 'app/businessEditV2/hooks/usePhotosUpload';
import DeleteMenuPhoto from 'app/businessEditV2/sections/MenuSection/components/DeleteMenuPhoto';
import {
    AddItemDescription,
    AddItemInputLineWrapper,
    AddItemInputWrapper,
    AddItemName,
    AddItemPrice,
    CancelButton,
    ItemPhotoWrapper,
    MenuPhotosDeleteClickable,
    ModalContent,
    ModalSubtitle,
    ModalTitle,
    ModalWrapper,
} from 'app/businessEditV2/sections/MenuSection/components/modals/modal.styled';
import { errorMaxCharacters } from 'app/businessEditV2/utils/utils';
import AddImageIcon from 'app/common/components/icons/AddImageIcon';
import StrikeTroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';

import { DropzoneWithExplanation } from 'app/common/designSystem/components/molecules/DropZone/DropZone';

export type FoodMenuItemFormType = {
    name: string;
    order: number;
    description: string;
    price?: number;
    media?: FoodMenuItemMediaType;
};

type Props = {
    show: boolean;
    handleClose: () => void;
    handleConfirm: (item: FoodMenuItemType) => void;
    initialData: FoodMenuItemType | null;
    currency: string;
    type?: string;
};

const defaultFormData = {
    name: '',
    description: '',
    price: undefined,
    media: undefined,
    order: 0,
};

const itemToFormData = (item: FoodMenuItemType): FoodMenuItemFormType => ({
    ...defaultFormData,
    ...item,
    ...{ price: item.price ?? undefined },
});

const AddItemModal = ({ currency, handleClose, handleConfirm, initialData, show, type }: Props) => {
    const [formData, setFormData] = useState<FoodMenuItemFormType>({
        ...defaultFormData,
    });
    const { t } = useTranslation();

    useEffect(() => {
        if (show) {
            setFormData(initialData ? itemToFormData({ ...initialData }) : { ...defaultFormData });
        }
    }, [show]);

    const onConfirm = useCallback(() => {
        const item = {
            ...formData,
            name: formData.name,
            price: formData?.price,
        };
        handleConfirm(item);
        setFormData({
            name: '',
            description: '',
            price: undefined,
            media: undefined,
            order: 0,
        });
        handleClose();
    }, [formData, handleConfirm]);

    const isFormValid: boolean = useMemo<boolean>(
        () =>
            !!(
                formData.name &&
                formData.name.replace(/\s/g, '').length > 0 &&
                formData.name.length <= 140 &&
                formData.description?.length <= 1000 &&
                (formData?.price === undefined || !isNaN(formData.price))
            ),
        [formData],
    );

    const uploadPhotos = usePhotosUpload(data => {
        setFormData({
            ...formData,
            media: {
                source_url: data.result.uploads[0],
                public_url: undefined,
            },
        });
    });

    const uploadPhoto = files => {
        uploadPhotos.mutate(files);
    };

    const deletePhoto = () => {
        setFormData({
            ...formData,
            media: undefined,
        });
    };

    const handlePriceChange = (value: string) => {
        // This conversion is necessary because design system is typing value as a number
        // But the form is returning a string
        // When price is deleted, an empty string is set as a value
        const stringValue = value.toString();
        setFormData({
            ...formData,
            price: stringValue.length > 0 ? parseInt(stringValue) : undefined,
        });
    };

    return (
        <Modal isOpen={show} closeModal={handleClose}>
            <ModalWrapper>
                <ModalContent>
                    <ModalTitle>
                        {type === 'add'
                            ? t('add_food_menu_item_title')
                            : t('edit_food_menu_item_title')}
                    </ModalTitle>
                    <ModalSubtitle>
                        {type === 'add'
                            ? t('add_food_menu_item_subtitle')
                            : t('edit_food_menu_item_subtitle')}
                    </ModalSubtitle>
                    <AddItemInputWrapper>
                        <AddItemInputLineWrapper>
                            <AddItemName>
                                <TextInput
                                    dataTrackId="food_menu_item_name"
                                    label={t('food_menu_item_name')}
                                    characterCount={140}
                                    required
                                    value={formData.name}
                                    onChange={value => {
                                        setFormData({
                                            ...formData,
                                            name: value || '',
                                        });
                                    }}
                                />
                            </AddItemName>

                            <AddItemPrice>
                                <NumberInput
                                    dataTrackId="food_menu_item_price"
                                    label={`${t('food_menu_item_price')} (${currency})`}
                                    unit={currency}
                                    value={formData?.price}
                                    onChange={handlePriceChange}
                                    min={0}
                                />
                            </AddItemPrice>
                        </AddItemInputLineWrapper>
                        <AddItemInputLineWrapper>
                            <AddItemDescription>
                                <TextArea
                                    characterCount={1000}
                                    rows={6}
                                    maxRows={10}
                                    value={formData.description}
                                    onChange={value => {
                                        setFormData({
                                            ...formData,
                                            description: value,
                                        });
                                    }}
                                    error={errorMaxCharacters(formData.description, 1000)}
                                    label={t('food_menu_item_description')}
                                    dataTrackId={'add-menu-item-description'}
                                />
                            </AddItemDescription>
                            {formData.media?.source_url ? (
                                <ItemPhotoWrapper>
                                    <MenuPhotosDeleteClickable
                                        photoUrl={formData.media?.source_url}
                                    >
                                        <DeleteMenuPhoto onClick={deletePhoto} height="160px" />
                                    </MenuPhotosDeleteClickable>
                                </ItemPhotoWrapper>
                            ) : (
                                <DropzoneWithExplanation
                                    files={[]}
                                    handleDropSuccess={uploadPhoto}
                                    label={t('menu_photo_dropzone_label')}
                                    uploadIcon={<AddImageIcon />}
                                    acceptedFiles={[
                                        {
                                            memeType: 'image/jpeg',
                                            extension: 'JPEG',
                                        },
                                        {
                                            memeType: 'image/png',
                                            extension: 'PNG',
                                        },
                                    ]}
                                    maxSize={1_000_000_000} // 1Go
                                    isCompact
                                    inFoodMenuModal
                                    isLoading={uploadPhotos.isLoading}
                                />
                            )}
                        </AddItemInputLineWrapper>
                    </AddItemInputWrapper>
                </ModalContent>

                <CancelButton>
                    <StrikeTroughButton
                        text={t('cancel')}
                        color="secondary"
                        onClick={handleClose}
                    />
                </CancelButton>
                <Button
                    dataTrackId="add_menu_item"
                    size="large"
                    full
                    variant="primary"
                    shape="cube"
                    disabled={!isFormValid}
                    onClick={onConfirm}
                >
                    {t('validate')}
                </Button>
            </ModalWrapper>
        </Modal>
    );
};

export default AddItemModal;
