import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import LoadingView from 'app/common/components/LoadingView';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import {
    CEPages,
    useCanAccessCustomerExperiencePage,
} from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import { ReviewList } from 'app/reviewManagement/reviewList/ReviewList';
import { ReviewListWithNewSidebar } from 'app/reviewManagement/reviewList/ReviewListWithNewSidebar';

import { NOT_FOUND_PATH } from 'app/routing/routeIds';

export const ReviewListPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);

    const {
        canAccessCustomerExperiencePage: { [CEPages.ClientReviews]: hasAccess },
        isLoading: accessLoading,
    } = useCanAccessCustomerExperiencePage();

    if (accessLoading) {
        return <LoadingView />;
    }

    const ReviewListComponent = isNewSidebarEnabled ? ReviewListWithNewSidebar : ReviewList;

    return !location.search ? null : (
        <>
            <DynamicPageHeader title={t('reviews')} />
            {hasAccess ? <ReviewListComponent /> : <Navigate replace to={NOT_FOUND_PATH} />}
        </>
    );
};
