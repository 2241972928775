import styled from '@emotion/styled';

export const BusinessStatusDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    line-height: 17px;
    margin-right: 48px;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.default.initial};
    font-weight: bold;
`;

export const Description = styled.div`
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const WarningMessage = styled.div`
    margin-top: 16px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.warning.initial};
`;
