import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import { GetMaxWidthContentPage } from 'app/global.styled';

export const EditFormContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 40px 40px 0 40px;
    overflow: auto;
`;

export const EditFormBox = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    ${GetMaxWidthContentPage}
`;

type EditFormHeaderProps = {
    colorState: ColorState;
};

export const EditFormHeader = styled.div<EditFormHeaderProps>`
    display: flex;
    margin-bottom: 40px;
    align-items: center;

    > i,
    svg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        min-width: 56px;
        border-radius: 56px;
        overflow: hidden;
        margin-right: 16px;
        font-size: 26px;
        color: ${({ theme }) => theme.colors.success.initial};
        background-color: ${({ theme }) => theme.colors.success.alpha};

        ${({ colorState, theme }) =>
            [ColorState.PRECHECK_ERRORS, ColorState.MISSING_WARNING].includes(colorState) &&
            css`
                color: ${theme.colors.warning.initial};
                background-color: ${theme.colors.warning.alpha};
            `};

        ${({ colorState, theme }) =>
            [ColorState.DISABLED, ColorState.CLOSED].includes(colorState) &&
            css`
                color: ${theme.colors.secondary.alpha};
                background-color: ${theme.colors.secondary.alpha};
            `};
    }
`;

export const EditFormInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    padding: 12px 16px;
    margin-bottom: 12px;
    min-height: 42px;
    background: rgba(215, 219, 223, 0.12);
    border-radius: 4px;
    color: #7f8ea4;
    font-size: 14px;
    line-height: 18px;

    > i {
        margin-right: 8px;
    }
`;

export const EditFormBusiness = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const EditFormBusinessName = styled.div`
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    color: ${({ theme }) => theme.colors.default.initial};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const EditFormBusinessAddress = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const EditFormBusinessReachContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const EditFormBusinessReach = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 24px;
    padding: 4px 8px;
    border: solid 1px ${({ theme }) => theme.colors.default.alpha};
    border-radius: 4px;
    width: fit-content;

    > * {
        text-transform: uppercase !important;
    }
`;

export const EditFormDescription = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.default.initial};
`;

export const EditFormDescriptionLeft = styled.div`
    display: flex;
    align-items: baseline;
    font-size: 18px;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.default.initial};

    > i {
        color: ${({ theme }) => theme.colors.warning.initial};
        margin-right: 8px;
    }
`;

export const EditFormDescriptionRight = styled.div`
    font-size: 14px;
    line-height: 17px;
`;

export const EditFormFooter = styled.div`
    position: sticky;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.theme.background};
    border-top: 1px solid ${greyColorObject.initial};

    // "auto" is to make sure footer is always at bottom even if the contents of the flex container doesn't take up 100% height
    margin-top: auto;
    padding: 16px;
`;
