import { useState } from 'react';

import { IconPrefix, LeftElementType, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { FiltersContainerStyled } from 'app/common/components/FilterSection.styled';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import useDebounce from 'app/common/hooks/useDebounce';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import getLang from 'app/common/services/getLang';

import { StyledCount, StyledSearchbar } from './ReplyTemplateFilters.styled';
import ReplyTemplatesFiltersButton from './ReplyTemplatesFiltersButton';

type Props = { templateCount: number };

export function ReplyTemplateFilters({ templateCount }: Props) {
    const { t } = useTranslation();

    const [searchQueryParam, setSearchQueryParam] = useStateQueryParams('search');
    const [currentSearch, setCurrentSearch] = useState(searchQueryParam);

    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);

    useDebounce(currentSearch, 200, () => {
        if (currentSearch != searchQueryParam) {
            setSearchQueryParam(currentSearch);
        }
    });

    const handleSearchReset = () => {
        setCurrentSearch('');
        setSearchQueryParam('');
    };

    return (
        <div>
            <FiltersContainerStyled isNewSidebarEnabled={isNewSidebarEnabled}>
                <StyledSearchbar>
                    <TextInput
                        dataTrackId="reply_template__search_field"
                        value={currentSearch}
                        label={t('auto_reply_template_select_search_placeholder')}
                        onChange={setCurrentSearch}
                        isReset
                        onReset={handleSearchReset}
                        leftElement={['fa-search', IconPrefix.REGULAR]}
                        leftElementType={LeftElementType.Icon}
                    />
                </StyledSearchbar>
                <ReplyTemplatesFiltersButton />
            </FiltersContainerStyled>
            <StyledCount variant="heading6">
                {`${templateCount?.toLocaleString(getLang())} ${t(
                    'header_counter_reply_templates',
                    {
                        count: templateCount,
                    },
                )}
                `}
            </StyledCount>
        </div>
    );
}
