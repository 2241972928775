import styled from '@emotion/styled';
import { Button, IconButton } from '@partoohub/ui';

export const WidgetDomainButton = styled(Button)`
    width: 100%;
`;

export const WidgetDomainTrashIcon = styled(IconButton)`
    cursor: pointer;
`;
