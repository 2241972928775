import { useEffect, useMemo } from 'react';

import { IconPrefix, LeftElementType, Stack, toast } from '@partoohub/ui';

import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from 'react-query';

import { V2BusinessData } from 'app/api/types/business';
import { FormattedUserData, V2UserData } from 'app/api/types/user';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import { ControlledTextInput } from 'app/businessEditV2/hookControllerWrapper/ControlledTextInput';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { USE_USER_SIDEBAR_FEATURES } from 'app/common/data/featureFlags';
import { USERS } from 'app/common/data/queryKeysConstants';
import { BUSINESS_MANAGER, GROUP_MANAGER } from 'app/common/data/roles';
import { formatRoleToChoice } from 'app/common/hooks/queries/useAvailableRoles';
import useGroup from 'app/common/hooks/queries/useGroup';
import { formatGroupToChoice } from 'app/common/hooks/queries/useGroups';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import useOrganization from 'app/common/hooks/queries/useOrganization';
import { formatOrgToChoice } from 'app/common/hooks/queries/useOrgs';
import useSsoInfo from 'app/common/hooks/queries/useSsoInfo';
import useUser, { formatLanguageToChoice } from 'app/common/hooks/queries/useUser';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    CEOrgFeatures,
    useHasCEOrgFeatures,
} from 'app/pages/customerExperience/settings/useHasCEOrgFeatures';
import groupsTracking from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/groupsTracking';
import useCreateUser from 'app/pages/settingsV2/subPages/Team/components/UserManagement/hooks/useCreateUser';
import useEditUser from 'app/pages/settingsV2/subPages/Team/components/UserManagement/hooks/useEditUser';
import useSidebarPages from 'app/pages/settingsV2/subPages/Team/components/UserManagement/hooks/useSidebarPages';
import { useUserAllBusinesses } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/hooks/useUserBusinesses';
import ControlledAccessesSelect from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm/hookControllerWrapper/ControlledAccessesSelect';
import PasswordEditionSubform from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm/PasswordSubform/PasswordEditionSubform';
import AuthorizationService from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/authorization';
import {
    Products,
    UserListQueryKeys,
} from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

import EditForm from './EditForm';
import { EditDescription, EditFormFooter } from './EditForm/EditForm.styled';
import ControlledBusinessSelect from './hookControllerWrapper/ControlledBusinessSelect';
import ControlledGroupSelect from './hookControllerWrapper/ControlledGroupSelect';
import ControlledLanguageSelect from './hookControllerWrapper/ControlledLanguageSelect';
import ControlledOrgSelect from './hookControllerWrapper/ControlledOrgSelect';
import ControlledProductsToggles from './hookControllerWrapper/ControlledProductsToggles';
import ControlledRoleSelect from './hookControllerWrapper/ControlledRoleSelect';
import { ControlledUserSidebarFeatures } from './hookControllerWrapper/ControlledUserSidebarFeatures';
import PasswordCreationSubform from './PasswordSubform/PasswordCreationSubform';
import { UserEditGridContainer } from './UserEditForm.styled';

type Props = {
    currentUser: V2UserData;
};

const UserEditForm = ({ currentUser }: Props) => {
    const authorizationService = new AuthorizationService(currentUser);
    const [userId, setUserId] = useStateQueryParams(UserListQueryKeys.USER_EDIT);
    const isCreation = userId === 'create';
    const queryClient = useQueryClient();
    const { data: user } = useUser(userId, {
        enabled: !isCreation && !!userId,
    });

    if (user && !authorizationService.canUpdateUser(user)) {
        // Close modal
        setUserId('');
    }

    const userOrgId = isCreation ? currentUser?.org_id : user?.org_id;
    const { data: userOrg } = useOrganization(userOrgId ?? 0, !!userOrgId);
    const { data: userGroup } = useGroup(user?.group_id ?? 0, !isCreation && !!user?.group_id);
    const [, initialUserBusinesses] = useUserAllBusinesses(user?.id ?? '', {
        enabled: !isCreation && !!user && user.role === BUSINESS_MANAGER,
    });
    const { t } = useTranslation();
    const displayOrgSelect = authorizationService.isProvider() || authorizationService.isAdmin();

    const setFormValue = () => ({
        firstName: user?.first_name ?? '',
        lastName: user?.last_name ?? '',
        email: user?.email ?? '',
        org: userOrg ? formatOrgToChoice(userOrg) : null,
        language: user?.lang ? formatLanguageToChoice(user?.lang, t) : null, // default FR ?
        role: user?.role ? formatRoleToChoice(user?.role, t) : null, // default BM ?
        group: userGroup ? formatGroupToChoice(userGroup) : undefined,
        accesses: user?.accesses ?? [],
        businesses: initialUserBusinesses ?? [],
        [Products.PRESENCE_MANAGEMENT]: !!user?.sidebar_products.includes(
            Products.PRESENCE_MANAGEMENT,
        ),
        [Products.REVIEW_MANAGEMENT]: !!user?.sidebar_products.includes(Products.REVIEW_MANAGEMENT),
        [Products.REVIEW_BOOSTER]: !!user?.sidebar_products.includes(Products.REVIEW_BOOSTER),
        [Products.MESSAGES]: !!user?.sidebar_products.includes(Products.MESSAGES),
        feedback_management: !!user?.sidebar_products.includes('feedback_management'),
        ssoOnly: user?.sso_only ?? false,
        password: '',
        sendInvitation: true,
        updatePassword: false,
        sidebar_pages: isCreation
            ? [] // Sidebar pages will be updated when org is selected
            : (user?.sidebar_pages ?? []),
    });

    const { control, handleSubmit, watch, reset, setValue } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    const selectedOrgId = Number(watch('org')?.value) || undefined;

    const enableNewGroups = useNewGroups(selectedOrgId);

    const { data: sidebarPages } = useSidebarPages(selectedOrgId ?? 0, {
        enabled: !!selectedOrgId,
    });
    const availableSidebarPages =
        sidebarPages?.sidebar_pages?.map(sidebarPage => sidebarPage.name) ?? [];

    const displayGroupSelect = enableNewGroups
        ? watch('role')?.value === GROUP_MANAGER
        : [BUSINESS_MANAGER, GROUP_MANAGER].includes(watch('role')?.value ?? '');

    const displayBusinessSelect = watch('role')?.value === BUSINESS_MANAGER;
    const displayProductToggle =
        watch('role')?.value === BUSINESS_MANAGER &&
        (authorizationService.isProvider() ||
            authorizationService.isAdmin() ||
            authorizationService.isOrgAdmin() ||
            authorizationService.isGroupManager());

    const displayUserSidebarFeatures =
        USE_USER_SIDEBAR_FEATURES &&
        ((watch('role')?.value === BUSINESS_MANAGER &&
            (authorizationService.isProvider() ||
                authorizationService.isAdmin() ||
                authorizationService.isOrgAdmin() ||
                authorizationService.isGroupManager())) ||
            (watch('role')?.value === GROUP_MANAGER &&
                (authorizationService.isProvider() ||
                    authorizationService.isAdmin() ||
                    authorizationService.isOrgAdmin())));

    const { data: ssoInfo } = useSsoInfo(Number(watch('org')?.value ?? 0), {
        enabled:
            !!watch('org')?.value &&
            (authorizationService.isProvider() ||
                authorizationService.isAdmin() ||
                authorizationService.isOrgAdmin()),
    });
    const displaySsoToggle =
        (authorizationService.isProvider() ||
            authorizationService.isAdmin() ||
            authorizationService.isOrgAdmin()) &&
        !!ssoInfo?.sso_type;

    const {
        orgFeatures: { [CEOrgFeatures.FeedbackManagement]: displayFBMtoggle },
    } = useHasCEOrgFeatures();

    // Reset form when new data is fetched
    useEffect(() => {
        reset(setFormValue());
    }, [user, userOrg, userGroup, initialUserBusinesses, isCreation]);

    // Sidebar pages can change depending on the org
    useEffect(() => {
        const newSidebarPages = isCreation ? availableSidebarPages : (user?.sidebar_pages ?? []);
        setValue('sidebar_pages', newSidebarPages);
    }, [sidebarPages]);

    const updateUser = useEditUser(userId, () => {
        if (enableNewGroups && watch('role')?.value === GROUP_MANAGER) {
            const accesses = watch('accesses');
            groupsTracking.saveEditUserPage(accesses?.flat().length ?? 0, accesses?.length ?? 0);
        }
        // Close modal
        setUserId('');
        queryClient.resetQueries(invalidatesAny(USERS));
        toast.success(null, t('toast__user_edited__success'));
    });
    const createUser = useCreateUser({
        onSuccess: () => {
            if (enableNewGroups && watch('role')?.value === GROUP_MANAGER) {
                const accesses = watch('accesses');
                groupsTracking.saveCreateUserPage(
                    accesses?.flat().length ?? 0,
                    accesses?.length ?? 0,
                );
            }
            // Close modal
            setUserId('');
            queryClient.invalidateQueries(invalidatesAny(USERS));
            toast.success(null, t('toast__user_created__success'));
        },
    });

    const errors =
        updateUser.error?.response?.data?.errors?.json ||
        createUser.error?.response?.data?.errors?.json;

    const onSubmit = (payload: FormattedUserData) => {
        if (isCreation) {
            createUser.mutate(payload);
        } else {
            updateUser.mutate(payload);
        }
    };

    const formatCommonPayload = (payload: FieldValues): FormattedUserData => {
        const hasPMtoggle = !!payload.businesses.find(
            (business: V2BusinessData) => business.subscriptions.presence_management.active,
        );
        const hasRMtoggle = !!payload.businesses.find(
            (business: V2BusinessData) => business.subscriptions.review_management.active,
        );
        const hasRBtoggle = !!payload.businesses.find(
            (business: V2BusinessData) => business.subscriptions.review_booster.active,
        );
        const hasMessagestoggle = !!payload.businesses.find(
            (business: V2BusinessData) => business.subscriptions.messages.active,
        );

        return {
            first_name: payload.firstName,
            last_name: payload.lastName,
            email: payload.email,
            business_ids:
                payload.role.value === BUSINESS_MANAGER
                    ? payload.businesses.map((business: V2BusinessData) => business.id)
                    : [],
            ...(displayOrgSelect ? { org_id: payload.org.value } : {}),
            role: payload.role.value,
            ...(payload.language ? { lang: payload.language.value } : {}),
            group_id:
                !enableNewGroups && payload.role?.value === GROUP_MANAGER
                    ? payload.group.value
                    : null,
            accesses:
                enableNewGroups && payload.role?.value === GROUP_MANAGER
                    ? payload.accesses.filter((access: Array<number>) => access.length)
                    : null,
            sidebar_products:
                payload.role?.value === BUSINESS_MANAGER
                    ? ([
                          payload[Products.PRESENCE_MANAGEMENT] && hasPMtoggle
                              ? Products.PRESENCE_MANAGEMENT
                              : null,
                          payload[Products.REVIEW_MANAGEMENT] && hasRMtoggle
                              ? Products.REVIEW_MANAGEMENT
                              : null,
                          payload[Products.REVIEW_BOOSTER] && hasRBtoggle
                              ? Products.REVIEW_BOOSTER
                              : null,
                          payload[Products.MESSAGES] && hasMessagestoggle
                              ? Products.MESSAGES
                              : null,
                          payload['feedback_management'] && displayFBMtoggle
                              ? 'feedback_management'
                              : null,
                      ].filter(Boolean) as string[])
                    : [
                          Products.PRESENCE_MANAGEMENT,
                          Products.REVIEW_MANAGEMENT,
                          Products.REVIEW_BOOSTER,
                          Products.MESSAGES,
                          'feedback_management',
                      ],
            ...(USE_USER_SIDEBAR_FEATURES
                ? {
                      sidebar_pages: displayUserSidebarFeatures
                          ? payload.sidebar_pages
                          : availableSidebarPages,
                  }
                : {}),
        };
    };

    const formatCreationPayload = (payload: FieldValues): Partial<FormattedUserData> => {
        return {
            send_invitation: !payload.ssoOnly && payload.sendInvitation,
            ...(payload.ssoOnly || payload.sendInvitation
                ? {}
                : { password: payload.password ?? '' }),
            ...(displaySsoToggle ? { sso_only: payload.ssoOnly } : {}),
        };
    };

    const formatEditionPayload = (payload: FieldValues): Partial<FormattedUserData> => {
        return {
            ...(displaySsoToggle ? { sso_only: payload.ssoOnly } : {}),
            ...((!displaySsoToggle || !payload.ssoOnly) &&
            payload.updatePassword &&
            payload.password
                ? { password: payload.password }
                : {}),
        };
    };

    const formatPayload = (payload: FieldValues): FormattedUserData => {
        return {
            ...formatCommonPayload(payload),
            ...(isCreation ? formatCreationPayload(payload) : formatEditionPayload(payload)),
        };
    };

    const submitForm = () => {
        handleSubmit(payload => onSubmit(formatPayload(payload)))();
    };

    const disabled = [
        !watch('firstName'),
        !watch('lastName'),
        !watch('email'),
        !watch('role'),
        displayOrgSelect && !watch('org'),
        !enableNewGroups && watch('role')?.value === GROUP_MANAGER && !watch('group'),
        enableNewGroups &&
            watch('role')?.value === GROUP_MANAGER &&
            !watch('accesses')?.some(access => access.length),
        // Handle password creation
        isCreation && !(watch('ssoOnly') || watch('sendInvitation')) && !watch('password'),
        // Handle password edition
        !isCreation && !watch('ssoOnly') && watch('updatePassword') && !watch('password'),
        authorizationService.isGroupManager() &&
            watch('role')?.value === BUSINESS_MANAGER &&
            !watch('businesses')?.length,
    ].some(Boolean);

    return (
        <>
            <EditForm
                title={isCreation ? t('add_user') : `${watch('firstName')} ${watch('lastName')}`}
                icon={
                    isCreation ? (
                        <i className="fa-regular fa-user-plus" />
                    ) : (
                        <i className="fa-regular fa-user-pen" />
                    )
                }
                description={t('user_info_title')}
            >
                <UserEditGridContainer>
                    <ControlledTextInput
                        dataTrackId="firstName"
                        name="firstName"
                        control={control}
                        label={t('first_name')}
                        leftElement={['fa-circle-user', IconPrefix.SOLID]}
                        leftElementType={LeftElementType.Icon}
                        errorMessage={errors?.first_name ? t(errors?.first_name) : ''}
                        hasError={!!errors?.first_name}
                        required
                        hasOldTextFieldHeight
                    />
                    <ControlledTextInput
                        dataTrackId="lastName"
                        name="lastName"
                        control={control}
                        label={t('last_name')}
                        leftElement={['fa-id-card-clip', IconPrefix.SOLID]}
                        leftElementType={LeftElementType.Icon}
                        errorMessage={errors?.last_name ? t(errors?.last_name) : ''}
                        hasError={!!errors?.last_name}
                        required
                        hasOldTextFieldHeight
                    />
                    <ControlledTextInput
                        dataTrackId="email"
                        name="email"
                        control={control}
                        label={t('email')}
                        type="email"
                        leftElement={['fa-at', IconPrefix.SOLID]}
                        leftElementType={LeftElementType.Icon}
                        errorMessage={errors?.email ? t(errors?.email) : ''}
                        hasError={!!errors?.email}
                        required
                        hasOldTextFieldHeight
                    />
                    <ControlledLanguageSelect name="language" control={control} />
                </UserEditGridContainer>

                <Stack>
                    <ControlledRoleSelect name="role" control={control} />

                    {displayOrgSelect && (
                        <ControlledOrgSelect name="org" control={control} setValue={setValue} />
                    )}

                    {(displayGroupSelect || displayBusinessSelect) && (
                        <EditDescription>{t('user_locations_title')}</EditDescription>
                    )}

                    {displayGroupSelect && (
                        <>
                            {enableNewGroups ? (
                                <ControlledAccessesSelect
                                    name="accesses"
                                    control={control}
                                    orgId={selectedOrgId}
                                    mandatory
                                />
                            ) : (
                                <ControlledGroupSelect
                                    name="group"
                                    control={control}
                                    watch={watch}
                                    explanation={errors?.group_id ? t('required_field') : ''}
                                    hasError={!!errors?.group_id}
                                />
                            )}
                        </>
                    )}
                    {displayBusinessSelect && (
                        <ControlledBusinessSelect
                            name="businesses"
                            control={control}
                            watch={watch}
                            explanation={t(errors?.managed_business)}
                            hasError={!!errors?.managed_business}
                            isMandatory={
                                authorizationService.isGroupManager() &&
                                watch('role')?.value === BUSINESS_MANAGER
                            }
                        />
                    )}

                    {displayProductToggle && (
                        <>
                            <EditDescription>{t('user_products_title')}</EditDescription>
                            <ControlledProductsToggles
                                control={control}
                                watch={watch}
                                explanation={t(errors?.sidebar_products)}
                                hasError={!!errors?.sidebar_products}
                            />
                        </>
                    )}

                    {displayUserSidebarFeatures && (
                        <>
                            <EditDescription>
                                ~ [Features] {t('user_products_title')}
                            </EditDescription>
                            <ControlledUserSidebarFeatures
                                control={control}
                                name="sidebar_pages"
                                selectedOrgId={selectedOrgId}
                            />
                        </>
                    )}

                    {isCreation ? (
                        <PasswordCreationSubform
                            control={control}
                            watch={watch}
                            authorizationService={authorizationService}
                            errorMessage={t(errors?.password || errors?.password_rules)}
                            hasError={!!(errors?.password || errors?.password_rules)}
                            displaySsoToggle={displaySsoToggle}
                        />
                    ) : (
                        <PasswordEditionSubform
                            control={control}
                            watch={watch}
                            authorizationService={authorizationService}
                            errorMessage={t(errors?.password || errors?.password_rules)}
                            hasError={!!(errors?.password || errors?.password_rules)}
                            displaySsoToggle={displaySsoToggle}
                        />
                    )}
                </Stack>
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={updateUser.isLoading || createUser.isLoading}
                    hasError={!!(updateUser.error || createUser.error)}
                    disabled={disabled}
                    onClick={submitForm}
                    dataTrackId="edit_user__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default UserEditForm;
