import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import { SectionNames } from 'app/businessEditV2/utils/utils';

import { OpensSoonBanner } from './BusinessOpeningSoon.styled';

const BusinessOpeningSoon = () => {
    const { t } = useTranslation();
    const { setShowModal } = useUnsavedModal(SectionNames.ADVANCED_SETTINGS);

    return (
        <OpensSoonBanner>
            <Banner
                key="info"
                actionButtonText={t('modify')}
                withCloseIcon={false}
                variant="info"
                dataTrackId={'opens-soon-business-edit'}
                onAction={() => setShowModal(true)}
            >
                {t('business_edit_business_opens_soon')}
            </Banner>
        </OpensSoonBanner>
    );
};

export default BusinessOpeningSoon;
