import dayjs from 'dayjs';

import {
    AssignFilterOption,
    Conversation,
    LOCAL_STORAGE_CONVERSATION_FILTERS,
    StatusFilterOption,
} from 'app/states/messaging';

export const getNextActiveConversation = (
    conversations: Array<Conversation>,
    activeConversation: Conversation | undefined,
    removedConversationId: number,
): Conversation | undefined => {
    const conversationIndex = conversations.findIndex(
        conversation => conversation.id === removedConversationId,
    );
    if (conversationIndex !== -1 && conversations.length > 1 && activeConversation) {
        if (activeConversation.id !== removedConversationId) {
            return activeConversation;
        }
        if (conversationIndex === conversations.length - 1) {
            return conversations[conversationIndex - 1];
        }
        return conversations[conversationIndex + 1];
    }
    return undefined;
};

export const conversationsSort = (prev: Conversation, next: Conversation) => {
    if (prev.last_message?.date && next.last_message?.date) {
        return dayjs(prev.last_message.date).isAfter(dayjs(next.last_message.date)) ? -1 : 1;
    }
    /**
     * Conversations with no messages (case can happen in dev) will be put at the end
     */
    return Infinity;
};

export const setConversationFiltersLocalStorage = (statusFilter: string, assignFilter: string) => {
    const params = {
        statusFilter: statusFilter,
        assignFilter: assignFilter,
    };
    localStorage.setItem(LOCAL_STORAGE_CONVERSATION_FILTERS, JSON.stringify(params));
};

export const isConversationInRightAssignFilter = (
    conversation: Conversation,
    assignFilter: AssignFilterOption,
    meId: string | undefined,
): boolean => {
    const assignedUserId = conversation.assigned_user_id;

    return (
        !assignFilter ||
        assignFilter === AssignFilterOption.ALL ||
        (assignFilter === AssignFilterOption.ME && assignedUserId === meId) ||
        (assignFilter === AssignFilterOption.UNASSIGNED && assignedUserId === null)
    );
};

export const isConversationInRightStatusFilter = (
    conversation: Conversation,
    statusFilter: StatusFilterOption,
) => {
    const isConversationClosed = conversation.is_solved || conversation.is_expired;

    return (
        (!isConversationClosed && statusFilter === StatusFilterOption.OPEN) ||
        (isConversationClosed && statusFilter === StatusFilterOption.CLOSED)
    );
};
