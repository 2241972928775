import { BusinessCreateContentContainer } from './BusinessCreateWithNewSidebar.styled';
import BusinessCreateContent from './sections/BusinessCreateContent';
import { BusinessCreatePageHeader } from './sections/BusinessCreateHeader/BusinessCreatePageHeader';

export const BusinessCreateWithNewSidebar = () => {
    return (
        <>
            <BusinessCreatePageHeader />
            <BusinessCreateContentContainer>
                <BusinessCreateContent />
            </BusinessCreateContentContainer>
        </>
    );
};
