import { useEffect } from 'react';

import useLocalStorage from 'app/common/hooks/useLocalStorage';

import { useThemeDetector } from './useThemeDetector';

export const PARTOO_THEME_KEY = 'partoo_theme';

export const usePartooTheme = (): [string, (theme: string) => void] => {
    const isDarkBrowserTheme = useThemeDetector();

    const [partooTheme, setPartooTheme] = useLocalStorage<string>(PARTOO_THEME_KEY, 'light', {
        maxAge: 3 * 24 * 3600, // 3 days
    });

    useEffect(() => {
        if (partooTheme === 'dark' || partooTheme === 'light') return;

        if (isDarkBrowserTheme) {
            setPartooTheme('dark');
        } else {
            setPartooTheme('light');
        }
    }, [isDarkBrowserTheme, partooTheme, setPartooTheme]);

    return [partooTheme, setPartooTheme];
};
