import { useContext } from 'react';

import { Button, IconPrefix, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReviewObjectType } from 'app/api/types/review';
import { localeToLanguage } from 'app/common/services/languages';
import { useIsMobile } from 'app/common/services/screenDimensions';
import useBusiness from 'app/reviewManagement/reviewList/hooks/useBusiness';
import { keywordRegexFilterSelector, reviewFiltersSelector } from 'app/states/reviews/filters';

import {
    StyledReviewCardContent,
    StyledReviewComment,
    StyledSeeDetailsButton,
} from './ReviewCardContent.styled';
import ShowMoreText from './ShowMoreText';
import { useFeedbackResultPanel } from '../../FeedbackResultPanel/hooks';
import { ReviewCardContext, ReviewCardContextType } from '../ReviewCard';

export default function ReviewCardContent() {
    const { review } = useContext<ReviewCardContextType>(ReviewCardContext);
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const { openPanel } = useFeedbackResultPanel();
    const keywordList = keywordRegexFilterSelector(useSelector(reviewFiltersSelector));
    const business = useBusiness(review.businessId);
    const businessLanguage = !!business ? localeToLanguage(business.locale, '') : '';

    return (
        <StyledReviewCardContent>
            <StyledReviewComment>
                {!!review.content ? (
                    businessLanguage === 'ar' ? (
                        <Text>{review.content}</Text>
                    ) : (
                        <Text>
                            <ShowMoreText
                                text={review.content}
                                shortTextLength={isMobile ? 150 : 600}
                                keywordList={keywordList}
                            />
                        </Text>
                    )
                ) : (
                    <Text italic={true}>{t('no_comment')}</Text>
                )}
            </StyledReviewComment>
            {review.reviewObjectType === ReviewObjectType.FEEDBACK_RESULT && (
                <StyledSeeDetailsButton>
                    <Button
                        dataTrackId="review_card_feedback_result_see_details"
                        appearance="text"
                        variant="primary"
                        icon={['fa-arrow-right', IconPrefix.REGULAR]}
                        iconPosition="right"
                        onClick={() => openPanel(review.id)}
                    >
                        {t('review_card_feedback_result_see_details')}
                    </Button>
                </StyledSeeDetailsButton>
            )}
        </StyledReviewCardContent>
    );
}
