import { Stack, Text } from '@partoohub/ui';
import { addDays, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const UpdateDateSection = () => {
    const { t } = useTranslation();
    const updateDate = addDays(new Date(), -1);

    return (
        <Stack alignItems="flex-end" justifyContent="center" gap="4px">
            <Text color="secondary" variant="bodyLItalic">
                {t('review_analytics_updated__date', { date: format(updateDate, 'PPP') })}
            </Text>
            <Text color="secondary" variant="bodyLItalic">
                ({t('review_analytics_google_only')})
            </Text>
        </Stack>
    );
};
