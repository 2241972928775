import styled from '@emotion/styled';
import { IconButton, Stack, Text } from '@partoohub/ui';
import { Skeleton } from '@partoohub/ui/dist/components/Skeleton';

export const ChatbotConfigurationContainer = styled.div`
    display: flex;
    overflow: auto;
    height: 100%;
    gap: 40px;
    padding: 16px;
    background: var(
        --Theme-Gradient,
        linear-gradient(
            108deg,
            #e6f1f7 0%,
            #f3f3fc 23.96%,
            #f6f4f1 50%,
            #f6f2ec 73.96%,
            #f6f8fc 100%
        )
    );
`;

export const BackIconButton = styled(IconButton)`
    padding: 8px;
    position: sticky;
    top: 0;
`;

export const ChatbotConfigurationSectionWrapper = styled(Stack)`
    background: white;
    border-radius: 16px;
    flex: 1;
    align-self: baseline;
`;

export const ChatbotConfigurationSectionContainer = styled.div`
    background: white;
    padding: 24px;
    border-radius: 16px;
`;

export const ChatPreviewContainer = styled.div`
    margin: 36px 100px 0 0;
    position: sticky;
    top: 36px;
`;

export const ChatPreviewTitle = styled(Text)`
    display: flex;
    justify-content: center;
`;

export const ActivationButtonContainer = styled.div`
    padding: 16px;
    border-top: 1px solid var(--Blackberry-Alpha, rgba(20, 37, 66, 0.12));
`;

export const ChatbotHeaderContainer = styled(Stack)`
    margin-bottom: 16px;
`;

export const ChatbotNameText = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 30vw;
`;

export const ChatbotNameInput = styled.input`
    border: 0;
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: -1px;
    border-bottom: 1px solid;
    border-bottom-color: ${({ theme }) => theme.colors.default.dark};
    outline: none;
    padding: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 30vw;
`;

export const HiddenChatbotName = styled.span`
    position: absolute;
    left: -9999px;
    visibility: hidden;
    // This is needed to handle whitespaces correctly.
    white-space-collapse: preserve;
`;

export const NameSkeleton = styled(Skeleton)`
    height: 32px;
    > div {
        height: 32px;
    }
`;
