import { useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { useAppDataContext } from 'app/common/contexts/appData';
import { BUSINESS_MANAGER } from 'app/common/data/roles';
import { CONTAINED } from 'app/common/designSystem/constants/type';
import FunnelOnboarding from 'app/onboarding/FunnelOnboarding';

export const NoBusiness = () => {
    const [showFunnel, setShowFunnel] = useState(false);
    const { me } = useAppDataContext();
    const role = me?.role;

    const handleShowFunnel = () => setShowFunnel(true);

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };

    return (
        <>
            <NotFoundTemplate
                show
                imgSrc={illustrationElement}
                title={'no_business'}
                subtitle={'no_business_description'}
                buttonText={'add_local_business'}
                buttonStatus={CONTAINED}
                withButton={role !== BUSINESS_MANAGER}
                handleClick={handleShowFunnel}
            />

            <FunnelOnboarding showFunnel={showFunnel} setShowFunnel={setShowFunnel} />
        </>
    );
};
