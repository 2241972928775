import { useCallback, useEffect, useMemo, useState } from 'react';

import { Expandable, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import {
    StyledExpandableToggle,
    StyledGraphSelector,
    StyledSectionText,
    StyledSelectableCard,
} from './GraphSelectorSection.styled';
import { ReviewAnalyticsGraphType, useGraphSelection } from '../../hooks/useGraphSelection';
import { REVIEW_ANALYTICS_FILTER_TAGS } from '../../hooks/useReviewAnalyticsFilters';

export const GraphSelectorSection = () => {
    const { t } = useTranslation();
    const [selectedGraph, setSelectedGraph] = useGraphSelection();
    const [openedSections, setOpenedSections] = useState<Array<string>>([]);
    const [, setTagFilter] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_TAGS);

    const sections: Array<{ name: string; graphs: Array<ReviewAnalyticsGraphType> }> = useMemo(
        () => [
            {
                name: 'ereputation',
                graphs: ['average_rating', 'review_count', 'rating_distribution'],
            },
            {
                name: 'review_reply',
                graphs: ['reply_time', 'reply_means'],
            },
            {
                name: 'tags',
                graphs: ['tags'],
            },
        ],
        [],
    );

    useEffect(() => {
        // After selecting a graph, make sure its section is opened
        if (!selectedGraph) {
            setSelectedGraph('average_rating');
        }

        const selectedSection = sections.find(sect => sect.graphs.includes(selectedGraph))?.name;

        if (selectedSection && !openedSections.find(opened => opened === selectedSection)) {
            setOpenedSections([...openedSections, selectedSection]);
        }
    }, [selectedGraph]);

    useEffect(() => {
        // On first render, set graph to average rating if the requested graph does not exist
        if (!sections.flatMap(section => section.graphs).includes(selectedGraph)) {
            setSelectedGraph('average_rating');
        }
    }, []);

    const toggleSection = useCallback((section: string) => {
        setOpenedSections(prevOpened =>
            prevOpened.includes(section)
                ? prevOpened.filter(opened => opened !== section)
                : [...prevOpened, section],
        );
    }, []);

    const handleGraphChange = (graph: ReviewAnalyticsGraphType) => setSelectedGraph(graph);

    const handleAllTagsClick = () => setTagFilter('');

    return (
        <StyledGraphSelector>
            {sections.map(({ name, graphs }) => {
                return (
                    <div key={name}>
                        <StyledExpandableToggle onClick={() => toggleSection(name)}>
                            <StyledSectionText variant="bodyLSemibold">
                                {t(`review_analytics_graph_section_${name}`)}
                            </StyledSectionText>
                            <Icon
                                icon={[
                                    openedSections.includes(name)
                                        ? FontAwesomeIconsPartooUsed.faChevronDown
                                        : FontAwesomeIconsPartooUsed.faChevronRight,
                                    IconPrefix.REGULAR,
                                ]}
                            />
                        </StyledExpandableToggle>
                        <Expandable key={name} opened={openedSections.includes(name)}>
                            {graphs.map(graph => (
                                <div
                                    key={graph}
                                    tabIndex={0}
                                    onKeyDown={() => undefined}
                                    onClick={graph === 'tags' ? handleAllTagsClick : undefined}
                                >
                                    <StyledSelectableCard
                                        label={
                                            <Text as="span" variant="bodyMBold" color="secondary">
                                                {t(`review_analytics_graph_${graph}`)}
                                            </Text>
                                        }
                                        dataTrackId={`review_analytics_graph_${graph}`}
                                        value={graph}
                                        checked={selectedGraph === graph}
                                        onChange={() => handleGraphChange(graph)}
                                    />
                                </div>
                            ))}
                        </Expandable>
                    </div>
                );
            })}
        </StyledGraphSelector>
    );
};
