export const ImportIcon = () => {
    return (
        <svg
            width="94"
            height="93"
            viewBox="0 0 94 93"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="46.9999" cy="46.5001" r="46.2907" fill="#7F8EA4" fillOpacity="0.12" />
            <path
                d="M64.4135 28.6499V64.3499C64.4135 68.1052 55.5878 71.1499 44.7056 71.1499C33.8235 71.1499 24.9978 68.1052 24.9978 64.3499V28.6499H64.4135Z"
                fill="#35718A"
            />
            <path
                d="M44.7056 35.4501C55.59 35.4501 64.4135 32.4056 64.4135 28.6501C64.4135 24.8946 55.59 21.8501 44.7056 21.8501C33.8213 21.8501 24.9978 24.8946 24.9978 28.6501C24.9978 32.4056 33.8213 35.4501 44.7056 35.4501Z"
                fill="#4184A9"
            />
            <path
                d="M24.9978 52.4498C24.9978 56.2051 33.8235 59.2498 44.7056 59.2498C55.5878 59.2498 64.4135 56.2051 64.4135 52.4498V40.5498C64.4135 44.3051 55.5878 47.3498 44.7056 47.3498C33.8235 47.3498 24.9978 44.3051 24.9978 40.5498V52.4498Z"
                fill="#4184A9"
            />
            <path
                d="M29.2822 29.4999C29.1023 29.4999 28.927 29.4438 28.7811 29.3395C28.6352 29.2351 28.5261 29.0879 28.4693 28.9187C28.4124 28.7495 28.4106 28.5667 28.4643 28.3965C28.5179 28.2262 28.6242 28.0769 28.768 27.9699C28.96 27.8263 33.6582 24.3999 44.7057 24.3999C44.9329 24.3999 45.1509 24.4895 45.3116 24.6489C45.4723 24.8083 45.5625 25.0245 45.5625 25.2499C45.5625 25.4753 45.4723 25.6915 45.3116 25.8509C45.1509 26.0103 44.9329 26.0999 44.7057 26.0999C34.3025 26.0999 29.84 29.2976 29.7963 29.3299C29.648 29.4403 29.4676 29.4999 29.2822 29.4999Z"
                fill="#5498C8"
            />
            <path
                d="M29.3198 39.7001C29.2018 39.7007 29.0851 39.6767 28.9771 39.6296C28.6475 39.4874 28.3333 39.3412 28.0345 39.191C27.9337 39.1407 27.8439 39.0712 27.7702 38.9864C27.6966 38.9016 27.6405 38.8032 27.6053 38.6969C27.5701 38.5905 27.5564 38.4783 27.565 38.3667C27.5736 38.2551 27.6043 38.1463 27.6555 38.0465C27.7066 37.9467 27.7771 37.8579 27.8629 37.7852C27.9487 37.7125 28.0481 37.6573 28.1554 37.6229C28.2628 37.5884 28.376 37.5753 28.4884 37.5843C28.6009 37.5933 28.7105 37.6243 28.8108 37.6754C29.079 37.8106 29.3626 37.9423 29.6591 38.0707C29.8398 38.1486 29.9879 38.286 30.0784 38.4596C30.1689 38.6332 30.1964 38.8324 30.1561 39.0238C30.1159 39.2151 30.0104 39.3868 29.8574 39.51C29.7045 39.6332 29.5134 39.7004 29.3164 39.7001H29.3198Z"
                fill="#FDE05E"
            />
            <path
                d="M33.5663 41.0599C33.4988 41.06 33.4314 41.052 33.3658 41.0361C33.0665 40.9647 32.7737 40.8911 32.4875 40.8151C32.3754 40.7896 32.2696 40.7419 32.1764 40.6749C32.0832 40.6079 32.0046 40.5229 31.9453 40.4251C31.8859 40.3273 31.847 40.2187 31.8309 40.1057C31.8148 39.9926 31.8218 39.8776 31.8516 39.7673C31.8813 39.657 31.9331 39.5538 32.0038 39.4638C32.0746 39.3738 32.163 39.2989 32.2636 39.2435C32.3642 39.1881 32.475 39.1534 32.5895 39.1414C32.7039 39.1294 32.8196 39.1404 32.9297 39.1738C33.2033 39.2458 33.4818 39.3155 33.7651 39.3829C33.9695 39.431 34.149 39.5519 34.2695 39.7227C34.3899 39.8935 34.4429 40.1022 34.4183 40.3092C34.3938 40.5162 34.2935 40.707 34.1364 40.8454C33.9793 40.9839 33.7764 41.0602 33.5663 41.0599Z"
                fill="#69E6C3"
            />
            <g clipPath="url(#clip0_1759_5821)">
                <path
                    d="M74.9829 54.9014C74.5484 54.9014 74.1712 54.5897 74.0985 54.1575C73.451 50.4069 69.7899 43.861 60.503 43.4785V48.2907C60.503 48.6241 60.3152 48.9296 60.0163 49.0822C59.7174 49.2347 59.3576 49.2088 59.0841 49.015L45.6284 39.4808C45.3926 39.3137 45.2527 39.0438 45.2527 38.7564C45.2527 38.4689 45.3926 38.1991 45.6284 38.0321L59.0842 28.4979C59.3576 28.304 59.7174 28.2781 60.0163 28.4307C60.3152 28.5832 60.503 28.8887 60.503 29.2222V34.1069C66.2436 34.6749 75.881 38.7854 75.881 54.0112C75.881 54.4744 75.5229 54.8601 75.0577 54.8983C75.0326 54.9003 75.0076 54.9014 74.9829 54.9014Z"
                    fill="#2CB884"
                />
            </g>
            <defs>
                <clipPath id="clip0_1759_5821">
                    <rect
                        width="30.6283"
                        height="30.383"
                        fill="white"
                        transform="translate(45.2527 26.4253)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
