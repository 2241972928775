import { RefObject, useRef, useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix } from '@partoohub/ui';
import { Outlet, useLocation } from 'react-router-dom';

import { GAP } from 'app/common/components/Layout/Layout.styled';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import IS_IFRAME from 'app/common/data/iframe';
import { DEFAULT_ANIMATION_IN_SECOND } from 'app/constants/constants';
import { IS_BELOW_TABLET, useDeviceType, useIntersectionObserver } from 'app/hooks';
import { useScrollToRef } from 'app/hooks/useScrollToRef';
import { REGEX_SETTINGS_CATEGORY_EXACT_PATH } from 'app/pages/settingsV2/helpers';
import {
    SettingMenuItem,
    SettingsMenuList,
} from 'app/pages/settingsV2/subComponents/SettingsMenuList/SettingsMenuList';
import { FIXED_CONTAINER_Y_PADDING } from 'app/routing/components/FixedPageHeader/FixedPageHeader.styled';

import {
    MenuListContent,
    PAGE_HEADER_HEIGHT,
    ScrollToBottomContainer,
    SettingSkeletonContainer,
    StickySettingsMenuList,
} from './SettingSkeleton.styled';

interface SettingsSkeleton {
    sections: SettingMenuItem[];
    activateScroll?: boolean;
}

export const SettingSkeleton = ({ sections, activateScroll = true }: SettingsSkeleton) => {
    const { pathname } = useLocation();
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const shouldNotRender = (section: SettingMenuItem) =>
        section.options.every(option => option.display === false);
    const targetScrollRef: RefObject<HTMLElement> | null = useRef(
        document.getElementById('react-content'),
    );
    const boundaryRef: RefObject<HTMLDivElement> | null = useRef(null);
    const { isBoundary: isAtTop, handleScroll } = useScrollToRef(boundaryRef, targetScrollRef);
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const shouldNotRenderComponent = sections.every(section => shouldNotRender(section));
    const matchSettingsCategoryPath = REGEX_SETTINGS_CATEGORY_EXACT_PATH.test(pathname);

    const [isSticky, setIsSticky] = useState(false);
    const stickySettingsMenuListRef = useRef<HTMLDivElement>(null);
    const rootMargin = isSticky
        ? `-${GAP * 2 + FIXED_CONTAINER_Y_PADDING * 2 + PAGE_HEADER_HEIGHT}px 0px 0px 0px`
        : `-${GAP}px 0px 0px 0px`;
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useIntersectionObserver({
        target: stickySettingsMenuListRef,
        callback: entry => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                setIsSticky(!entry.isIntersecting);
            }, DEFAULT_ANIMATION_IN_SECOND * 1000);
        },
        options: {
            rootMargin,
            threshold: 1,
        },
        dependencies: [isSticky],
    });

    if (shouldNotRenderComponent) {
        return null;
    }

    return (
        <>
            <div ref={boundaryRef} />
            <SettingSkeletonContainer isBelowTablet={isBelowProvidedDevices}>
                {!IS_IFRAME && (matchSettingsCategoryPath || !isBelowProvidedDevices) && (
                    <StickySettingsMenuList
                        ref={stickySettingsMenuListRef}
                        isSticky={isSticky}
                        isNewSidebarEnabled={isNewSidebarEnabled}
                    >
                        <SettingsMenuList sections={sections} />
                    </StickySettingsMenuList>
                )}
                <MenuListContent>
                    <Outlet />
                </MenuListContent>
                {activateScroll && !isAtTop && (
                    <ScrollToBottomContainer>
                        <IconButton
                            icon={[FontAwesomeIconsPartooUsed.faChevronUp, IconPrefix.SOLID]}
                            dataTrackId="scroll_to_top"
                            onClick={handleScroll}
                        />
                    </ScrollToBottomContainer>
                )}
            </SettingSkeletonContainer>
        </>
    );
};
