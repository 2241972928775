import LoadingView from 'app/common/components/LoadingView';
import { NoBusiness } from 'app/common/components/placeholder/NoBusiness';
import { NoReviewManagement } from 'app/common/components/placeholder/reviewMangement/NoReviewManagement';

import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import { ReviewsPageHeader } from 'app/pages/analytics/ReviewAnalytics/pageHeader/ReviewsPageHeader';
import { AnalyticsTabs } from 'app/pages/analytics/VisibilityAnalyticsV2/components/AnalyticsTabs';

import { GetParamsReduxSync } from './GetParamsReduxSync';
import { FilterSection } from './pageHeader';
import ReviewAnalyticsSection from './ReviewAnalyticsSection/ReviewAnalyticsSection';

export const ReviewAnalyticsWithNewSidebar = () => {
    const { hasBusinesses, subscribedToRM, isLoading } = useBusinessSubscriptions();

    if (isLoading) {
        return <LoadingView />;
    }

    if (!hasBusinesses) {
        return <NoBusiness />;
    }

    if (!subscribedToRM) {
        return <NoReviewManagement />;
    }

    return (
        <>
            <GetParamsReduxSync />
            <ReviewsPageHeader />
            <AnalyticsTabs />
            <FilterSection />
            <ReviewAnalyticsSection />
        </>
    );
};
