import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
    AverageCompletionValueContainer,
    AverageValue,
    Completed,
    WhiteContainer,
} from './AverageCompletionValue.styled';

type Props = {
    value?: number | null;
};

const AverageCompletionValue = ({ value }: Props) => {
    const DURATION_TIMEOUT = 1000;

    const { t } = useTranslation();
    const [displayedValue, setDisplayedValue] = useState<number>(value ?? 0);

    useEffect(() => {
        const animate = () => {
            if (!value || displayedValue === value) return;
            let current: number = displayedValue ?? 0;
            const increment = value > displayedValue ? 1 : -1;
            const range = Math.abs((displayedValue - value) * 5);
            const stepTime = Math.abs(Math.floor(DURATION_TIMEOUT / range));
            const timer = setTimeout(() => {
                current += increment;
                setDisplayedValue(current);
                if (current === value) {
                    clearTimeout(timer);
                }
            }, stepTime);
        };
        if (displayedValue !== value) {
            animate();
        }
    }, [displayedValue, value]);

    return (
        <AverageCompletionValueContainer>
            <WhiteContainer>
                <AverageValue>{value ? `${displayedValue}%` : '-'}</AverageValue>
                {typeof value === 'number' && (
                    <Completed>{t('business_edit_status_completion_completed')}</Completed>
                )}
            </WhiteContainer>
        </AverageCompletionValueContainer>
    );
};
export default AverageCompletionValue;
