import { FontAwesomeIconsPartooUsed, IconElement, Text, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import {
    AssignFilterOption,
    MESSAGE_CONTENT_TYPE,
    StatusFilterOption,
    URLParams,
} from 'app/states/messaging';

import queryClient from 'app/states/queryClient';

import { OverridenButton } from './StatusChangeButton.styled';
import { getNextActiveConversation } from '../../ConversationUtils';

import { useGetActiveConversation } from '../../hooks/useGetActiveConversation';
import { useGetConversations } from '../../hooks/useGetConversations';

import useUpdateConversationStatus from '../../hooks/useUpdateConversationStatus';
import { createStatusMessage } from '../../services/createMetadataMessage';
import { removeConversation } from '../../services/handleConversationsCache';
import { addNewMessage } from '../../services/handleMessagesCache';

export const StatusChangeButton = () => {
    const { data: me } = useMe();
    const { t } = useTranslation();
    const [statusFilter] = useStateQueryParams<StatusFilterOption>(URLParams.STATUS);
    const [assignFilter] = useStateQueryParams<AssignFilterOption>(URLParams.ASSIGN);
    const businessFilters = useBusinessModalFilters();
    const activeConversation = useGetActiveConversation()!;
    const updateConversation = useUpdateConversationStatus(activeConversation.id);
    const [, setConversationParamId] = useStateQueryParams(URLParams.CONVERSATION_ID);
    const { conversations } = useGetConversations();

    const icon =
        statusFilter === StatusFilterOption.OPEN
            ? [FontAwesomeIconsPartooUsed.faCircleCheck]
            : ['fa-inbox-out'];

    const text =
        statusFilter === StatusFilterOption.OPEN
            ? t('message_close_conv_button')
            : t('message_reopen_conv_button');

    const dataTrackId =
        statusFilter === 'open'
            ? 'messaging_active_conversation_close_conversation'
            : 'messaging_active_conversation_reopen_conversation';

    const handleClick = () => {
        const nextConversation = getNextActiveConversation(
            conversations,
            activeConversation,
            activeConversation.id,
        );

        const contentType =
            statusFilter === StatusFilterOption.OPEN
                ? MESSAGE_CONTENT_TYPE.SOLVING
                : MESSAGE_CONTENT_TYPE.REOPENING;
        const metadataMessage = createStatusMessage(activeConversation, contentType, me);
        addNewMessage(queryClient, activeConversation.id, metadataMessage);

        removeConversation(activeConversation.id, statusFilter, assignFilter, businessFilters);

        if (nextConversation) setConversationParamId(String(nextConversation.id));

        const newStatus =
            statusFilter === StatusFilterOption.OPEN
                ? StatusFilterOption.CLOSED
                : StatusFilterOption.OPEN;

        const successMessage =
            statusFilter === StatusFilterOption.OPEN
                ? 'message_toast_conv_closed'
                : 'message_toast_conv_reopened';

        toast.success(null, t(successMessage));
        updateConversation.mutate({ status: newStatus });
    };

    return (
        <OverridenButton
            dataTrackId={dataTrackId}
            disabled={activeConversation.is_expired}
            size="small"
            shape="cube"
            variant="secondary"
            appearance="outlined"
            icon={icon as IconElement}
            onClick={handleClick}
        >
            <Text as="span" variant="bodySSemibold">
                {text}
            </Text>
        </OverridenButton>
    );
};
