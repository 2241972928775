import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { DefaultShortcutComponent } from 'app/pages/Homepage/sections/HomepageShortcuts/DefaultShortcutComponent';
import { CUSTOMER_EXPERIENCE_SEND_INVITE_PATH } from 'app/routing/routeIds';

export const ShortcutSendInvite = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <DefaultShortcutComponent
            title={t('homepage_shortcut_rb_send_an_invitation')}
            variant="warning"
            iconElement={[FontAwesomeIconsPartooUsed.faRocket, IconPrefix.SOLID]}
            onClick={() => navigate(CUSTOMER_EXPERIENCE_SEND_INVITE_PATH)}
            dataTrack="click_homepage_shortcut_send_invite"
        />
    );
};
