import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { ExportPayload } from 'app/api/types/competitiveBenchmark';
import api from 'app/api/v2/api_calls';

export const usePostExportTask = (): {
    postExportTask: (payload: ExportPayload) => Promise<void>;
    isLoading: boolean;
} => {
    const { t } = useTranslation();
    const { mutate: postExportTask, isLoading } = useMutation(
        (payload: ExportPayload) => api.competitiveBenchmark.postExportTask(payload),
        {
            onSuccess: () => {
                toast.loading(
                    t('hours_update_progress_export_progress_report'),
                    t('hours_update_progress_export_progress'),
                );
            },
            onError: (error: Error) => {
                toast.error(error.message, t('request_issue'));
            },
        },
    );

    return {
        postExportTask: postExportTask as (payload: ExportPayload) => Promise<void>,
        isLoading,
    };
};
