import { useEffect, useMemo } from 'react';

import { IconButton, Stack, Table } from '@partoohub/ui';
import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { sumValues } from 'app/common/utils/sumValues';

import { useInternalRankingParameters } from './hooks/useInternalRankingParameters';
import { StyledCell, StyledRowOverlay, StyledTableRow } from './Rankings.styled';
import {
    calculateYProportion,
    getQuadrantBackgroundColor,
    getReviewQuantity,
} from '../../../helper';
import { useGraphSelection } from '../../../hooks/useGraphSelection';
import { useReviewTagMetrics } from '../../../hooks/useMetrics';
import { REVIEW_ANALYTICS_FILTER_TAGS } from '../../../hooks/useReviewAnalyticsFilters';

type TagDataItemProps = {
    x: number;
    y: number;
    yProportion: number;
    dimension: string;
    dimension_name: string;
    backgroundColor: string;
    quadrant: 'topRight' | 'topLeft' | 'bottomLeft' | 'bottomRight';
};

export const TagsRanking = () => {
    const { t } = useTranslation();
    const { resetParameters } = useInternalRankingParameters();
    const [, setTagFilter] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_TAGS);
    const [, setSelectedGraph] = useGraphSelection();

    useEffect(() => {
        resetParameters({});
    }, []);

    const metrics = useReviewTagMetrics();

    const tagsData: TagDataItemProps[] = useMemo(() => {
        const rawData = metrics?.data ?? [];

        const xValues = rawData.map(d => sumValues(d.metrics.rating_distribution));

        const { middleReviewQuantity } = getReviewQuantity(xValues);

        const middleY = 3;

        return rawData.map(d => {
            const x = sumValues(d.metrics.rating_distribution);
            const y = d.metrics.average_rating;
            const yProportion = calculateYProportion(y);
            const backgroundColor = getQuadrantBackgroundColor(
                x,
                yProportion,
                middleReviewQuantity,
                middleY,
            );
            let quadrant: 'topRight' | 'topLeft' | 'bottomLeft' | 'bottomRight';

            if (x >= middleReviewQuantity && yProportion >= middleY) {
                quadrant = 'topRight';
            } else if (
                (x < middleReviewQuantity && yProportion >= middleY) ||
                (x === middleReviewQuantity && yProportion === middleY)
            ) {
                quadrant = 'topLeft';
            } else if (x <= middleReviewQuantity && yProportion < middleY) {
                quadrant = 'bottomLeft';
            } else {
                quadrant = 'bottomRight';
            }

            return {
                x,
                y,
                yProportion,
                dimension: d.dimension,
                dimension_name: d.dimension_name,
                backgroundColor,
                quadrant,
            };
        });
    }, [metrics]);

    if (tagsData.length === 0) return null;

    type Quadrant = 'topRight' | 'topLeft' | 'bottomLeft' | 'bottomRight';

    // Group and sort the rows
    const groupedData: Record<Quadrant, typeof tagsData> = {
        topRight: [],
        topLeft: [],
        bottomLeft: [],
        bottomRight: [],
    };

    tagsData.forEach(item => groupedData[item.quadrant].push(item));

    // Sort rows within each group
    Object.keys(groupedData).forEach(group => {
        const quadrant = group as Quadrant;
        groupedData[quadrant].sort((a, b) => b.x - a.x);
    });

    const handleRowClick = (dimension: string) => {
        setTagFilter(dimension);
        setSelectedGraph('average_rating');
    };

    return (
        <Table
            columns={[
                {
                    label: t('review_analytics_rankings_tag'),
                    width: '3000px',
                },
                {
                    label: (
                        <Stack style={{ flex: 1 }}>
                            {t('review_analytics_rankings_reviews_tagged')}
                        </Stack>
                    ),
                    width: '3000px',
                },
                {
                    label: (
                        <Stack style={{ flex: 1 }}>
                            {t('review_analytics_rankings_tag_average_rating')}
                        </Stack>
                    ),
                    width: '3000px',
                },
                {
                    label: '', // Ghost column for the arrow
                    width: '50px',
                },
            ]}
        >
            <Table.Body>
                {['topRight', 'topLeft', 'bottomLeft', 'bottomRight'].map(group => {
                    const quadrant = group as Quadrant;
                    return groupedData[quadrant].map(
                        ({ dimension, dimension_name, x, y, backgroundColor }) => (
                            <StyledTableRow
                                key={dimension}
                                dataTrackId="review_analytics__ranking_tags"
                                id={dimension}
                                backgroundColor={backgroundColor}
                            >
                                <StyledCell align="left">{dimension_name}</StyledCell>
                                <StyledCell>{x}</StyledCell>
                                <StyledCell>{y.toFixed(2)}</StyledCell>

                                {/* StyledRowOverlay for row click and hover arrow */}
                                <StyledRowOverlay onClick={() => handleRowClick(dimension)}>
                                    <IconButton
                                        dataTrackId="review_analytics__ranking_tags_row"
                                        data-css-selector="drilldown-button"
                                        size="small"
                                        appearance="outlined"
                                        icon={[
                                            FontAwesomeIconsPartooUsed.faArrowRight,
                                            IconPrefix.REGULAR,
                                        ]}
                                    />
                                </StyledRowOverlay>
                            </StyledTableRow>
                        ),
                    );
                })}
            </Table.Body>
        </Table>
    );
};
