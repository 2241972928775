import styled from '@emotion/styled';
import { Stack, Table } from '@partoohub/ui';

export const StyledHeader = styled(Stack)`
    padding: 24px 24px 0 24px;
`;

export const StyledCell = styled(Table.Cell)<{ align?: string }>`
    height: 52px;
    text-align: ${({ align }) => align || 'center'};
`;

export const StyledBackgroundTableCell = styled(Table.Cell)<{
    color: 'emerald' | 'success' | 'secondary' | 'warning' | 'danger';
}>`
    padding: 16px;
    background-color: ${({ theme, color }) => theme.colors[color].alpha};
    text-align: center;
`;

export const StyledPaginationWrapper = styled(Stack)`
    align-items: flex-end;
    padding-right: 24px;
`;

export const StyledTableRow = styled(Table.Row)<{ dataTrackId: string; backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    &:hover {
        font-weight: bold;
    }
    & > td {
        font-weight: inherit;
    }
    position: relative;
`;

export const StyledRowOverlay = styled.div<{ onClick?: () => void }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    [data-css-selector='drilldown-button'] {
        visibility: hidden;
    }
    &:hover [data-css-selector='drilldown-button'] {
        visibility: visible;
    }
`;
