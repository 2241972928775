import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba } from '@partoohub/branding';

export const BusinessCardGridContainer = styled.div`
    width: 100%;
    height: 80px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0 26px;
    border-left: 5px solid ${({ theme }) => theme.colors.secondary.initial};
    background: ${({ theme }) => theme.colors.theme.background};
    box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.06);
`;

export const BusinessCardGridItemContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const BusinessFullAddress = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const BusinessImage = styled.div`
    display: flex;
    border-radius: 4px;
    height: 50px;
    width: 50px;
    background-color: ${convertToRgba(HEX_COLORS.blackberry, 0.12)};
    margin-right: 16px;
`;

export const BusinessTitle = styled.div`
    height: 14px;
    background-color: ${convertToRgba(HEX_COLORS.blackberry, 0.12)};
    margin-bottom: 10px;
`;

export const BusinessAddress = styled.div`
    height: 14px;
    background-color: ${convertToRgba(HEX_COLORS.blackberry, 0.12)};
`;
