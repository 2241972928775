import { FC } from 'react';

import { Banner, Button, Stack, Text } from '@partoohub/ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';

import { ColorPicker } from './components/ColorPicker';
import { DropdownFormSection } from './components/DropdownFormSection';
import { BrandSection } from './components/Sections/BrandSection/BrandSection';
import { ChannelsSelector } from './components/Sections/ChannelsSelector';
import { IconSelector } from './components/Sections/IconSelector/IconSelector';
import { WidgetDomainSection } from './components/Sections/WidgetDomainSection/WidgetDomainSection';
import { useGetFirstBusinessPage } from './hooks/useGetFirstBusinessPage';

import { WidgetSetupFormValues } from './WidgetSetup';

interface Props {
    initialData: Partial<WidgetSetupFormValues>;
    onSubmit: (formValues: WidgetSetupFormValues) => void;
    isCreation: boolean;
    isSubmitting: boolean;
}

export const WidgetSetupForm: FC<Props> = ({ initialData, onSubmit, isCreation, isSubmitting }) => {
    const { t } = useTranslation();
    const { setValue, watch, register, handleSubmit, control } = useForm<WidgetSetupFormValues>({
        defaultValues: initialData,
    });
    const { data: firstBusinessPage } = useGetFirstBusinessPage();

    return (
        <Stack direction="column" gap="16px">
            {firstBusinessPage && firstBusinessPage.count >= 2 && (
                <BrandSection control={control} name="business_id" />
            )}

            <DropdownFormSection
                header={<Text variant="heading6">{t('widget_setup_channels_section_title')}</Text>}
            >
                <ChannelsSelector control={control} name="active_channels" />
            </DropdownFormSection>

            <WidgetDomainSection
                domains={watch('domain_whitelist')}
                onChange={domains => setValue('domain_whitelist', domains)}
            />

            <DropdownFormSection
                canCollapse
                header={
                    <Stack gap="8px" direction="row" alignItems="center">
                        <Text variant="heading6">
                            {t('widget_setup_personalize_section_title')}
                        </Text>
                    </Stack>
                }
            >
                {!isCreation && (
                    <Banner key="warning" variant="warning" withCloseIcon={false}>
                        {t('widget_setup_personalize_section_warning_banner')}
                    </Banner>
                )}
                <ColorPicker color={watch('color')} onChange={color => setValue('color', color)} />
                <IconSelector control={control} name="icon" />
                <p>avatar_url</p>
                <input {...register('avatar_url')} />
            </DropdownFormSection>
            <MenuListContentContainer>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    dataTrackId={''}
                    appearance="contained"
                    shape="cube"
                    size="medium"
                    variant="primary"
                    icon={isCreation ? undefined : ['fa-save']}
                    iconPosition="left"
                    full
                >
                    {isCreation ? t('widget_setup_create_button') : t('widget_setup_update_button')}
                </Button>
            </MenuListContentContainer>
        </Stack>
    );
};
