import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba, radius } from '@partoohub/branding';
import { FontAwesomeIconsPartooUsed, Icon, Stack } from '@partoohub/ui';

export const DropdownFormSectionContainer = styled(Stack)`
    width: 100%;
    padding: 24px 16px 24px 24px;
    border-radius: ${radius.large};
    border: 1px solid ${convertToRgba(HEX_COLORS.blackberry, 0.12)};
`;

export const DropdownFormSectionHeader = styled(Stack)<{ canCollapse: boolean }>`
    width: 100%;
    ${({ canCollapse }) =>
        canCollapse &&
        css`
            cursor: pointer;
        `}
`;

interface DropdownFormSectionChevronProps {
    opened: boolean;
    icon: [FontAwesomeIconsPartooUsed.faChevronDown];
}

export const DropdownFormSectionChevron = styled(Icon)`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s;
    ${(props: DropdownFormSectionChevronProps) =>
        props.opened &&
        css`
            transform: rotate(-180deg);
        `}
`;

export const DropdownFormSectionContent = styled(Stack)`
    padding-top: 24px;
`;
