import styled from '@emotion/styled';

import { CompletionBadgeAnimation } from 'app/businessEditV2/BusinessEditV2.styled';

export const BusinessBannerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    min-height: 90px;
    background-color: ${({ theme }) => theme.colors.default.initial};
    color: ${({ theme }) => theme.colors.theme.initial};
    font-weight: bold;
    position: relative;
    font-size: 18px;
    text-align: center;
`;

export const BannerMessage = styled.div`
    margin: 0 90px;
    line-height: 1.1;
    z-index: 1;
`;

export const BusinessBannerImages = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    z-index: 1;

    &:hover {
        > svg:first-of-type {
            ${CompletionBadgeAnimation({ from: 115, to: 100 })}
        }

        > svg:last-of-type {
            ${CompletionBadgeAnimation({
                from: 100,
                to: 90,
                rotatePath: false,
            })}
        }
    }

    > svg:first-of-type {
        transform: scale(1.15);
        margin-left: -20px;
    }

    > svg:last-of-type {
        align-self: flex-start;
    }
`;
