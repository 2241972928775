import { useContext } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import FunnelModal from 'app/common/components/FunnelModal';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { useAppDataContext } from 'app/common/contexts/appData';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { BULK_RELOOKING } from 'app/common/data/featureFlags';
import IS_IFRAME from 'app/common/data/iframe';

import { RouterContext } from 'app/routing/context/RouterContext';
import {
    NOT_FOUND_PATH,
    VISIBILITY_BULK_UPDATE_EXPORT_PATH,
    VISIBILITY_BULK_UPDATE_PATH,
    VISIBILITY_PATH,
} from 'app/routing/routeIds';

import { BulkUpdate } from './BulkUpdate';

import { ButtonContent, StyledButton, TwoButtonsWrapper } from './BulkUpdateModal.styled';
import { ExportIcon } from './ExportIcon';
import { ImportIcon } from './ImportIcon';

export const BulkUpdateModal = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { routes } = useContext(RouterContext);
    const { me, organization } = useAppDataContext();
    const isAllowedToUseFeature =
        me?.can_use_bulk_modification && (organization?.has_bulk_modification ?? false);

    const bulkRelookingEnabled = useFeatureFlag(BULK_RELOOKING);

    const chooseExportImportScreen = pathname == VISIBILITY_BULK_UPDATE_PATH;
    const inExportModal = pathname.startsWith(VISIBILITY_BULK_UPDATE_EXPORT_PATH);

    const getFirstValidPath = () => {
        const validPath = routes.find(
            route => !route.startsWith(VISIBILITY_BULK_UPDATE_PATH) && route !== NOT_FOUND_PATH,
        );
        return validPath ?? VISIBILITY_PATH;
    };

    const handleRedirect = () => {
        navigate(getFirstValidPath());
    };

    if (!isAllowedToUseFeature) {
        const illustrationElement: IllustrationLaptopElement = {
            type: IllustrationCategoryEnum.Laptop,
            name: IllustrationLaptopEnum.Sad,
        };

        return (
            <NotFoundTemplate
                show
                imgSrc={illustrationElement}
                title="no_access_title_bulk_modification"
                subtitle="no_access_subtitle_bulk_modification"
                withButton={false}
            />
        );
    }

    return (
        <>
            {bulkRelookingEnabled && chooseExportImportScreen && (
                <FunnelModal
                    isOpen={true}
                    onClose={IS_IFRAME ? () => undefined : handleRedirect}
                    background="transparent"
                >
                    <Stack direction="column" alignItems="center" gap="56px">
                        <Text variant="heading1">{t('bulk_update_what_do_you_want_to_do')}</Text>
                        <TwoButtonsWrapper justifyContent="center" gap="24px" direction="row">
                            <StyledButton to={VISIBILITY_BULK_UPDATE_EXPORT_PATH}>
                                <Stack direction="column" alignItems="center">
                                    <ExportIcon />
                                    <ButtonContent direction="column" gap="8px" alignItems="center">
                                        <Text variant="heading6">
                                            {t('bulk_update_export_data')}
                                        </Text>
                                        <Text variant="bodyLRegular">
                                            {t('bulk_update_filter_data_you_want_to_export')}
                                        </Text>
                                    </ButtonContent>
                                </Stack>
                            </StyledButton>
                            <StyledButton to={VISIBILITY_BULK_UPDATE_EXPORT_PATH}>
                                <Stack direction="column" alignItems="center" gap="16px">
                                    <ImportIcon />
                                    <ButtonContent>
                                        <Text variant="heading6">
                                            {t('bulk_update_import_data')}
                                        </Text>
                                        <Text variant="bodyLRegular">
                                            {t('bulk_update_upload_the_file')}
                                        </Text>
                                    </ButtonContent>
                                </Stack>
                            </StyledButton>
                        </TwoButtonsWrapper>
                    </Stack>
                </FunnelModal>
            )}
            {bulkRelookingEnabled && inExportModal && (
                <FunnelModal
                    isOpen={true}
                    onClose={IS_IFRAME ? () => undefined : handleRedirect}
                    onBack={() => {
                        if (inExportModal) {
                            navigate(VISIBILITY_BULK_UPDATE_PATH);
                        }
                    }}
                >
                    <BulkUpdate />
                </FunnelModal>
            )}
            {!bulkRelookingEnabled && (
                <FunnelModal isOpen={true} onClose={IS_IFRAME ? () => undefined : handleRedirect}>
                    <BulkUpdate />
                </FunnelModal>
            )}
        </>
    );
};
