import { useEffect, useRef, useState } from 'react';

import { Button, FontAwesomeIconsPartooUsed, Icon, Text, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { V2BusinessData } from 'app/api/types/business';
import { BusinessUser, SearchOrderEnum, UserStatusEnum } from 'app/api/types/user';

import { ORG_ADMIN } from 'app/common/data/roles';
import { useBusinesses } from 'app/common/hooks/queries/useBusinesses';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useDebounce from 'app/common/hooks/useDebounce';
import dataLayer from 'app/common/utils/dataLayer';
import { useWindowSize } from 'app/hooks';
import { useMessagingUsers } from 'app/reviewManagement/messaging/LoadedUsersProvider';
import { MessagingPartnerEnum } from 'app/states/messaging';

import {
    BackButton,
    Container,
    SearchMenuContainer,
    TextFieldContainer,
    TextWrapper,
} from './AssignConversationButton.styled';
import { AssignConversationMenuList } from './AssignConversationMenuList';
import { DisplayedLabel } from './DisplayedLabel';
import { useGetActiveConversation } from '../../hooks/useGetActiveConversation';
import { useGetAllBusinessUsers } from '../../hooks/useGetAllBusinessUsers';
import { useHasAssignConversationFeature } from '../../hooks/useHasAssignConversationFeature';

const HEIGHT_THRESHHOLD = 700;

export const AssignConversationButton = () => {
    const activeConversation = useGetActiveConversation()!;
    const { t } = useTranslation();
    const { data: me } = useMe();
    const businessUserMe = me
        ? { id: me.id, role: me.role, firstName: me.first_name, lastName: me.last_name }
        : null;
    const { height } = useWindowSize();
    const debouncedWindowHeight = useDebounce(height);

    const businessId = activeConversation.business.id;
    const params = {
        status: UserStatusEnum.ACTIVE,
        order_by: SearchOrderEnum.MINUSROLE,
    };
    const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(null);
    const [users, isLoading] = useGetAllBusinessUsers(selectedBusinessId ?? businessId, params);
    const { loadedUsers, setLoadedUsers } = useMessagingUsers();
    const newLoadedUsers = users.reduce((acc, user) => ({ ...acc, [user.id]: user }), loadedUsers);

    const { businesses } = useBusinesses({ subscribed_to_bm: 'True' });
    const selectedBusiness = businesses?.find(
        (business: V2BusinessData) => business.id === selectedBusinessId,
    );
    const formattedAddress = [
        selectedBusiness?.address_full,
        selectedBusiness?.city,
        selectedBusiness?.zipcode,
    ]
        .filter(f => f)
        .join(', ');

    useEffect(() => {
        if (!isLoading) setLoadedUsers(newLoadedUsers);
    }, [isLoading]);

    const [isMenuShown, setIsMenuShown] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [hasUsedSearch, setHasUsedSearch] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const menuListRef = useRef<HTMLDivElement | null>(null);

    const assignedUserId = activeConversation?.assigned_user_id;
    const [assignedUser, setAssignedUser] = useState<BusinessUser | null>(null);
    const showBusinesses =
        activeConversation.messaging_partner === MessagingPartnerEnum.WHATSAPP &&
        me?.role === ORG_ADMIN;

    useEffect(() => {
        const user = assignedUserId ? loadedUsers[assignedUserId] : null;
        setAssignedUser(user);
    }, [assignedUserId, isLoading]);

    const handleClickOutside = (event: Event) => {
        if (
            menuListRef.current &&
            !menuListRef.current.contains(event.target as Node) &&
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setSearchValue('');
            setIsMenuShown(false);
            setHasUsedSearch(false);
            setSelectedBusinessId(null);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside, menuListRef]);

    const { hasAssignConversationFeature } = useHasAssignConversationFeature();
    if (!hasAssignConversationFeature || activeConversation.is_expired) return null;

    const handleClick = () => {
        setIsMenuShown(!isMenuShown);
        setSelectedBusinessId(null);
        setHasUsedSearch(false);
        setSearchValue('');
    };

    const handleBackButton = () => {
        setSelectedBusinessId(null);
        setSearchValue('');
    };

    const handleChange = (val: string) => {
        setSearchValue(val);
        if (!hasUsedSearch) {
            dataLayer.pushDict('messaging_search_user_to_assign');
            setHasUsedSearch(true);
        }
    };

    const handleClickBusiness = (businessId: string) => {
        setSelectedBusinessId(businessId);
        setSearchValue('');
    };

    const maxHeight =
        debouncedWindowHeight && debouncedWindowHeight < HEIGHT_THRESHHOLD
            ? debouncedWindowHeight / 2
            : 400;

    const chevronIcon = isMenuShown
        ? FontAwesomeIconsPartooUsed.faChevronUp
        : FontAwesomeIconsPartooUsed.faChevronDown;

    return (
        <Container>
            <div ref={dropdownRef}>
                <Button
                    size="small"
                    dataTrackId={''} // Add your dataTrackId here
                    onClick={handleClick}
                    shape="cube"
                    variant="secondary"
                    appearance="outlined"
                    icon={[chevronIcon]}
                    iconPosition="right"
                    disabled={
                        activeConversation.messaging_partner !== MessagingPartnerEnum.WHATSAPP &&
                        isLoading
                    }
                >
                    <DisplayedLabel
                        isLoading={
                            activeConversation.messaging_partner !==
                                MessagingPartnerEnum.WHATSAPP && isLoading
                        }
                        isAssigned={!!assignedUserId}
                        assignedUser={assignedUserId === me?.id ? businessUserMe : assignedUser}
                        isAutomated={activeConversation.is_automated}
                    />
                </Button>
                {isMenuShown && (
                    <SearchMenuContainer ref={menuListRef} maxHeight={maxHeight}>
                        {selectedBusinessId && (
                            <BackButton onClick={handleBackButton}>
                                <Icon icon={['fa-arrow-left']}></Icon>
                                <TextWrapper>
                                    <Text variant="bodyMBold" oneLine>
                                        {selectedBusiness?.name}
                                    </Text>
                                    <Text variant="bodyMRegular" color="secondary" oneLine>
                                        {formattedAddress}
                                    </Text>
                                </TextWrapper>
                            </BackButton>
                        )}
                        <TextFieldContainer>
                            <TextInput
                                label={t('messaging_assign_cta_search_bar_message')}
                                dataTrackId=""
                                valueContainer={null}
                                value={searchValue}
                                onChange={handleChange}
                                autoFocus={true}
                            />
                        </TextFieldContainer>
                        <AssignConversationMenuList
                            users={users}
                            businesses={showBusinesses ? businesses : undefined}
                            searchValue={searchValue}
                            assignedUserId={assignedUserId}
                            setIsMenuShown={setIsMenuShown}
                            maxHeight={maxHeight - 90}
                            handleClickBusiness={handleClickBusiness}
                            isBusinessUsersMenuShown={!!selectedBusinessId}
                        />
                    </SearchMenuContainer>
                )}
            </div>
        </Container>
    );
};
