import React from 'react';

import { PartnerConnectionProvider } from 'app/businessConnection/components/PartnerConnectionContext';
import { PartnerConnectionsPage } from 'app/pages/settings/PartnerConnections';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';
import ConnectionFilters from 'app/pages/settingsV2/subPages/Platforms/components/Connection/sections/ConnectionFilters';
import ConnectionHeader from 'app/pages/settingsV2/subPages/Platforms/components/Connection/sections/ConnectionHeader';
import { MetaRelogin } from 'app/pages/visibility/subComponents/MetaRelogin/MetaRelogin';

export const Connection = () => {
    return (
        <PartnerConnectionProvider>
            <MenuListContentTemplate headerContent={<ConnectionHeader />}>
                <MenuListContentContainer>
                    <MetaRelogin />
                    <ConnectionFilters />
                    <PartnerConnectionsPage />
                </MenuListContentContainer>
            </MenuListContentTemplate>
        </PartnerConnectionProvider>
    );
};
