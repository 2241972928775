import { PropsWithChildren, ReactNode, useCallback, useState } from 'react';

import { Expandable, FontAwesomeIconsPartooUsed } from '@partoohub/ui';

import {
    DropdownFormSectionChevron,
    DropdownFormSectionContainer,
    DropdownFormSectionContent,
    DropdownFormSectionHeader,
} from './DropdownFormSection.styled';

interface DropdownFormSectionProps {
    header: ReactNode;
    canCollapse?: boolean;
}

export const DropdownFormSection = ({
    header,
    canCollapse = false,
    children,
}: PropsWithChildren<DropdownFormSectionProps>) => {
    const [isOpen, setIsOpen] = useState<boolean>(!canCollapse);

    const handleHeaderClick = useCallback(() => {
        if (canCollapse) {
            setIsOpen(!isOpen);
        }
    }, [canCollapse, setIsOpen, isOpen]);

    return (
        <DropdownFormSectionContainer gap="0px" direction="column">
            <DropdownFormSectionHeader
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="8px"
                canCollapse={canCollapse}
                onClick={handleHeaderClick}
            >
                {header}
                {canCollapse && (
                    <DropdownFormSectionChevron
                        opened={isOpen}
                        icon={[FontAwesomeIconsPartooUsed.faChevronDown]}
                        color="secondary"
                        iconSize="24px"
                    />
                )}
            </DropdownFormSectionHeader>
            <Expandable opened={isOpen}>
                <DropdownFormSectionContent gap="24px">{children}</DropdownFormSectionContent>
            </Expandable>
        </DropdownFormSectionContainer>
    );
};
