import styled from '@emotion/styled';
import { radius } from '@partoohub/branding';
import { TextInput } from '@partoohub/ui';

export const ColorPickerContainer = styled('div')`
    position: relative;
    cursor: pointer;
    pointer-events: auto !important;
`;

export const ColorPickerTextInput = styled(TextInput)`
    div[data-readonly] {
        background-color: ${({ theme }) => theme.colors.theme.background} !important;
    }
`;

// Using absolute positioning to make the picker open from the middle of the icon
export const HiddenColorPickerInput = styled('input')`
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 26px;
    padding: 0;
    border: none;
    cursor: none;

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    ::-webkit-color-swatch-wrapper {
        padding: 0;
    }

    ::-webkit-color-swatch {
        border: 0;
        border-radius: 0;
    }

    ::-moz-color-swatch,
    ::-moz-focus-inner {
        border: 0;
    }

    ::-moz-focus-inner {
        padding: 0;
    }
`;

interface ColoredDot {
    bgColor: string;
}

export const ColoredDot = styled.div<ColoredDot>`
    background-color: ${({ bgColor }) => bgColor};
    border-radius: ${radius.circle};
    height: 24px;
    width: 24px;
    padding: 2px;
`;
