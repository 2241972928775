import styled from '@emotion/styled';

export const BusinessAverageDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
`;

export const AverageTitle = styled.div`
    font-size: 18px;
    color: ${({ theme }) => theme.colors.default.initial};
    font-weight: bold;
`;

export const AverageSubtitle = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const BulkButtonContainer = styled.div`
    margin-top: 8px;
`;
