import LoadingView from 'app/common/components/LoadingView';
import { NoBusiness } from 'app/common/components/placeholder/NoBusiness';
import NotAccessibleForAdmin from 'app/common/components/placeholder/NotAccessibleForAdmin';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import { ADMIN } from 'app/common/data/roles';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import useMe from 'app/common/hooks/queries/useMeUncamel';

import { useIsLoadingMessaging } from 'app/pages/conversations/Messaging/hooks/useIsLoadingMessaging';
import Messaging from 'app/reviewManagement/messaging/Messaging';
import { MessagingWithNewSidebar } from 'app/reviewManagement/messaging/MessagingWithNewSidebar';

import { useConversationFiltersSetup } from './hooks/useConversationFiltersSetup';
import { MessagingProvider } from './MessagingContext';

export const MessagingWithContext = () => {
    const isLoading = useIsLoadingMessaging();
    const { data: me } = useMe();
    const { hasBusinesses, subscribedToBM } = useBusinessSubscriptions();
    const hasSetupConversationFilters = useConversationFiltersSetup();

    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const MessagingComponent = isNewSidebarEnabled ? MessagingWithNewSidebar : Messaging;

    if (isLoading || !hasSetupConversationFilters) {
        return <LoadingView />;
    }

    if (subscribedToBM && me?.role === ADMIN) {
        return <NotAccessibleForAdmin />;
    }

    if (!hasBusinesses) {
        return <NoBusiness />;
    }

    return (
        <MessagingProvider>
            <MessagingComponent />
        </MessagingProvider>
    );
};
