import { ProductIconsAppV2Enum } from '@partoohub/iconography';
import {
    MENU_ACTION_TYPE,
    MENU_ITEM_TYPE,
    MENU_PARTNER_LINK,
    MenuActionType,
    MenuItemType,
    MenuPartnerLinkType,
    RouteListType,
} from '@partoohub/modular-components';
import { useTranslation } from 'react-i18next';

import { openQuickAction } from 'app/addon/QuickAction/utils';
import { useAppDataContext } from 'app/common/contexts/appData';
import IS_IFRAME from 'app/common/data/iframe';
import {
    BUSINESS_MESSAGE,
    PRESENCE_MANAGEMENT,
    REVIEW_MANAGEMENT,
} from 'app/common/data/productIds';
import { PROVIDER } from 'app/common/data/roles';
import usePartnerLinks from 'app/common/hooks/queries/usePartnerLinks';
import {
    PARTOOROBOT_SIDEBAR_ANALYTICS,
    PARTOOROBOT_SIDEBAR_BUSINESSES,
    PARTOOROBOT_SIDEBAR_HOMEPAGE,
    PARTOOROBOT_SIDEBAR_MESSAGES,
    PARTOOROBOT_SIDEBAR_PARTNER_LINK,
    PARTOOROBOT_SIDEBAR_POSTS,
    PARTOOROBOT_SIDEBAR_QUICK_SEARCH,
    PARTOOROBOT_SIDEBAR_REVIEWS,
} from 'app/constants/robot';
import { useCanAccessCustomerExperiencePage } from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import { useHasHomepage } from 'app/pages/Homepage/hooks/useHasHomepage';
import { useConversationStats } from 'app/reviewManagement/messaging/hooks/useConversationStats';
import { useConversationUpdate } from 'app/reviewManagement/messaging/hooks/useConversationUpdate';
import { useNewReviewsCount } from 'app/reviewManagement/reviewList/hooks/useNewReviewsCount';

import { useReviewListWebsocket } from 'app/reviewManagement/reviewList/hooks/useReviewListWebsocket';
import {
    ANALYTICS_PATH,
    CONVERSATIONS_PATH,
    CUSTOMER_EXPERIENCE_PATH,
    HOMEPAGE_PATH,
    POSTS_PATH,
    VISIBILITY_PATH,
} from 'app/routing/routeIds';

const HOMEPAGE_DATATRACK_ID = 'homepage';
const VISIBILITY_DATATRACK_ID = 'visbility';
const POSTS_DATATRACK_ID = 'posts';
const CUSTOMER_EXPERIENCE_DATATRACK_ID = 'customer_experience';
const CONVERSATIONS_DATATRACK_ID = 'conversations';
const ANALYTICS_DATATRACK_ID = 'analytics';
const SEARCH_DATATRACK_ID = 'search';

const useGetHomepageMenu = (): MenuItemType => {
    const { t } = useTranslation();
    const { hasHomepage } = useHasHomepage();
    const displayHomepage = !IS_IFRAME && hasHomepage;

    return {
        dataTrackId: HOMEPAGE_DATATRACK_ID,
        dataPartooRobot: PARTOOROBOT_SIDEBAR_HOMEPAGE,
        type: MENU_ITEM_TYPE,
        name: t('homepage_title'),
        icon: ProductIconsAppV2Enum.Homepage,
        to: HOMEPAGE_PATH,
        display: displayHomepage,
    };
};

const useGetVisibilityMenu = (): MenuItemType => {
    const { t } = useTranslation();
    return {
        dataTrackId: VISIBILITY_DATATRACK_ID,
        dataPartooRobot: PARTOOROBOT_SIDEBAR_BUSINESSES,
        type: MENU_ITEM_TYPE,
        name: t('businesses'),
        icon: ProductIconsAppV2Enum.Locations,
        to: VISIBILITY_PATH,
    };
};

const useGetPostsMenu = (): MenuItemType => {
    const { t } = useTranslation();
    const { me } = useAppDataContext();
    const displayPosts = !me?.sidebar_excluded_pages.includes('google_post');

    return {
        dataTrackId: POSTS_DATATRACK_ID,
        dataPartooRobot: PARTOOROBOT_SIDEBAR_POSTS,
        type: MENU_ITEM_TYPE,
        name: t('google_posts_title'),
        icon: ProductIconsAppV2Enum.Posts,
        to: POSTS_PATH,
        display: displayPosts,
    };
};

const useGetCustomerExperienceMenu = (): MenuItemType => {
    const { t } = useTranslation();
    const { me } = useAppDataContext();
    const sidebarProducts: Array<string> = me?.sidebar_products ?? [];
    const sidebarPartooProducts: Array<string> = sidebarProducts.map(s => s.toUpperCase());
    useReviewListWebsocket();
    const { canAccessCustomerExperiencePage } = useCanAccessCustomerExperiencePage();
    const newReviewsCount = useNewReviewsCount();

    const notificationCount = sidebarPartooProducts.includes(REVIEW_MANAGEMENT)
        ? newReviewsCount
        : 0;

    const displayCustomerExperience = Object.values(canAccessCustomerExperiencePage).some(Boolean);

    return {
        dataTrackId: CUSTOMER_EXPERIENCE_DATATRACK_ID,
        dataPartooRobot: PARTOOROBOT_SIDEBAR_REVIEWS,
        type: MENU_ITEM_TYPE,
        name: t('reviews'),
        icon: ProductIconsAppV2Enum.Reviews,
        to: CUSTOMER_EXPERIENCE_PATH,
        position: 'top',
        notification: notificationCount,
        display: displayCustomerExperience,
    };
};

const useGetConversationsMenu = (): MenuItemType => {
    const { t } = useTranslation();
    const { me } = useAppDataContext();
    const sidebarProducts: Array<string> = me?.sidebar_products ?? [];
    const sidebarPartooProducts: Array<string> = sidebarProducts.map(s => s.toUpperCase());
    useConversationUpdate();
    const { data: conversationStats } = useConversationStats();
    const notificationCount = conversationStats ? conversationStats.unread_open : 0;

    const displayMessaging =
        sidebarPartooProducts.includes(BUSINESS_MESSAGE) && me.role !== PROVIDER;

    return {
        dataTrackId: CONVERSATIONS_DATATRACK_ID,
        dataPartooRobot: PARTOOROBOT_SIDEBAR_MESSAGES,
        type: MENU_ITEM_TYPE,
        name: t('sidebar_title_conversations'),
        icon: ProductIconsAppV2Enum.Messages,
        to: CONVERSATIONS_PATH,
        notification: notificationCount,
        display: displayMessaging,
    };
};

const useGetAnalyticsMenu = (): MenuItemType => {
    const { t } = useTranslation();
    const { me } = useAppDataContext();
    const sidebarProducts: Array<string> = me?.sidebar_products ?? [];
    const sidebarPartooProducts: Array<string> = sidebarProducts.map(s => s.toUpperCase());

    const displayAnalytics = [PRESENCE_MANAGEMENT, REVIEW_MANAGEMENT].some(element =>
        sidebarPartooProducts.includes(element),
    );

    return {
        dataTrackId: ANALYTICS_DATATRACK_ID,
        dataPartooRobot: PARTOOROBOT_SIDEBAR_ANALYTICS,
        type: MENU_ITEM_TYPE,
        name: t('analytics'),
        icon: ProductIconsAppV2Enum.Analytics,
        to: ANALYTICS_PATH,
        display: displayAnalytics,
    };
};

const useGetSearchMenu = (): MenuActionType => {
    const { t } = useTranslation();

    return {
        dataTrackId: SEARCH_DATATRACK_ID,
        dataPartooRobot: PARTOOROBOT_SIDEBAR_QUICK_SEARCH,
        type: MENU_ACTION_TYPE,
        name: t('quick_action__sidebar_button__tooltip'),
        icon: ProductIconsAppV2Enum.Search,
        position: 'bottom',
        callback: () => openQuickAction(),
    };
};

const useGetPartnerLinkMenus = (): MenuPartnerLinkType[] => {
    const { data: partnerLinks } = usePartnerLinks();

    return (
        partnerLinks?.partner_links.map(partnerLink => ({
            dataTrackId: `partner_link__${partnerLink.name}`,
            dataPartooRobot: PARTOOROBOT_SIDEBAR_PARTNER_LINK,
            type: MENU_PARTNER_LINK,
            name: partnerLink.name,
            logoUrl: partnerLink.logo_url,
            href: partnerLink.link_url,
        })) ?? []
    );
};

export const useGetSidebarMenus = (): RouteListType => {
    return [
        useGetHomepageMenu(),
        useGetVisibilityMenu(),
        useGetPostsMenu(),
        useGetCustomerExperienceMenu(),
        useGetConversationsMenu(),
        useGetAnalyticsMenu(),
        useGetSearchMenu(),
        ...useGetPartnerLinkMenus(),
    ];
};
