import { Stack } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { BusinessReachEnum } from 'app/api/types/business';
import CTAButtons from 'app/businessEditV2/components/BusinessEditV2Header/CTAButtons';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useBusinessStatus } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import { OPEN } from 'app/common/data/businessStatuses';
import IS_IFRAME from 'app/common/data/iframe';

import { DiffusionListButton } from 'app/pages/visibility/BusinessListV2/sections/BusinessListHeader/HeaderButtons/Buttons/DiffusionListButton';
import { PageHeaderWithAvatar } from 'app/routing/components/PageHeaderWithAvatar';

export const BusinessEditV2PageHeader = () => {
    const { t } = useTranslation();

    const { data: business, isLoading } = useBusiness();
    const isOpen = useBusinessStatus() === OPEN;

    const buttons =
        !isLoading && isOpen
            ? [
                  <Stack
                      gap="8px"
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      key="cta_buttons"
                  >
                      {business?.reach !== BusinessReachEnum.GLOBAL && <CTAButtons />}
                      {!IS_IFRAME && <DiffusionListButton />}
                  </Stack>,
              ]
            : [];

    return <PageHeaderWithAvatar title={t('business_edit_my_business')} actions={buttons} />;
};
