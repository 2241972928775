import { ReactNode } from 'react';

import { Text } from '@partoohub/ui';
import { Interweave } from 'interweave';
import { useTranslation } from 'react-i18next';

import { LiteralRating } from 'app/api/types/review';
import { FormattedReplyTemplate } from 'app/api/types/reviewReplyTemplates';
import NegativeIcon from 'app/common/components/icons/NegativeIcon';
import NeutralIcon from 'app/common/components/icons/NeutralIcon';
import PositiveIcon from 'app/common/components/icons/PositiveIcon';
import { useAppDataContext } from 'app/common/contexts/appData';
import FLAG from 'app/common/flags.json';
import { LANGUAGES_MAP } from 'app/common/services/languages';

import { STATIC_BASE_URL } from 'app/config';
import { useSettingsPageContext } from 'app/pages/settings/AutoReply/hooks/useSettingsPageContext';

import {
    FlagContainer,
    ReplyTemplateHeader,
    StyledBadge,
    StyledBadges,
    StyledCard,
    StyledCheckbox,
    StyledIcon,
    StyledPartooLogo,
    StyledTitle,
} from './ReplyTemplateCard.styled';

type Props = {
    template: FormattedReplyTemplate;
    textMatchers: Array<any>;
};

const ratingStyles: Record<LiteralRating, { color: string; icon: ReactNode }> = {
    positive: { color: '#40dba2', icon: <PositiveIcon /> },
    neutral: { color: '#ff9014', icon: <NeutralIcon /> },
    negative: { color: '#ff426e', icon: <NegativeIcon /> },
};

const ReplyTemplateCard = ({ template, textMatchers }: Props) => {
    const { id, language, title, text, ratings, orgId } = template;
    const { t } = useTranslation();
    const { me } = useAppDataContext();
    const providerLabel = me?.provider;
    const { selectedTemplates, onTemplatesSelect } = useSettingsPageContext();
    const selected = !!selectedTemplates.find(template => template.id === id);

    return (
        <StyledCard onClick={() => onTemplatesSelect(!selected, template)} selected={selected}>
            <ReplyTemplateHeader>
                <StyledBadges>
                    {ratings.map(rating => {
                        const { color, icon } = ratingStyles[rating];
                        return (
                            <StyledBadge key={rating}>
                                <StyledIcon>{icon}</StyledIcon>
                                <Text variant="bodySSemibold" as="span" color={color}>
                                    {t(rating)}
                                </Text>
                            </StyledBadge>
                        );
                    })}
                    {!orgId && (
                        <StyledBadge>
                            {providerLabel === 'partoo' && (
                                <StyledPartooLogo
                                    src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/partoo.svg`}
                                    alt="partoo"
                                />
                            )}
                            <Text variant="bodySSemibold" as="span" color={'secondary'}>
                                {t('auto_reply_suggested_template')}
                            </Text>
                        </StyledBadge>
                    )}
                </StyledBadges>
                <StyledCheckbox type="checkbox" checked={selected} readOnly />
            </ReplyTemplateHeader>
            <StyledTitle>
                <FlagContainer>
                    <img src={FLAG[LANGUAGES_MAP[language]?.country]} alt={language} />
                </FlagContainer>
                <Text variant="heading6">{title}</Text>
            </StyledTitle>

            <Text color={'secondary'}>
                <Interweave content={text} matchers={textMatchers} />
            </Text>
        </StyledCard>
    );
};

export default ReplyTemplateCard;
