import { useSelector } from 'react-redux';

import { useAppDataContext } from 'app/common/contexts/appData';
import { orgsSelector } from 'app/states/reducers';

export default function useHasReviewTagFeature(): boolean {
    const { me: meAppData } = useAppDataContext();
    const orgId = meAppData?.org_id;
    const orgs = useSelector(orgsSelector);

    return Boolean(orgId && orgs.byId[orgId] && orgs.byId[orgId].hasReviewTag);
}
