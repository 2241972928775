import { PropsWithChildren, ReactNode } from 'react';

import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';

import {
    MenuListContent,
    MenuListContentFooter,
    MenuListContentHeader,
    MenuListContentTemplateContainer,
} from './MenuListContentTemplate.styled';

interface MenuListContentTemplateProps {
    headerContent?: ReactNode;
    footerContent?: ReactNode;
    withoutPadding?: boolean;
}

export const MenuListContentTemplate = ({
    headerContent,
    children,
    footerContent,
    withoutPadding = false,
}: PropsWithChildren<MenuListContentTemplateProps>) => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);

    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);

    return (
        <MenuListContentTemplateContainer>
            {headerContent && <MenuListContentHeader>{headerContent}</MenuListContentHeader>}
            <MenuListContent withoutPadding={withoutPadding}>{children}</MenuListContent>
            {footerContent && (
                <MenuListContentFooter
                    isBelowProvidedDevices={isBelowProvidedDevices}
                    isNewSidebarEnabled={isNewSidebarEnabled}
                >
                    {footerContent}
                </MenuListContentFooter>
            )}
        </MenuListContentTemplateContainer>
    );
};
