import { FC, useCallback } from 'react';

import { IconButton, Stack, Text, theme } from '@partoohub/ui';
import { UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import messengerLogoUrl from 'app/assets/partooChat/messenger_logo.svg';
import smsLogoUrl from 'app/assets/partooChat/sms_logo.svg';
import whatsappLogoUrl from 'app/assets/partooChat/whatsapp_logo.svg';

import { SelectableCard } from 'app/common/components/SelectableCard';
import {
    CenteredText,
    IconContainer,
    InfoBubbleContainer,
} from 'app/common/components/SelectableCard/SelectableCard.styled';

import { ActiveChannelsFormValues, WidgetSetupFormValues } from '../../../WidgetSetup';

const validateChannels = (active_channels: ActiveChannelsFormValues) => {
    return active_channels.sms || active_channels.whatsapp || active_channels.messenger;
};

export const ChannelsSelector: FC<UseControllerProps<WidgetSetupFormValues>> = ({ control }) => {
    const {
        field: { onChange, value: activeChannels },
        fieldState: { error },
    } = useController({
        name: 'active_channels',
        control,
        rules: { validate: validateChannels },
    });

    const { t } = useTranslation();
    const canSetupSms = true;

    const handleSmsClick = useCallback(
        (isCardSelected: boolean) => {
            onChange({ ...activeChannels, sms: isCardSelected });
        },
        [onChange, activeChannels],
    );

    const handleWhatsappClick = useCallback(
        (isCardSelected: boolean) => {
            onChange({ ...activeChannels, whatsapp: isCardSelected });
        },
        [onChange, activeChannels],
    );

    const handleMessengerClick = useCallback(
        (isCardSelected: boolean) => {
            onChange({ ...activeChannels, messenger: isCardSelected });
        },
        [onChange, activeChannels],
    );

    return (
        <Stack direction="column" gap="16px">
            <Stack direction="row" gap="16px">
                <SelectableCard
                    isSelected={activeChannels.sms}
                    onChange={handleSmsClick}
                    disabled={!canSetupSms}
                >
                    <IconContainer as="div">
                        <img src={smsLogoUrl} />
                    </IconContainer>
                    <CenteredText variant="bodyLBold">
                        {t('widget_setup_channels_sms_title')}
                    </CenteredText>
                    <CenteredText color="secondary">
                        {t('widget_setup_channels_sms_description')}
                    </CenteredText>
                    {!canSetupSms && (
                        <InfoBubbleContainer isSelected={activeChannels.sms}>
                            <IconButton
                                dataTrackId="widget_setup__channel_sms_info_bubble"
                                icon={['fa-circle-info']}
                                appearance="outlined"
                                size="xsmall"
                                tooltip={t('widget_setup_channels_sms_error')}
                            />
                        </InfoBubbleContainer>
                    )}
                </SelectableCard>
                <SelectableCard isSelected={activeChannels.whatsapp} onChange={handleWhatsappClick}>
                    <IconContainer as="div">
                        <img src={whatsappLogoUrl} />
                    </IconContainer>
                    <CenteredText variant="bodyLBold">
                        {t('widget_setup_channels_whatsapp_title')}
                    </CenteredText>
                    <CenteredText color="secondary">
                        {t('widget_setup_channels_whatsapp_description')}
                    </CenteredText>
                </SelectableCard>
                <SelectableCard
                    isSelected={activeChannels.messenger}
                    onChange={handleMessengerClick}
                >
                    <IconContainer as="div">
                        <img src={messengerLogoUrl} />
                    </IconContainer>
                    <CenteredText variant="bodyLBold">
                        {t('widget_setup_channels_messenger_title')}
                    </CenteredText>
                    <CenteredText color="secondary">
                        {t('widget_setup_channels_messenger_description')}
                    </CenteredText>
                </SelectableCard>
            </Stack>
            {error && (
                <Text color={theme.colors.danger.initial}>{t('widget_setup_channels_error')}</Text>
            )}
        </Stack>
    );
};
