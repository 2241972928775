import { useMemo, useState } from 'react';

import {
    FontAwesomeIconsPartooUsed,
    Icon,
    LeftElementType,
    Stack,
    Text,
    TextInput,
    Tooltip,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { extractDomainFromUrl } from './utils';
import { WidgetDomainButton, WidgetDomainTrashIcon } from './WidgetDomainSection.styled';
import { DropdownFormSection } from '../../DropdownFormSection';

export interface WidgetDomainSectionProps {
    domains: string[];
    onChange: (domains: string[]) => void;
}

const DOMAIN_FORM_STEPS = {
    ADD: 'ADD',
    VALIDATE: 'VALIDATE',
};

export const WidgetDomainSection = ({ domains, onChange }: WidgetDomainSectionProps) => {
    const { t } = useTranslation();

    const [formStep, setFormStep] = useState(DOMAIN_FORM_STEPS.ADD);
    const [currentUrl, setCurrentUrl] = useState<string | null>(null);

    const isCurrentUrlValid = useMemo(() => !!extractDomainFromUrl(currentUrl ?? ''), [currentUrl]);

    const handleAddWebsiteButtonClick = () => {
        setCurrentUrl('');
        setFormStep(DOMAIN_FORM_STEPS.VALIDATE);
    };

    const handleValidateWebsiteButtonClick = () => {
        const domain = extractDomainFromUrl(currentUrl ?? '');

        if (!domain) return;

        onChange([...domains, domain]);
        setCurrentUrl(null);
        setFormStep(DOMAIN_FORM_STEPS.ADD);
    };

    const handleDeleteWebsiteButtonClick = (index: number) => {
        onChange(domains.filter((_, i) => i !== index));
    };

    const handleDeleteCurrentUrlButtonClick = () => {
        setCurrentUrl(null);
        setFormStep(DOMAIN_FORM_STEPS.ADD);
    };

    const linkIcon = (
        <Icon
            dataTrackId="settings_messages_widget_setup__domain_link"
            icon={[FontAwesomeIconsPartooUsed.faLink]}
            color="secondary"
        />
    );

    return (
        <DropdownFormSection
            canCollapse
            header={
                <Stack gap="8px" direction="row" alignItems="center">
                    <Text variant="heading6">{t('widget_setup_domain_section_title')}</Text>
                    <Tooltip text={t('widget_setup_domain_section_tooltip')}>
                        <Icon
                            icon={[FontAwesomeIconsPartooUsed.faCircleInfo]}
                            iconSize="16px"
                            color="secondary"
                        />
                    </Tooltip>
                </Stack>
            }
        >
            <Stack gap="16px" direction="column">
                {domains.map((domain, index) => (
                    <Stack gap="8px" direction="row" alignItems="center" key={`${index}-${domain}`}>
                        <TextInput
                            dataTrackId="settings_messages_widget_setup__domain_textfield"
                            value={domain}
                            readOnly
                            label={t('widget_setup_website_input_label')}
                            leftElement={linkIcon}
                            leftElementType={LeftElementType.Icon}
                        />
                        <WidgetDomainTrashIcon
                            dataTrackId="settings_messages_widget_setup__domain_trash"
                            type="button"
                            icon={[FontAwesomeIconsPartooUsed.faTrash]}
                            variant="danger"
                            appearance="outlined"
                            onClick={() => handleDeleteWebsiteButtonClick(index)}
                        />
                    </Stack>
                ))}
                {typeof currentUrl === 'string' && (
                    <Stack gap="8px" direction="row" alignItems="center">
                        <TextInput
                            dataTrackId="settings_messages_widget_setup__website_input"
                            value={currentUrl}
                            onChange={setCurrentUrl}
                            error={currentUrl.length > 0 && !isCurrentUrlValid}
                            label={t('widget_setup_website_input_label')}
                            placeholder={t('widget_setup_website_input_placeholder')}
                            leftElement={linkIcon}
                            leftElementType={LeftElementType.Icon}
                        />
                        <WidgetDomainTrashIcon
                            dataTrackId="settings_messages_widget_setup__domain_trash"
                            type="button"
                            icon={[FontAwesomeIconsPartooUsed.faTrash]}
                            variant="danger"
                            appearance="outlined"
                            onClick={handleDeleteCurrentUrlButtonClick}
                        />
                    </Stack>
                )}
                {formStep === DOMAIN_FORM_STEPS.ADD && (
                    <WidgetDomainButton
                        dataTrackId="settings_messages_widget_setup__add_website_button"
                        variant="secondary"
                        appearance="outlined"
                        onClick={handleAddWebsiteButtonClick}
                        icon={[FontAwesomeIconsPartooUsed.faPlus]}
                        iconPosition="left"
                    >
                        {t('widget_setup_add_website_button')}
                    </WidgetDomainButton>
                )}
                {formStep === DOMAIN_FORM_STEPS.VALIDATE && (
                    <WidgetDomainButton
                        dataTrackId="settings_messages_widget_setup__validate_website_button"
                        variant="primary"
                        appearance="outlined"
                        onClick={handleValidateWebsiteButtonClick}
                        disabled={!isCurrentUrlValid}
                    >
                        {t('widget_setup_validate_website_button')}
                    </WidgetDomainButton>
                )}
            </Stack>
        </DropdownFormSection>
    );
};
