import { Switch, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { FEATURE_ENUM, PRODUCT_ENUM } from 'app/api/types/plans_features';
import useSidebarPages from 'app/pages/settingsV2/subPages/Team/components/UserManagement/hooks/useSidebarPages';
import { formatSidebarFeatures } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/utils';

import {
    FeaturesContainer,
    ProductContainer,
    UserSidebarFeaturesContainer,
} from './UserSidebarFeatures.styled';

const getFeatureKey = (feature: FEATURE_ENUM): string => {
    return `user_sidebar_feature__${feature}`;
};

const getProductKey = (product: PRODUCT_ENUM): string => {
    return `user_sidebar_product__${product}`;
};

type Props = {
    value: Array<FEATURE_ENUM>;
    onChange: (value: Array<FEATURE_ENUM>) => void;
    selectedOrgId: number | undefined;
};

export const UserSidebarFeatures = ({ value, onChange, selectedOrgId }: Props) => {
    const { t } = useTranslation();

    const { data: sidebarPages } = useSidebarPages(selectedOrgId ?? 0, {
        enabled: !!selectedOrgId,
    });

    const featuresByProduct = formatSidebarFeatures(sidebarPages?.sidebar_pages ?? []);

    const onToggle = (feature: FEATURE_ENUM) => {
        if (value.includes(feature)) {
            onChange(value.filter(item => item !== feature));
        } else {
            onChange([...value, feature]);
        }
    };

    return (
        <UserSidebarFeaturesContainer>
            {featuresByProduct.map(({ product, features }) => (
                <ProductContainer key={product}>
                    <Text variant="bodyMBold">{t(getProductKey(product))}</Text>

                    <FeaturesContainer>
                        {features.map(feature => (
                            <Switch
                                key={feature}
                                checked={value.includes(feature)}
                                dataTrackId={getFeatureKey(feature)}
                                name={getFeatureKey(feature)}
                                onChange={() => onToggle(feature)}
                                label={
                                    <Text variant="bodyMSemibold">{t(getFeatureKey(feature))}</Text>
                                }
                            />
                        ))}
                    </FeaturesContainer>
                </ProductContainer>
            ))}
        </UserSidebarFeaturesContainer>
    );
};
