import { useTranslation } from 'react-i18next';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { PageHeaderWithAvatar } from 'app/routing/components/PageHeaderWithAvatar';

export const HomepagePageHeader = () => {
    const { t } = useTranslation();

    const { data: me } = useMe();
    const title = me?.first_name
        ? t('homepage_greeting_with_name', {
              name: me?.first_name,
          })
        : t('homepage_greeting');

    return <PageHeaderWithAvatar title={title} description={t('homepage_header_description')} />;
};
