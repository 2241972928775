export type KeywordType = {
    keyword: string;
    dates: Date[];
};

export type CompetitiveKeywords = {
    available_keywords: KeywordType[];
};

export type CompetitiveOverviewBusinessData = {
    rank: number;
    averageReviews: number;
    averageRating: number;
};

export type CompetitiveOverviewBusiness = {
    name: string;
    data: CompetitiveOverviewBusinessData[];
};

export type CompetitiveOverview = {
    dates: Date[];
    businesses: CompetitiveOverviewBusiness[];
};

export type OverviewType = keyof CompetitiveOverviewBusinessData;

export type CompetitorRankRawData = {
    name: string;
    highlight: boolean;
    apparition_percentage: number;
    rank: number;
    average_rating: number;
    average_reviews: number;
    business_count: number;
};

export type CompetitorLocalRankRawData = Omit<
    CompetitorRankRawData,
    'apparition_percentage' | 'average_rating' | 'average_reviews'
> & {
    review_count: number;
    rating: number;
    address: string;
};

export type CompetitorCentralRank = {
    name: string;
    highlight: boolean;
    apparitionPercentage: number;
    rank?: number;
    rating: number;
    reviewCount: number;
    businessCount: number;
};

export type CompetitorLocalRank = Omit<CompetitorCentralRank, 'apparitionPercentage'> & {
    address: string;
};

export type CompetitorRank = CompetitorCentralRank | CompetitorLocalRank;

export enum ExportSelectionType {
    ALL = 'all',
    BUSINESSES = 'businesses',
    ORG = 'org',
}

export type ExportPayload = {
    email: string;
    type: ExportSelectionType;
    monthDate: string;
};
