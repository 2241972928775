import { Stack, Table, Text } from '@partoohub/ui';

import {
    CompetitorCell,
    SubLabelProps,
} from 'app/pages/analytics/CompetitiveBenchmark/components/CompetitorTableSection/CompetitorCell/CompetitorCell';
import { CompetitorRankCell } from 'app/pages/analytics/CompetitiveBenchmark/components/CompetitorTableSection/CompetitorRankCell/CompetitorRankCell';
import { StyledRow } from 'app/pages/analytics/CompetitiveBenchmark/components/CompetitorTableSection/CompetitorRow/CompetitorRow.styled';

import { getRatingColor } from '../../../utils/getRatingColor';

export type RowValues = {
    reviews: number;
    rating: number;
    rank?: number;
};

export type RowOptions = RowValues & {
    name: string;
    subLabels?: SubLabelProps[];
    highlight?: boolean;
    previousRank?: number;
    color?: string;
    appearance?: number;
};

type Props = {
    options: RowOptions;
};

export const CompetitorRow = ({ options }: Props) => {
    const variant = options.highlight ? 'bodyLBold' : 'bodyLRegular';
    const ratingColor = getRatingColor(options.rating);

    return (
        <StyledRow
            highlight={options.highlight ?? false}
            id={`row-${options.name}`}
            dataTrackId={`row-${options.name}`}
        >
            <CompetitorCell
                color={options.color}
                name={options.name}
                subLabels={options.subLabels}
            />
            {options.appearance !== undefined && (
                <Table.Cell>
                    <Stack direction="row" justifyContent="center">
                        <Text variant={variant} color="secondary" as={'span'}>
                            {`${options.appearance}%`}
                        </Text>
                    </Stack>
                </Table.Cell>
            )}
            <CompetitorRankCell
                isHighlighted={options.highlight ?? false}
                rank={options.rank}
                previousRank={options.previousRank}
            />
            <Table.Cell>
                <Stack direction="row" justifyContent="center">
                    <Text variant={variant} color="secondary" as={'span'}>
                        {options.reviews || '-'}
                    </Text>
                </Stack>
            </Table.Cell>
            <Table.Cell>
                <Stack direction="row" justifyContent="center">
                    <Text variant={variant} color={ratingColor} as={'span'}>
                        {(options.rating && options.rating.toFixed(1)) || '-'}
                    </Text>
                </Stack>
            </Table.Cell>
        </StyledRow>
    );
};
