import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';
import { Link } from 'react-router-dom';

export const StyledButton = styled(Link)`
    border-radius: 16px;
    background-color: white;
    padding: 32px 48px 32px 48px;
    flex-basis: 340px;
    box-shadow: ${({ theme }) => theme.shadows.initial};

    :hover {
        transform: translateY(-10px);
        box-shadow: ${({ theme }) => theme.shadows.hover};
    }
`;

export const TwoButtonsWrapper = styled(Stack)`
    width: 704px;
`;

export const ButtonContent = styled(Stack)`
    text-align: center;
`;
