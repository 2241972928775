import { useMutation } from 'react-query';

import {
    CreateWidgetSetupResponse,
    MessagingWidgetSetup,
    UpdateWidgetSetupData,
} from 'app/api/v2/api_calls/messagingWidgetSetupApiCalls';

export interface WidgetSetupUpdateVariables {
    id: string;
    data: UpdateWidgetSetupData;
}

export const useWidgetSetupUpdate = () => {
    return useMutation<CreateWidgetSetupResponse, any, WidgetSetupUpdateVariables>(({ id, data }) =>
        MessagingWidgetSetup.updateWidgetSetup(id, data),
    );
};
