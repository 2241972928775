import styled from '@emotion/styled';
import { SelectableCard } from '@partoohub/ui';

export const OverviewSelectionContainer = styled.div`
    height: fit-content;
    position: relative;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.theme.background};
    padding: 16px;
`;

export const OverviewMenuOption = styled(SelectableCard)`
    padding-left: 8px;
`;
