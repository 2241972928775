import { AvatarMenuItem } from '@partoohub/modular-components';
import { Icon, Switch, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { usePartooThemeContext } from 'app/Theme';

import { IconContainer } from './AvatarSwitchTheme.styled';

export const AvatarSwitchThemeItem = () => {
    const { t } = useTranslation();
    const { theme, toggleTheme } = usePartooThemeContext();
    const isDark = theme === 'dark';
    return (
        <AvatarMenuItem callback={toggleTheme}>
            <IconContainer>
                <Icon icon={['fa-moon-stars']} color="default" />
            </IconContainer>
            <Text variant="bodyMMedium" oneLine>
                {t('avatar_dark_theme_label')}
            </Text>
            <Switch
                dataTrackId="switch_theme"
                name="switch_theme_toggle_box"
                onChange={toggleTheme}
                checked={isDark}
                activeBackgroundColor={isDark ? 'default' : 'theme'}
                inactiveBackgroundColor={isDark ? 'theme' : 'default'}
                pillColor="theme"
                size="xsmall"
            />
        </AvatarMenuItem>
    );
};
