import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COLLAPSED_SIDEBAR_WIDTH } from '@partoohub/modular-components';

import { GetMaxWidthContentPage } from 'app/global.styled';

export const GAP = 24;
export const LAYOUT_X_PADDING = 40;
export const LAYOUT_Y_PADDING = 32;

// TODO Tricia: update conversations layout to have this padding: 32px 40px; instead
// Do not remove the conditional height since it would break the messaging page
// Which is not on the new layout
export const OffsetContainer = styled.div<{ isBelowTablet: boolean; isMessagingPage: boolean }>`
    margin-left: ${({ isBelowTablet }) => (isBelowTablet ? '0px' : `${COLLAPSED_SIDEBAR_WIDTH}px`)};
    ${({ isMessagingPage }) =>
        `padding: ${isMessagingPage ? '40px' : `${LAYOUT_Y_PADDING}px ${LAYOUT_X_PADDING}px`};`}

    ${({ isMessagingPage }) =>
        isMessagingPage &&
        css`
            padding: 40px;
            height: 100%;
        `})
`;

export const OutletContainer = styled.div<{ isMessagingPage: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;

    margin: auto;
    ${({ isMessagingPage }) =>
        !isMessagingPage &&
        css`
            ${GetMaxWidthContentPage}
        `}
`;
