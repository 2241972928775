import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DefaultShortcutComponent } from 'app/pages/Homepage/sections/HomepageShortcuts/DefaultShortcutComponent';
import { SETTINGS_TEAM_USER_ADD_PATHNAME } from 'app/routing/routeIds';

export const ShortcutCreateUser = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <DefaultShortcutComponent
            title={t('homepage_shortcut_create_user')}
            variant="ratings"
            iconElement={[FontAwesomeIconsPartooUsed.faUser, IconPrefix.SOLID]}
            onClick={() => navigate(SETTINGS_TEAM_USER_ADD_PATHNAME)}
            dataTrack="click_homepage_shortcut_users"
        />
    );
};
