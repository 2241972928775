// ROOT LEVEL
import addSearchFilterSaga from 'app/common/components/businessModalDeprecated/sagas/addSearchFilter';
import loadInitialFilterDataSaga from 'app/common/components/businessModalDeprecated/sagas/loadInitialFilterData';
import businessEditFieldsSaga from 'app/common/sagas/businessEditFieldsSaga';
import fuzzySearchBusinessesSaga from 'app/common/sagas/fuzzySearchBusinesses';
import getAllCitiesSaga from 'app/common/sagas/getAllCities';
import getGroupSaga from 'app/common/sagas/getGroupSaga';
import { forceGetMeSaga } from 'app/common/sagas/getMeSaga';
import getOrgSaga from 'app/common/sagas/getOrgSaga';
import groupFilterSaga from 'app/common/sagas/groupFilterSaga';
import searchGroupsByOrgIdSaga from 'app/common/sagas/searchGroupsByOrgIdSaga';
import searchOrgsSaga from 'app/common/sagas/searchOrgsSaga';

export const sharedSagas = [
    getGroupSaga,
    fuzzySearchBusinessesSaga, // Settings sagas
    // Knowledge sagas
    businessEditFieldsSaga,
    getAllCitiesSaga,
    loadInitialFilterDataSaga,
    addSearchFilterSaga,
    groupFilterSaga,
    getOrgSaga, // Team sagas
    searchGroupsByOrgIdSaga,
    searchOrgsSaga, // Reviews saga

    // Only to update me/org after an update of user
    // Should be removed when all other pages are not using sagas anymore !
    forceGetMeSaga,
];
