import { Dispatch } from 'redux';

import { V2UserData } from 'app/api/types/user';
import IS_IFRAME from 'app/common/data/iframe';
import { showPushNotificationsOptInPrompt } from 'app/states/pushNotifs/actions';

import PushNotificationsBrowserState from './browserState';
import isSupportedBrowser from './isSupportedBrowser';
import { NotificationPermissionType } from './permissionTypes';
import { PushNotificationsWrapper } from './pushNotificationsWrapper';

const MIN_TIME_BETWEEN_PROMPTS = 60 * 24 * 60 * 60 * 1000; // 60 days in ms

export const maybeDisplayPushNotificationOptInPrompt = (
    user: V2UserData,
    dispatch: Dispatch<any>,
) => {
    if (isUserElligibleForPushNotifications(user) && canUserDisplayOptInPrompt(user)) {
        dispatch(showPushNotificationsOptInPrompt());
    }
};

const isUserElligibleForPushNotifications = (user: V2UserData) => {
    if (user.shadow_user_id) {
        console.warn('[PushNotifs] Not showing opt-in prompt because the user is a shadow user');
        return false;
    }

    // Test if inside an iframe.
    if (IS_IFRAME) {
        console.warn('[PushNotifs] Not showing opt-in prompt because we are in an iframe');
        return false;
    }

    // Test if browser is supported.
    if (!isSupportedBrowser()) {
        console.warn('[PushNotifs] Not showing opt-in prompt because the browser is not supported');
        return false;
    }

    return true;
};

const canUserDisplayOptInPrompt = (user: V2UserData) => {
    // Test if already accepted push notifications, or denied. In other words,
    // check if optInState is DEFAULT.
    const optInState = PushNotificationsWrapper.getOptIn();
    if (optInState !== NotificationPermissionType.DEFAULT) {
        console.warn(
            '[PushNotifs] Not showing opt-in prompt because the user already accepted or denied push notifications',
        );
        return false;
    }

    // Test if we have already asked and user denied within last 60 days
    const browserStateInstance: PushNotificationsBrowserState =
        PushNotificationsBrowserState.getInstance();
    const lastPrompts = browserStateInstance.getPrompts();
    const lastPrompt = lastPrompts[user.id];

    if (lastPrompt && Date.now() - lastPrompt < MIN_TIME_BETWEEN_PROMPTS) {
        console.warn(
            '[PushNotifs] Not showing opt-in prompt because the user already refused custom prompt within last 60 days',
        );
        return false;
    }

    // Add a checkpoint for later.
    browserStateInstance.addPrompt({ [user.id]: Date.now() });

    return true;
};
