import { FC } from 'react';

import { UseControllerProps, useController } from 'react-hook-form';

import { WidgetSetupIcon } from 'app/api/v2/api_calls/messagingWidgetSetupApiCalls';
import iconBubble from 'app/assets/partooChat/widget_setup_icon_bubble.svg';
import iconLogo from 'app/assets/partooChat/widget_setup_icon_logo.svg';
import iconQuestionMark from 'app/assets/partooChat/widget_setup_icon_question_mark.svg';
import { RadioCardList } from 'app/common/components/RadioCardList/index';

import { WidgetSetupFormValues } from '../../../WidgetSetup';

export const IconSelector: FC<UseControllerProps<WidgetSetupFormValues>> = ({ control }) => {
    const {
        field: { onChange, value: icon },
    } = useController({
        name: 'icon',
        control,
    });
    const cards = [
        {
            id: WidgetSetupIcon.LOGO,
            icon: <img src={iconLogo} />,
        },
        {
            id: WidgetSetupIcon.BUBBLE,
            icon: <img src={iconBubble} />,
        },
        {
            id: WidgetSetupIcon.QUESTION_MARK,
            icon: <img src={iconQuestionMark} />,
        },
    ];
    return <RadioCardList<string> cards={cards} onChange={onChange} value={icon} />;
};
