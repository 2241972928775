import LoadingView from 'app/common/components/LoadingView';
import { NoBusiness } from 'app/common/components/placeholder/NoBusiness';
import { NoReviewBooster } from 'app/common/components/placeholder/ReviewBooster/NoReviewBooster';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import { useReviewBoosterInitialDataLoad } from 'app/pages/conversations/ReviewBooster/hooks/useReviewBoosterInitialDataLoad';
import { CustomerExperienceTabs } from 'app/pages/customerExperience/components/CustomerExperienceTabs/CustomerExperienceTabs';

import { ReviewBoosterPageHeader } from './pageHeader/ReviewBoosterPageHeader';
import ReviewBoosterForm from './ReviewBoosterForm';

export const ReviewBoosterWithNewSidebar = () => {
    const {
        isLoading: subscriptionsLoading,
        hasBusinesses,
        subscribedToRB,
    } = useBusinessSubscriptions();
    const { isLoading: isInitialDataLoadLoading } = useReviewBoosterInitialDataLoad();

    if (subscriptionsLoading || isInitialDataLoadLoading) {
        return <LoadingView />;
    }

    if (!hasBusinesses) {
        return <NoBusiness />;
    }

    if (!subscribedToRB) {
        return <NoReviewBooster />;
    }

    return (
        <>
            <ReviewBoosterPageHeader />
            <CustomerExperienceTabs />
            <ReviewBoosterForm />
        </>
    );
};
