import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';

import NotFoundTemplate, {
    NotFoundTemplateProps,
} from 'app/common/components/placeholder/NotFoundTemplate';

export const NoBusinessToConnect = (props: NotFoundTemplateProps) => {
    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.VeryHappySuccess,
    };
    return (
        <NotFoundTemplate
            {...props}
            show={true}
            withButton={false}
            imgSrc={illustrationElement}
            title={'no_business_to_connect'}
            subtitle={'no_business_to_connect_description'}
        />
    );
};
