import { UseQueryOptions, useQuery } from 'react-query';

import { OrgLanguagesRequest, OrgLanguagesResponse } from 'app/api/types/org';
import api from 'app/api/v2/api_calls/camel';
import { useAppDataContext } from 'app/common/contexts/appData';
import { ORG_LANGUAGES } from 'app/common/data/queryKeysConstants';

export default function useOrgLanguages(
    params: OrgLanguagesRequest = {},
    options: UseQueryOptions<OrgLanguagesResponse> = {},
) {
    const { me } = useAppDataContext();
    const orgId = me?.org_id;
    const enabled = options.hasOwnProperty('enabled') ? options.enabled : true;

    return useQuery([ORG_LANGUAGES, params], () => api.org.getBusinessesLanguages(orgId, params), {
        ...options,
        enabled: !!orgId && enabled,
    });
}
