import { SidebarPage } from 'app/api/types/org';
import { FEATURE_ENUM, PRODUCT_ENUM } from 'app/api/types/plans_features';

export const formatSidebarFeatures = (
    sidebarPages: Array<SidebarPage>,
): Array<{ product: PRODUCT_ENUM; features: Array<FEATURE_ENUM> }> => {
    return sidebarPages.reduce(
        (acc, { name, product }) => {
            const existingProduct = acc.find(item => item.product === product);
            if (existingProduct) {
                existingProduct.features.push(name);
            } else {
                acc.push({ product, features: [name] });
            }
            return acc;
        },
        [] as Array<{ product: PRODUCT_ENUM; features: FEATURE_ENUM[] }>,
    );
};
