import styled from '@emotion/styled';

import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

export const SectionTop = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    &::after {
        content: '';
        margin-top: 8px;
        border-bottom: solid 1px ${DISABLED_COLOR};
    }
`;

export const SectionTopInfos = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const SectionTopRightActions = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 16px;
`;

export const SectionName = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.default.initial};
    > i {
        font-size: 12px;
        margin-left: 8px;
    }

    &:hover {
        cursor: pointer;
    }
`;

export const SectionBottom = styled.div`
    margin-top: 16px;
`;

export const GroupsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
