import { useEffect, useMemo } from 'react';

import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, LeftElementType } from '@partoohub/ui';
import { cloneDeep } from 'lodash-es';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AttributeMetadataList, attributesEnumType } from 'app/api/types/attributes';
import { PlaceActionLinksPostRequest } from 'app/api/types/placeActionLinks';
import EditForm from 'app/businessEditV2/components/EditForm';
import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import { ControlledTextInput } from 'app/businessEditV2/hookControllerWrapper/ControlledTextInput';
import useFacebookAttributeUrl from 'app/businessEditV2/hooks/attributes/attributesBusiness/useFacebookAttributeUrl';
import useFormattedBusinessAttributesUrl from 'app/businessEditV2/hooks/attributes/attributesBusiness/useFormattedBusinessAttributesUrl';
import useTwitterAttributeUrl from 'app/businessEditV2/hooks/attributes/attributesBusiness/useTwitterAttributeUrl';
import useFormattedAttributesUrl from 'app/businessEditV2/hooks/attributes/attributesMetadata/useFormattedAttributesUrl';
import useAttributesUpdate from 'app/businessEditV2/hooks/attributes/useAttributesUpdate';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import { useIsLoadingLinks } from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingLinks';
import useLinksSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useLinksSectionColor';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useBusinessPlaceActionLinks from 'app/businessEditV2/hooks/placeActionLinks/useBusinessPlaceActionLinks';
import usePlaceActionLinksUpdate from 'app/businessEditV2/hooks/placeActionLinks/useBusinessPlaceActionLinksUpdate';
import useFormFieldFormatter from 'app/businessEditV2/hooks/publisherErrors/useFormFieldFormatter';
import AttributesTypeRepeatableUrl from 'app/businessEditV2/sections/AttributesSection/AttributesComponents/AttributesTypeRepeatableUrl';

import {
    ATTRIBUTES_SOCIAL_MEDIA,
    ATTRIBUTE_URL_FACEBOOK,
    ATTRIBUTE_URL_TWITTER,
    attributesUrlsIcons,
} from 'app/businessEditV2/utils/attributes';
import { formatHttpsUrl } from 'app/businessEditV2/utils/links';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames, invalidatesAny } from 'app/businessEditV2/utils/utils';
import { BUSINESS } from 'app/common/data/queryKeysConstants';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';
import queryClient from 'app/states/queryClient';

import { AdditionalUrlsTitle, LinksStack, MarginContainer } from './LinksForm.styled';

import ControlledPlaceActionLinks from './PlaceActionLinks/ControlledPlaceActionLinks';

type Props = {
    closeModal: () => void;
    useDirtyUpdate: (boolean: boolean) => void;
};

const LinksForm = ({ closeModal, useDirtyUpdate }: Props) => {
    const { t } = useTranslation();
    const colorState = useLinksSectionColor();
    const { data: business } = useBusiness();
    const businessAttributesUrl = useFormattedBusinessAttributesUrl();
    const allAttributesUrl = useFormattedAttributesUrl();
    const { data: placeActionLinksBusinessData } = useBusinessPlaceActionLinks();
    const isLoading = useIsLoadingLinks();

    const facebookAttributeUrl = useFacebookAttributeUrl();
    const twitterAttributeUrl = useTwitterAttributeUrl();

    const placeActionLinksBusiness = placeActionLinksBusinessData?.place_action_links ?? [];

    const setFormValue = () => {
        const result: Record<string, any> = {
            links: {
                websiteUrl: business?.website_url ?? '',
                facebookUrl: facebookAttributeUrl || business?.facebook_url || '',
                twitterUrl: twitterAttributeUrl || business?.twitter_url || '',
            },
            attributesUrl: {},
            placeActionLinks: {},
        };

        businessAttributesUrl.forEach(attribute => {
            result.attributesUrl[attribute.gmb_id] = attribute.value;
        });

        placeActionLinksBusiness.forEach(pal => {
            result.placeActionLinks[pal.gmb_id] = pal.urls;
        });

        return result;
    };

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        watch,
        reset,
    } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });
    useDirtyUpdate(isDirty);

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const businessUpdate = useBusinessUpdate(
        () => undefined,
        () => undefined,
        true,
    );
    const attributesUpdate = useAttributesUpdate(
        () => undefined,
        () => undefined,
        true,
    );

    const businessPlaceActionLinksUpdate = usePlaceActionLinksUpdate(
        () => undefined,
        () => undefined,
        true,
    );

    const errors = businessUpdate.error?.response?.data?.errors?.json;
    const attributesErrors = attributesUpdate.error?.response?.data?.errors?.json?.attributes;
    const placeActionLinksErrors =
        businessPlaceActionLinksUpdate.error?.response?.data?.errors?.json?.place_action_links;

    const onSubmit = payload => {
        const updatePromises = [
            businessUpdate.mutateAsync(formatBusinessPayload(payload)),
            attributesUpdate.mutateAsync(formatAttributesPayload(payload)),
            businessPlaceActionLinksUpdate.mutateAsync(formatPlaceActionLinksPayload(payload)),
        ];

        Promise.all(updatePromises)
            .then(() => {
                // Invalidating queries in the hooks will refetch the other hooks in error
                queryClient.invalidateQueries(invalidatesAny(BUSINESS)).then(() => {
                    businessUpdateCompletionRate.mutate();
                    closeModal();
                });
            })
            .catch(() => undefined);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_LINKS_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
    };

    // Permission
    const businessIsOpen = !useIsBusinessClosed();
    const websitePermission = useFieldMetadata('links', 'website_url')?.enabled && businessIsOpen;

    const facebookPermission = useFieldMetadata('links', 'facebook_url')?.enabled && businessIsOpen;

    // Used for all additional urls (place action links and attributes url)
    const attributesUrlPermission =
        useFieldMetadata('links', 'attributes_url')?.enabled && businessIsOpen;

    const twitterPermission = useFieldMetadata('links', 'twitter_url')?.enabled && businessIsOpen;

    const formatBusinessPayload = payload => {
        return {
            ...(websitePermission && {
                website_url: formatHttpsUrl(payload.links.websiteUrl),
            }),
            ...(facebookPermission && {
                facebook_url: payload.links.facebookUrl,
            }),
            ...(twitterPermission && { twitter_url: payload.links.twitterUrl }),
        };
    };

    const formatAttributesPayload = payload => {
        if (!attributesUrlPermission) {
            return { attributes: [] };
        }

        const urlAttributes = Object.entries(payload.attributesUrl).map(([key, value]) => {
            const attribute = businessAttributesUrl.find(attr => attr.gmb_id === key);
            return {
                gmb_id: key,
                type: attribute?.type,
                value: value || null,
            };
        });

        return {
            attributes: [
                ...urlAttributes,
                // Hardcode fb and twitter attributes
                {
                    gmb_id: ATTRIBUTE_URL_FACEBOOK,
                    type: attributesEnumType.URL,
                    value: payload.links.facebookUrl || null,
                },
                {
                    gmb_id: ATTRIBUTE_URL_TWITTER,
                    type: attributesEnumType.URL,
                    value: payload.links.twitterUrl || null,
                },
            ],
        };
    };

    const formatPlaceActionLinksPayload = (payload): PlaceActionLinksPostRequest => {
        if (!attributesUrlPermission) {
            return { place_action_links: [] };
        }

        const placeActionLinks = Object.entries(payload.placeActionLinks).map(([gmb_id, urls]) => {
            return {
                gmb_id: gmb_id,
                urls: urls.map(url => ({
                    is_preferred: url.is_preferred,
                    id: url.id ? url.id : null,
                    value: formatHttpsUrl(url.value),
                })),
            };
        });

        return {
            place_action_links: placeActionLinks,
        };
    };

    const submitForm = () => {
        handleSubmit(onSubmit)();
        trackSaveBusinessEditSection(SectionNames.LINKS, colorState);
    };

    const attributesSocialMedia = allAttributesUrl.filter(attribute =>
        ATTRIBUTES_SOCIAL_MEDIA.includes(attribute.gmb_id),
    );

    const otherAttributesUrl = allAttributesUrl.filter(
        attribute => !ATTRIBUTES_SOCIAL_MEDIA.includes(attribute.gmb_id),
    );

    const attributesUrlRender = (attributes: AttributeMetadataList) => {
        return (
            <>
                {attributes.map(attribute => {
                    const businessAttribute = businessAttributesUrl.find(
                        data => data.gmb_id === attribute.gmb_id,
                    );
                    const name = `attributesUrl.${businessAttribute?.gmb_id}`;
                    const displayName = attribute?.name ?? '';
                    const key = `attribute_url_${name}}`;

                    switch (attribute.type) {
                        case attributesEnumType.URL:
                            return (
                                <MarginContainer>
                                    <ControlledTextInput
                                        name={name}
                                        control={control}
                                        key={key}
                                        label={displayName}
                                        leftElement={
                                            <Icon
                                                icon={
                                                    attributesUrlsIcons[attribute.gmb_id] ?? [
                                                        FontAwesomeIconsPartooUsed.faLink,
                                                        IconPrefix.SOLID,
                                                    ]
                                                }
                                            />
                                        }
                                        leftElementType={LeftElementType.Icon}
                                        disabled={!attributesUrlPermission}
                                        notice={
                                            attributesErrors?.[attribute.gmb_id]
                                                ? t(attributesErrors?.[attribute.gmb_id])
                                                : ''
                                        }
                                        error={attributesErrors?.[attribute.gmb_id]}
                                        dataTrackId="visibility_location__form_links__additional_urls"
                                    />
                                </MarginContainer>
                            );

                        case attributesEnumType.REPEATABLE_URL:
                            return (
                                <AttributesTypeRepeatableUrl
                                    name={name}
                                    control={control}
                                    key={key}
                                    displayName={displayName}
                                    disabled={!attributesUrlPermission}
                                    error={cloneDeep(attributesErrors?.[attribute.gmb_id])}
                                />
                            );

                        default:
                            return null;
                    }
                })}
            </>
        );
    };

    const displayOtherAttributes = otherAttributesUrl.length > 0;
    const displayPlaceActionLinks = placeActionLinksBusiness.length > 0;

    return (
        <>
            <EditForm
                title={t('links')}
                description={t('business_edit_links_description')}
                colorState={colorState}
            >
                <LinksStack>
                    <ControlledTextInput
                        name="links.websiteUrl"
                        control={control}
                        label={t('website_extended')}
                        leftElement={['fa-globe', IconPrefix.SOLID]}
                        leftElementType={LeftElementType.Icon}
                        dataTrackId="visibility_location__form_links__website_url"
                        {...useFormFieldFormatter(
                            'links',
                            'website_url',
                            watch,
                            formatBusinessPayload,
                            errors,
                        )}
                    />
                    <ControlledTextInput
                        name="links.facebookUrl"
                        control={control}
                        label={t('facebook_page')}
                        leftElement={['fa-facebook', IconPrefix.BRANDS]}
                        leftElementType={LeftElementType.Icon}
                        dataTrackId="visibility_location__form_links__facebook_url"
                        {...useFormFieldFormatter(
                            'links',
                            'facebook_url',
                            watch,
                            formatBusinessPayload,
                            errors,
                        )}
                    />
                    <ControlledTextInput
                        name="links.twitterUrl"
                        control={control}
                        label={t('twitter_profile')}
                        leftElement={['fa-twitter', IconPrefix.BRANDS]}
                        leftElementType={LeftElementType.Icon}
                        dataTrackId="visibility_location__form_links__twitter_url"
                        {...useFormFieldFormatter(
                            'links',
                            'twitter_url',
                            watch,
                            formatBusinessPayload,
                            errors,
                        )}
                    />
                </LinksStack>

                {/*Handle social media attributes with normal links*/}
                {attributesSocialMedia.length > 0 && (
                    <>{attributesUrlRender(attributesSocialMedia)}</>
                )}

                {/*Handle other attributes urls and Place Action Links in a separate section*/}
                {(displayOtherAttributes || displayPlaceActionLinks) && (
                    <AdditionalUrlsTitle>{t('additional_urls')}</AdditionalUrlsTitle>
                )}
                {displayPlaceActionLinks && (
                    <ControlledPlaceActionLinks
                        control={control}
                        name={'placeActionLinks'}
                        errors={placeActionLinksErrors}
                        disabled={!attributesUrlPermission}
                    />
                )}
                {displayOtherAttributes && attributesUrlRender(otherAttributesUrl)}
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={
                        businessUpdate.isLoading ||
                        attributesUpdate.isLoading ||
                        businessPlaceActionLinksUpdate.isLoading
                    }
                    hasError={
                        !!(
                            businessUpdate.error ||
                            attributesUpdate.error ||
                            businessPlaceActionLinksUpdate.error
                        )
                    }
                    onClick={submitForm}
                    dataTrackId="visibility_location__form_links__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default LinksForm;
