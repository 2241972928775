import { Control, Controller, UseFormClearErrors } from 'react-hook-form';

import { DayString } from 'app/api/types';
import { SettingFormReturn } from 'app/api/types/business_message_settings';
import { HOUR_SEPARATOR } from 'app/businessEditV2/oldFromEditV1/data/constants';
import { findFirstString } from 'app/businessEditV2/utils/utils';
import OpenHourDay from 'app/common/components/OpenHour/OpenHourDay';

type Props = {
    day: DayString;
    name: string;
    control: Control<any>;
    rules: object;
    errors: any;
    clearOpenHoursErrors: UseFormClearErrors<SettingFormReturn>;
};

const ControlledOpenHourDay = ({
    day,
    name,
    control,
    rules,
    errors,
    clearOpenHoursErrors,
}: Props) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => {
            const valueFormatString = value?.map(
                (el: { startHour: string; endHour: string }) => `${el.startHour}-${el.endHour}`,
            );

            const onChangeFormatString = (valueFormatString: Array<string>) => {
                clearOpenHoursErrors();
                const valueFormatStartEnd = valueFormatString.map((stringWindow: string) => {
                    const [startHour, endHour] = stringWindow.split(HOUR_SEPARATOR);
                    return {
                        startHour: startHour || '',
                        endHour: endHour || '',
                    };
                });

                onChange(valueFormatStartEnd);
            };

            return (
                <OpenHourDay
                    disabled={false}
                    day={day}
                    dayValue={valueFormatString ?? []}
                    onChange={onChangeFormatString}
                    hoursError={findFirstString(errors) ?? ''}
                />
            );
        }}
    />
);

export default ControlledOpenHourDay;
