import { Icon, IconElement, Stack, Text, Tooltip } from '@partoohub/ui';
import { t } from 'i18next';

import {
    ColorTileStyled,
    CompetitorCellStyled,
    CompetitorNameText,
    CompetitorSubLabel,
} from './CompetitorCell.styled';

type Props = {
    name: string;
    subLabels?: SubLabelProps[];
    color?: string;
};

export type SubLabelProps = {
    label: string | number | undefined;
    prefixIcon?: IconElement;
    prefixIconTooltip?: string;
    suffixIcon?: IconElement;
    suffixIconTooltip?: string;
};

const SubLabel = ({
    label,
    prefixIcon,
    prefixIconTooltip,
    suffixIcon,
    suffixIconTooltip,
}: SubLabelProps) => {
    if (!label) {
        return <></>;
    }

    return (
        <CompetitorSubLabel>
            {prefixIcon && (
                <Tooltip text={prefixIconTooltip ? t(prefixIconTooltip) : undefined}>
                    <Icon iconSize="12px" icon={prefixIcon} color="secondary" />
                </Tooltip>
            )}
            <Text variant="bodyMRegular" as="span" color="secondary">
                {label}
            </Text>
            {suffixIcon && (
                <Tooltip text={suffixIconTooltip ? t(suffixIconTooltip) : undefined}>
                    <Icon iconSize="12px" icon={suffixIcon} color="secondary" />
                </Tooltip>
            )}
        </CompetitorSubLabel>
    );
};

export const CompetitorCell = ({ name, color, subLabels }: Props) => {
    return (
        <CompetitorCellStyled>
            {color && <ColorTileStyled color={color} />}
            <Stack direction="column" gap="4px">
                <CompetitorNameText variant="bodyLBold" as="span">
                    {name}
                </CompetitorNameText>
                {subLabels?.map(labelInfo => (
                    <SubLabel key={`subLabel-${labelInfo.label}`} {...labelInfo} />
                ))}
            </Stack>
        </CompetitorCellStyled>
    );
};
