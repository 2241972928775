import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Checkbox, Text, TextInput } from '@partoohub/ui';

type LegalMentionProps = {
    disabled?: boolean;
};

export const ReviewBoosterFormElement = styled.form`
    height: 100%;
`;

export const StyledTextInput = styled(TextInput)`
    width: 100%;
`;

export const ReviewBoosterFormContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 32px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export const FormWrapper = styled.div`
    width: 50%;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const FormTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.default.initial};
`;

export const SmsWrapper = styled.div`
    width: 50%;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const SmsTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

export const LegalMention = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-weight: bold;
    display: flex;
    flex-direction: row;
    ${(props: LegalMentionProps) =>
        props.disabled &&
        css`
            opacity: 0.25;
            cursor: not-allowed;
        `}
`;
export const StyledCheckbox = styled(Checkbox)`
    margin-bottom: 0;
`;

export const LegalInformation = styled(Text)`
    margin: auto 0;
`;

export const ClickableContainer = styled.div`
    margin: auto 8px;
`;
