import { BusinessReachEnum } from 'app/api/types/business';

import { useBusinesses } from './useBusinesses';
import useMe from './useMeUncamel';

export const useGetBrands = () => {
    const { data: me } = useMe();
    return useBusinesses({
        reach: BusinessReachEnum.GLOBAL,
        org_id: me?.org_id,
    });
};
