import { createRouterReducer } from '@lagunovsky/redux-react-router';
import { combineReducers } from 'redux';

import filterByBusiness, {
    FilterByBusinessState,
} from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import confirmModal, { ConfirmState } from 'app/common/components/ConfirmModal/reducers/confirm';
import { actionReduxEffectReducer } from 'app/common/hooks/useReduxEffect';
import businessEditFields, {
    BusinessEditFieldsState,
} from 'app/common/reducers/businessEditFields';
import global, { GlobalState } from 'app/common/reducers/global';
import group, { GroupState } from 'app/common/reducers/groups';
import me, { MeState } from 'app/common/reducers/me';
import businesses, { BusinessesState } from 'app/common/reducers/newBusinesses';
import orgs, { OrgsState } from 'app/common/reducers/orgs';
import hideableComponents, { HideableComponentsState } from 'app/SDKBridge/reducers';
import { RealtimeUpdatesState } from 'app/states/realtimeUpdates';
import { ReviewAnalyticsState } from 'app/states/reviewAnalytics';
import { ReviewsState } from 'app/states/reviews';

import businessConnection, {
    BusinessConnectionState,
} from './knowledge/reducers/businessConnection';
import { PartnerConnectionState } from './knowledge/reducers/businessConnection/connection';
import facebook, { FacebookState } from './knowledge/reducers/businessConnection/facebook';
import { FacebookLocationsState } from './knowledge/reducers/businessConnection/facebook/facebookLocations';
import gmb, { GmbState } from './knowledge/reducers/businessConnection/gmb';
import { GMBLocationsState } from './knowledge/reducers/businessConnection/gmb/gmbLocations';
import pushNotifications, { PushNotificationsStateType } from './pushNotifs/reducers';
import realtimeUpdates from './realtimeUpdates/reducers/reducers';
import reviewAnalytics from './reviewAnalytics/reducers/reducers';
import reviews from './reviews/reducers/reducers';

export type AppState = {
    me: MeState;
    // Common reducers
    businesses: BusinessesState;
    confirmModal: ConfirmState;
    global: GlobalState;
    group: GroupState;
    filterByBusiness: FilterByBusinessState;
    router: any;
    businessEditFields: BusinessEditFieldsState;
    orgs: OrgsState;
    gmb: GmbState;
    facebook: FacebookState;
    businessConnection: BusinessConnectionState;
    // REVIEWS reducers
    reviews: ReviewsState;
    reviewAnalytics: ReviewAnalyticsState;
    realtimeUpdates: RealtimeUpdatesState;
    // HIDEABLE components reducers
    hideableComponents: HideableComponentsState;
    // Push Notifications reducers
    pushNotifications: PushNotificationsStateType;
};

export const groupStateSelector = (state: AppState): GroupState => state.group;

export const confirmModalSelector = (state: AppState): any => state.confirmModal;

export const businessesSelector = (state: AppState): BusinessesState => state.businesses;

export const businessesEditFieldsSelector = (state: AppState): BusinessEditFieldsState =>
    state.businessEditFields;

export const meSelector = (state: AppState): MeState => state.me;

export const orgsSelector = (state: AppState): OrgsState => state.orgs;

export const connectionSelector = (state: AppState): PartnerConnectionState =>
    state.businessConnection.page;

export const gmbLocationsSelector = (state: AppState): GMBLocationsState => state.gmb.locations;

export const facebookLocationsSelector = (state: AppState): FacebookLocationsState =>
    state.facebook.locations;

export default (history: any) => {
    const combinedReducers = {
        router: createRouterReducer(history),
        // Common reducers
        me,
        businesses,
        global,
        confirmModal,
        group,
        filterByBusiness,
        businessEditFields,
        orgs,
        // KNOWLEDGE reducers
        gmb,
        facebook,
        businessConnection,
        // REVIEWS reducers
        reviews,
        reviewAnalytics,
        realtimeUpdates,
        // HIDEABLE COMPONENTS reducers
        hideableComponents,
        // PUSH NOTIFICATIONS reducer
        pushNotifications,
        // useReduxEffect
        actionReduxEffectReducer,
    };

    const reducersSorted = Object.keys(combinedReducers).sort((a, b) => a.localeCompare(b));

    return combineReducers(
        reducersSorted.reduce((acc, c) => {
            acc[c] = combinedReducers[c];
            return acc;
        }, {}),
    );
};
