export const PARTOOROBOT_SIDEBAR_BUSINESSES = 'sidebar_businesses';
export const PARTOOROBOT_SIDEBAR_REVIEWS = 'sidebar_reviews';
export const PARTOOROBOT_SIDEBAR_HOMEPAGE = 'sidebar_homepage';
export const PARTOOROBOT_SIDEBAR_POSTS = 'sidebar_posts';
export const PARTOOROBOT_SIDEBAR_MESSAGES = 'sidebar_messages';
export const PARTOOROBOT_SIDEBAR_ANALYTICS = 'sidebar_analytics';
export const PARTOOROBOT_SIDEBAR_QUICK_SEARCH = 'sidebar_quick_search';
export const PARTOOROBOT_SIDEBAR_PARTNER_LINK = 'sidebar_partner_link';

export const PARTOOROBOT_TAB_ANALYTYCS_VISIBILITY = 'tab_analytics_visibility';
export const PARTOOROBOT_TAB_ANALYTYCS_REVIEWS = 'tab_analytics_reviews';
export const PARTOOROBOT_TAB_ANALYTYCS_REVIEWS_V2 = 'tab_analytics_reviews_v2';
export const PARTOOROBOT_TAB_COMPETITIVE_BENCHMARK = 'tab_competitive_benchmark';

export const PARTOOROBOT_TAB_CE_REVIEWS = 'tab_ce_reviews';
export const PARTOOROBOT_TAB_CE_REVIEW_BOOSTER = 'tab_ce_review_booster';
