import styled from '@emotion/styled';
import { SelectableCard, Text } from '@partoohub/ui';

export const StyledGraphSelector = styled.div`
    display: flex;
    max-width: 25%;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.theme.background};
    height: fit-content;
    padding: 16px;
    border-radius: 8px;
    position: sticky;
    top: -24px;
`;

export const StyledExpandableToggle = styled.div`
    height: 44px;
    padding-left: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

export const StyledSectionText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledSelectableCard = styled(SelectableCard)`
    padding-left: 8px;
`;
