import { addMonths, addYears, parse } from 'date-fns';
import { format } from 'date-fns/format';

import { ReviewAnalyticsFiltersType } from 'app/api/types/review_analytics';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { ISO_DATETIME_FORMAT_WITH_TIMEZONE } from 'app/utils/dates';

import { useDateDimension } from './useDateDimension';

export const REVIEW_ANALYTICS_FILTER_DATE_FROM = 'date-from';
export const REVIEW_ANALYTICS_FILTER_DATE_TO = 'date-to';
export const REVIEW_ANALYTICS_FILTER_TAGS = 'tags';

const formatDatetime = (dateStr: string | number | Date) => {
    return format(dateStr, ISO_DATETIME_FORMAT_WITH_TIMEZONE);
};

export const useReviewAnalyticsFilters = (): ReviewAnalyticsFiltersType => {
    const [dateFrom] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_DATE_FROM);
    const [dateTo] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_DATE_TO);
    const [tags] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_TAGS);
    const businessFilters = useBusinessModalFilters();

    return {
        update_date__gte: dateFrom ? formatDatetime(dateFrom) : undefined,
        update_date__lte: dateTo ? formatDatetime(dateTo) : undefined,
        tags: tags || undefined,
        ...businessFilters,
    };
};

export const usePreviousPeriodFilters = (): ReviewAnalyticsFiltersType => {
    const [dateFrom] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_DATE_FROM);
    const [dateTo] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_DATE_TO);
    const [tags] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_TAGS);
    const businessFilters = useBusinessModalFilters();
    const dimensions = useDateDimension();

    if (!dateFrom || !['day', 'month'].includes(dimensions)) return {};

    const shiftPeriod = dimensions === 'day' ? addMonths : addYears;
    return {
        update_date__gte: formatDatetime(
            shiftPeriod(parse(dateFrom, 'yyyy-MM-dd', new Date()), -1),
        ),
        update_date__lte: dateTo
            ? formatDatetime(shiftPeriod(parse(dateTo, 'yyyy-MM-dd', new Date()), -1))
            : undefined,
        tags: tags || undefined,
        ...businessFilters,
    };
};
