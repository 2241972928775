const VALID_HOSTNAME_CHARS = /^[a-z0-9.-]+$/;

export const extractDomainFromUrl = (url: string) => {
    try {
        const { hostname } = new URL(url.toLowerCase());

        if (!VALID_HOSTNAME_CHARS.test(hostname)) return '';

        if (hostname.endsWith('.')) {
            return hostname.slice(0, hostname.length - 1);
        }

        return hostname;
    } catch (e) {
        if (e instanceof TypeError) return '';

        throw e;
    }
};
